import { bindActionCreators } from "redux";
import store from "../../store";

const updateProjektBeschreibung = (beschreibung) => ({
  type: "CHANGE_PROJEKT_BESCHREIBUNG",
  payload: beschreibung,
});
const updateProjektBaustellenBilder = (bilder) => ({
  type: "CHANGE_PROJEKT_BILDER_BAUSTELLE",
  payload: bilder,
});

const updateProjektBaustellenBilderName = (bilderName) => ({
  type: "CHANGE_PROJEKT_BILDER_BAUSTELLE_NAME",
  payload: bilderName,
});

const updateProjektBaustellenBilderPath = (bilderPath) => ({
  type: "CHANGE_PROJEKT_BILDER_BAUSTELLE_PATH",
  payload: bilderPath,
});
const updateProjektBaustellenBilderPathUrl = (bilderPathUrl) => ({
  type: "CHANGE_PROJEKT_BILDER_BAUSTELLE_PATH_URL",
  payload: bilderPathUrl,
});

const updateProjektZeichnungenSkizzenBaustelle = (zeichnungen) => ({
  type: "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE",
  payload: zeichnungen,
});

const updateProjektZeichnungenSkizzenBaustelleName = (zeichnungenName) => ({
  type: "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE_NAME",
  payload: zeichnungenName,
});

const updateProjektZeichnungenSkizzenBaustellePath = (zeichnungenPath) => ({
  type: "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE_PATH",
  payload: zeichnungenPath,
});
const updateProjektZeichnungenSkizzenBaustellePathUrl = (
  zeichnungenPathUrl
) => ({
  type: "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE_PATH_URL",
  payload: zeichnungenPathUrl,
});

const updateProjektBaustelleAdresse = (adresse) => ({
  type: "CHANGE_PROJEKT_BAUSTELLE_ADRESSE",
  payload: adresse,
});
const updateProjektBaustellePostleitzahl = (plz) => ({
  type: "CHANGE_PROJEKT_BAUSTELLE_POSTLEITZAHL",
  payload: plz,
});
const updateProjektBaustelleOrt = (ort) => ({
  type: "CHANGE_PROJEKT_BAUSTELLE_ORT",
  payload: ort,
});

const updateProjektAngabenGeschlecht = (geschlecht) => ({
  type: "CHANGE_PROJEKT_ANGABEN_GESCHLECHT",
  payload: geschlecht,
});

const updateProjektAngabenVorname = (vorname) => ({
  type: "CHANGE_PROJEKT_ANGABEN_VORNAME",
  payload: vorname,
});
const updateProjektAngabenNachname = (nachname) => ({
  type: "CHANGE_PROJEKT_ANGABEN_NACHNAME",
  payload: nachname,
});
const updateProjektAngabenUnternehmen = (unternehmen) => ({
  type: "CHANGE_PROJEKT_ANGABEN_UNTERNEHMEN",
  payload: unternehmen,
});

const updateProjektAngabenAnsprechpartnerAdresse = (adresse) => ({
  type: "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_ADRESSE",
  payload: adresse,
});

const updateProjektAngabenAnsprechpartnerPostzeitzahl = (
  plzAnsprechpartner
) => ({
  type: "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_POSTLEITZAHL",
  payload: plzAnsprechpartner,
});
const updateProjektAngabenAnsprechpartnerOrt = (ortAnsprechpartner) => ({
  type: "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_ORT",
  payload: ortAnsprechpartner,
});

const updateProjektAngabenAnsprechpartnerTelefonnummer = (telefonnummer) => ({
  type: "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_TELEFONNUMMER",
  payload: telefonnummer,
});
const updateProjektAngabenAnsprechpartnerEMail = (email) => ({
  type: "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_EMAIL",
  payload: email,
});
const updateProjektAngabenAgb = (agb) => ({
  type: "CHANGE_PROJEKT_ANGABEN_AGB",
  payload: agb,
});
const updateProjektAngabenNewsletter = (newsletter) => ({
  type: "CHANGE_PROJEKT_ANGABEN_NEWSLETTER",
  payload: newsletter,
});

const updateCurrentID = (currentId) => ({
  type: "CHANGE_CURRENT_ID",
  payload: currentId,
});
const updateSetCurrentID = (setCurrentId) => ({
  type: "CHANGE_SET_CURRENT_ID",
  payload: setCurrentId,
});

export const furtherQuestionsCreators = bindActionCreators(
  {
    beschreibung: updateProjektBeschreibung,
    bilder: updateProjektBaustellenBilder,
    bilderName: updateProjektBaustellenBilderName,
    bilderPath: updateProjektBaustellenBilderPath,
    bilderPathUrl: updateProjektBaustellenBilderPathUrl,
    zeichnungen: updateProjektZeichnungenSkizzenBaustelle,
    zeichnungenName: updateProjektZeichnungenSkizzenBaustelleName,
    zeichnungenPath: updateProjektZeichnungenSkizzenBaustellePath,
    zeichnungenPathUrl: updateProjektZeichnungenSkizzenBaustellePathUrl,
    adresse: updateProjektBaustelleAdresse,
    baustellePostleitzahl: updateProjektBaustellePostleitzahl,
    baustelleOrt: updateProjektBaustelleOrt,
    geschlecht: updateProjektAngabenGeschlecht,
    vorname: updateProjektAngabenVorname,
    nachname: updateProjektAngabenNachname,
    unternehmen: updateProjektAngabenUnternehmen,
    adresseAnsprechpartner: updateProjektAngabenAnsprechpartnerAdresse,
    postleitzahlAnsprechpartner:
      updateProjektAngabenAnsprechpartnerPostzeitzahl,
    ortAnsprechpartner: updateProjektAngabenAnsprechpartnerOrt,
    telefonnummer: updateProjektAngabenAnsprechpartnerTelefonnummer,
    email: updateProjektAngabenAnsprechpartnerEMail,
    agb: updateProjektAngabenAgb,
    newsletter: updateProjektAngabenNewsletter,
    updateCurrentID,
    updateSetCurrentID,
    updateProjektBeschreibung,
    updateProjektBaustellenBilder,
    updateProjektBaustellenBilderName,
    updateProjektBaustellenBilderPath,
    updateProjektBaustellenBilderPathUrl,
    updateProjektZeichnungenSkizzenBaustelle,
    updateProjektZeichnungenSkizzenBaustelleName,
    updateProjektZeichnungenSkizzenBaustellePath,
    updateProjektZeichnungenSkizzenBaustellePathUrl,
    updateProjektBaustelleAdresse,
    updateProjektBaustellePostleitzahl,
    updateProjektBaustelleOrt,
    updateProjektAngabenGeschlecht,
    updateProjektAngabenVorname,
    updateProjektAngabenNachname,
    updateProjektAngabenUnternehmen,
    updateProjektAngabenAnsprechpartnerAdresse,
    updateProjektAngabenAnsprechpartnerPostzeitzahl,
    updateProjektAngabenAnsprechpartnerOrt,
    updateProjektAngabenAnsprechpartnerTelefonnummer,
    updateProjektAngabenAnsprechpartnerEMail,
    updateProjektAngabenAgb,
    updateProjektAngabenNewsletter,
  },
  store.dispatch
);
