import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Vordach from "./Ueberdachungen/Vordach";
import Pergola from "./Ueberdachungen/Pergola";
import Haustuerueberdachung from "./Ueberdachungen/Haustuerueberdachung";
import Carport from "./Ueberdachungen/Carport";
import Terassenueberdachung from "./Ueberdachungen/Terassenueberdachung";
import Balkonueberdachung from "./Ueberdachungen/Balkonueberdachung";
import B2cComponentFunction from "../B2cComponentFunction";

import {
  onListItemClickedB2CUeberdachungBalkon,
  onListItemClickedB2CUeberdachungCarport,
  onListItemClickedB2CUeberdachungHaustuer,
  onListItemClickedB2CUeberdachungPergola,
  onListItemClickedB2CUeberdachungTerasse,
  onListItemClickedB2CUeberdachungVordach,
} from "../../../../actions/useCases/b2c/ueberdachungen/ueberdachungen";

export default function Ueberdachungen() {
  const ueberdachung = useSelector(
    (state) => state.ueberdachung.ArtUeberdachung
  );
  console.log(ueberdachung);
  const dispatch = useDispatch();

  // Deklariere eine neue State Variable, die wir "zustandComponente" nennen werden
  const [zustandComponente, setZustandComponente] = useState(0);

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Welche Überdachung willst du?
        </h1>

        <div className="row row-cols-3 pt-4 pb-4">
          <div
            className="col breakpointColsM divRand pt-4 pb-4"
            key={"Vordach"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CUeberdachungVordach());
            }}
          >
            <p className="text_wrapper">Vordach</p>
          </div>
          <div
            className="col breakpointColsM divRand pt-4 pb-4"
            key={"Carport"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CUeberdachungCarport());
            }}
          >
            <p className="text_wrapper">Carport</p>
          </div>
          <div
            className="col breakpointColsM divRand pt-4 pb-4"
            key={"Pergola"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CUeberdachungPergola());
            }}
          >
            <p className="text_wrapper">Pergola</p>
          </div>
          <div
            className="col breakpointColsM divRand pt-4 pb-4"
            key={"Terassenueberdachung"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CUeberdachungTerasse());
            }}
          >
            <p className="text_wrapper">Terassenüberdachung</p>
          </div>
          <div
            className="col breakpointColsM divRand pt-4 pb-4"
            key={"Balkonueberdachung"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CUeberdachungBalkon());
            }}
          >
            <p className="text_wrapper">Balkonüberdachung</p>
          </div>
          <div
            className="col breakpointColsM divRand pt-4 pb-4"
            key={"Haustuerueberdachung"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CUeberdachungHaustuer());
            }}
          >
            <p className="text_wrapper">Haustürüberdachung</p>
          </div>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    switch (ueberdachung) {
      case "Vordach":
        return <Vordach />;
      case "Pergola":
        return <Pergola />;
      case "Balkonüberdachung":
        return <Balkonueberdachung />;
      case "Terassenüberdachung":
        return <Terassenueberdachung />;
      case "Haustürüberdachung":
        return <Haustuerueberdachung />;
      case "Carport":
        return <Carport />;
      default:
        return null;
    }
  } else if (zustandComponente == -1) {
    return <B2cComponentFunction />;
  }
}
