import React, { useState } from "react";

export default function Tueren() {
  return (
    <div>
      <h1>Türen</h1>
      <p className="text_wrapper">Welche Art von Türe willst du?</p>
    </div>
  );
}
