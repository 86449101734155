import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import TextfieldWrapper from "../materialui/textfield";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { furtherQuestionsCreators } from "../../actions/forms/furtherQuestionsCreators";
import FormLabel from "@mui/material/FormLabel";
import LinearWithPercent90 from "../progress/number/progress90";
import { useNavigate } from "react-router-dom";
import Baustelle from "./baustelle";
import Termin from "../termin/termin";
import CheckboxWrapper from "../materialui/checkbox";
import "./style.css";

export default function Kontakt() {
  const navigate = useNavigate();

  const [zustandComponente, setZustandComponente] = useState(0);

  //Validation Schema
  const phoneRegExp =
    /[0-9]*\/*(\+49)*[ ]*(\([0-9]+\))*([ ]*(-|–)*[ ]*[0-9]+)*/g;

  const kontaktValidationSchema = Yup.object().shape({
    vorname: Yup.string()
      .required("Das Feld Vorname muss ausgefüllt werden.")
      .min(2, "Der Vorname muss aus mindestens 2 Buchstaben bestehen."),
    nachname: Yup.string()
      .required("Das Feld Nachname muss ausgefüllt werden.")
      .min(2, "Der Vorname muss aus mindestens 2 Buchstaben bestehen."),
    unternehmen: Yup.string(),
    adresseAnsprechpartner: Yup.string()
      .required("Adresse muss ausgefüllt werden.")
      .min(3, "Der Ortsname muss aus mindestens 3 Buchstaben bestehen."),
    plzAnsprechpartner: Yup.number()
      .required("Postleitzahl muss ausgefüllt werden.")
      .min(5, "Die Postleitzahl muss aus mindestens 5 Zahlen bestehen."),
    ortAnsprechpartner: Yup.string()
      .required("Ort muss ausgefüllt werden.")
      .min(3, "Der Ortsname muss aus mindestens 3 Buchstaben bestehen."),
    email: Yup.string()
      .email("Falsches E-Mail Format.")
      .required("E-Mail muss ausgefüllt werden."),
    telefon: Yup.string()
      .required("Telefonnummer muss ausgefüllt werden.")
      .matches(phoneRegExp, "Telefonnummer ist nicht gültig.")
      .typeError("Bitte gebe eine gültige Telefonnummer ein."),
    agb: Yup.bool()
      .default(true)
      .required("AGB müssen ausgefüllt werden.")
      .oneOf(
        [true],
        "Den allgemeinen Geschäftsbedingungen muss zugestimmt werden."
      ),

    newsletter: Yup.boolean(),
  });

  if (zustandComponente == 0) {
    const min = 15;
    const max = 200;
    const randomnumber = Math.floor(Math.random() * (max - min)) + min;

    return (
      <Formik
        initialValues={{
          vorname: "",
          nachname: "",
          unternehmen: "",
          adresseAnsprechpartner: "",
          plzAnsprechpartner: "",
          ortAnsprechpartner: "",
          email: "",
          telefon: "",
          agb: false,
          newsletter: false,
        }}
        validationSchema={kontaktValidationSchema}
        onSubmit={(values, actions) => {
          furtherQuestionsCreators.vorname(values.vorname);
          furtherQuestionsCreators.nachname(values.nachname);
          furtherQuestionsCreators.unternehmen(values.unternehmen);
          furtherQuestionsCreators.adresseAnsprechpartner(
            values.adresseAnsprechpartner
          );
          furtherQuestionsCreators.postleitzahlAnsprechpartner(
            values.plzAnsprechpartner
          );
          furtherQuestionsCreators.ortAnsprechpartner(
            values.ortAnsprechpartner
          );
          furtherQuestionsCreators.email(values.email);
          furtherQuestionsCreators.telefonnummer(values.telefon);
          furtherQuestionsCreators.agb(values.agb);
          furtherQuestionsCreators.newsletter(values.newsletter);
          actions.setSubmitting(false);
          actions.resetForm({
            vorname: "",
            nachname: "",
            unternehmen: "",
            adresseAnsprechpartner: "",
            plzAnsprechpartner: "",
            ortAnsprechpartner: "",
            email: "",
            telefon: "",
            agb: false,
            newsletter: false,
          });
          setZustandComponente(1);
          navigate("/beratung/projekt/termin");
        }}
      >
        {(props) => (
          <Form
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
            className="px-4 pb-4"
            onSubmit={props.handleSubmit}
          >
            <LinearWithPercent90 />
            <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
              Angaben zur Person oder Unternehmen
            </h1>
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 16 }}
              className="pt-4 pb-4"
            >
              <Grid item xs={9} alignItems="left" justifyContent="left">
                <p align="left" className="angabenp pb-2">
                  Nur noch wenige Angaben und wir können dir dein persönliches
                  Angebot erstellen.
                </p>

                <TextfieldWrapper
                  type="text"
                  placeholder="Vorname"
                  name="vorname"
                  className="textfield"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Nachname"
                  name="nachname"
                  className="textfield"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Unternehmen (optional)"
                  name="unternehmen"
                  className="textfield"
                />

                <FormLabel
                  className="pt-4 pb-2  fs-5 fw-normal lh-lg float-left"
                  id="outlined-adresse"
                >
                  Adresse
                </FormLabel>
                <TextfieldWrapper
                  type="text"
                  placeholder="Adresse"
                  name="adresseAnsprechpartner"
                  className="textfield"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Postleitzahl"
                  name="plzAnsprechpartner"
                  className="textfield"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Ort"
                  name="ortAnsprechpartner"
                  className="textfield"
                  required
                />

                <FormLabel
                  className="pt-4 pb-2  fs-5 fw-normal lh-lg float-left"
                  id="outlined-adresse"
                >
                  Kontaktdaten
                </FormLabel>
                <TextfieldWrapper
                  type="text"
                  placeholder="E-Mail-Adresse"
                  name="email"
                  className="textfield"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Telefonnummer"
                  name="telefon"
                  className="textfield"
                  required
                />

                <FormGroup className="pb-4 pt-4">
                  <CheckboxWrapper
                    name="agb"
                    label="Ich akzeptiere die allgemeinen Geschäftsbedingungen der M&S Stuttgart GmbH."
                    align="left"
                    className="pb-2 font-size-checkboxen checkboxwrapper"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    required
                  />
                  <ErrorMessage
                    name="agb"
                    component="div"
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                  />
                  <CheckboxWrapper
                    name="newsletter"
                    label="Ja, M&S Stuttgart GmbH darf mit nützliche Tipps und Informationen rund um das Thema Handwerk zuschicken. Die Abmeldung ist jederzeit möglich."
                    align="left"
                    className="pb-2 font-size-checkboxen checkboxwrapper"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={7} className="px-2">
                <div className="anfragenHeute pt-2 pb-2">
                  <span>Anfragen heute</span>
                  <br></br>
                  <span>{randomnumber}</span>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 16 }}
            >
              <Grid item xs={2} md={8} sm={4}>
                <button
                  key={"Zurueck"}
                  className="btn btn-secondary me-md-2 mx-2"
                  type="button"
                  onClick={() => {
                    setZustandComponente(-1);
                    navigate("/beratung/projekt/baustelle");
                  }}
                >
                  ZURÜCK
                </button>
              </Grid>
              <Grid item xs={2} md={8} sm={4}>
                <button
                  key={"Weiter"}
                  className="btn btn-success"
                  type="submit"
                >
                  Weiter
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  } else if (zustandComponente == 1) {
    return <Termin />;
  } else if (zustandComponente == -1) {
    return <Baustelle />;
  }
}
