import React, { useState } from "react";
import UseCasesFunction from "./Components/UseCases/UseCasesFunction";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import B2cComponentFunction from "./Components/UseCases/B2cComponent/B2cComponentFunction";

export default function NotFound() {
  // Deklariere eine neue State Variable, die wir "zustandComponente" nennen werden
  const [zustandComponente, setZustandComponente] = useState(0);
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="notFound App">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          404 Nicht Gefunden
        </h1>

        <p className="text_wrapper">
          Leider geht es hier nicht weiter. Klicke auf den Button um zum Start
          der Anfrage zurückzukehren.
        </p>

        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mb-4"
          onClick={() => {
            setZustandComponente(-1);
            navigate("/");
          }}
        >
          ZURÜCK ZUM START
        </button>
        <Footer />
      </div>
    );
  } else if (zustandComponente == -1) {
    return <B2cComponentFunction />;
  }
}
