import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UseCasesFunction from "../UseCasesFunction";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import B2CComponent from "../B2cComponent/B2cComponentFunction";

export default function WiComponent() {
  const b2cLeistung = useSelector((state) => state.b2c.leistung);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Deklariere eine neue State Variable, die wir "zustandComponente" nennen werden
  const [zustandComponente, setZustandComponente] = useState(0);
  if (zustandComponente == 0) {
    return (
      <div>
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wartungen und Instandsetzungen
        </h1>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mb-4 mt-4"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == -1) {
    return <B2CComponent />;
  }
}
