export const onListItemClickedB2CUeberdachungVordach = () => {
  return {
    type: "Vordach",
  };
};
export const onListItemClickedB2CUeberdachungCarport = () => {
  return {
    type: "Carport",
  };
};
export const onListItemClickedB2CUeberdachungPergola = () => {
  return {
    type: "Pergola",
  };
};
export const onListItemClickedB2CUeberdachungTerasse = () => {
  return {
    type: "Terassenüberdachung",
  };
};
export const onListItemClickedB2CUeberdachungBalkon = () => {
  return {
    type: "Balkonüberdachung",
  };
};
export const onListItemClickedB2CUeberdachungHaustuer = () => {
  return {
    type: "Haustürüberdachung",
  };
};
