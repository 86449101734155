import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import B2cComponentFunction from "../B2cComponentFunction";
import FurtherQuestions from "../FurtherQuestions/FurtherQuestions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { handlaufCreators } from "../../../../actions/forms/handlaufCreators";
import {
  onListItemClickedB2CHandlaufGlas,
  onListItemClickedB2CHandlaufStuetzen,
  onListItemClickedB2CHandlaufWand,
} from "../../../../actions/useCases/b2c/handlauf/handlauf";
import WandseiteHandlauf from "./Handlauf/WandseiteHandlauf";
import FormHandlauf from "./Handlauf/FormHandlauf";
import LinearWithPercent5 from "../../../progress/number/progress5";

export default function Handlauf() {
  const dispatch = useDispatch();
  // Deklariere eine neue State Variable, die wir "zustandComponente" nennen werden
  const [zustandComponente, setZustandComponente] = useState(0);
  const [handlaufWand, setHandlaufWand] = useState(0);
  const [handlaufGlas, setHandlaufGlas] = useState(0);
  const [handlaufStuetzen, setHandlaufStuetzen] = useState(0);
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    //Was für einen Handlauf willst du?
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent5 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Was für einen Handlauf willst du?
        </h1>

        <div className="row row-cols-3 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/handlauf/form-handlauf"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setHandlaufGlas(1);
                setZustandComponente(1);
                dispatch(onListItemClickedB2CHandlaufGlas());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Handlauf auf Glas</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/handlauf/wandseite-handlauf"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setHandlaufWand(1);
                setZustandComponente(1);
                dispatch(onListItemClickedB2CHandlaufWand());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Handlauf an der Wand</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/handlauf/form-handlauf"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setHandlaufStuetzen(1);
                setZustandComponente(1);
                dispatch(onListItemClickedB2CHandlaufStuetzen());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Handlauf auf Stützen</p>
            </div>
          </Link>
        </div>

        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    if (handlaufWand == 1 && zustandComponente == 1) {
      //Für welche Wandseite soll der Handlauf sein?
      return <WandseiteHandlauf />;
    } else {
      return <FormHandlauf />;
    }
  } else if (zustandComponente == -1) {
    return <B2cComponentFunction />;
  }
}
