const userReducer = (user = [], action) => {
  switch (action.type) {
    case "CHANGE_SIGNUP_BENUTZERNAME":
      return {
        ...user,
        benutzername: action.payload,
      };
    case "CHANGE_SIGNUP_EMAIL":
      return {
        ...user,
        email: action.payload,
      };
    case "CHANGE_SIGNUP_PASSWORT":
      return {
        ...user,
        passwort: action.payload,
      };
    case "CHANGE_SIGNUP_PASSWORT_WDH":
      return {
        ...user,
        passwortWdh: action.payload,
      };
    default:
      return user;
  }
};
export default userReducer;
