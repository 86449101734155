function treppenReducer(state = [], action) {
  switch (action.type) {
    case "Treppe-Einläufig":
      return {
        ...state,
        Form: "Einläufig",
      };
    case "Treppe-Zweiläufig":
      return {
        ...state,
        Form: "Zweiläufig",
      };

    case "Treppe-Dreiäufig":
      return {
        ...state,
        Form: "Dreiäufig",
      };

    case "Treppe-Gewendelt":
      return {
        ...state,
        Form: "Gewendelt",
      };

    case "Treppe-Stabsgeländer":
      return {
        ...state,
        Geländer: "Stabsgeländer",
      };

    case "Treppe-Seilgeländer":
      return {
        ...state,
        Geländer: "Seilgeländer",
      };

    case "Treppe-Glasgeländer":
      return {
        ...state,
        Geländer: "Glasgeländer",
      };

    case "Treppe-Material-Boden-Marmor":
      return {
        ...state,
        Boden: "Marmor",
      };
    case "Treppe-Material-Boden-Holz":
      return {
        ...state,
        Boden: "Holz",
      };
    case "Treppe-Material-Boden-Edelstahl":
      return {
        ...state,
        Boden: "Edelstahl",
      };
    case "Treppe-Material-Boden-Metall":
      return {
        ...state,
        Boden: "Metall",
      };

    case "Treppe-Innen":
      return {
        ...state,
        Ort: "Innen",
      };
    case "Treppe-Aussen":
      return {
        ...state,
        Ort: "Außen",
      };

    case "Treppe-Material-Metall":
      return {
        ...state,
        Material: "Metall",
      };
    case "Treppe-Material-Edelstahl":
      return {
        ...state,
        Material: "Edelstahl",
      };
    case "Treppe-Material-VerzinkterStahl":
      return {
        ...state,
        Material: "Verzinkter Stahl",
      };

    case "Treppe-1-Stockwerk":
      return {
        ...state,
        AnzahlStockwerke: "1 Stockwerk",
      };
    case "Treppe-2-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "2 Stockwerke",
      };
    case "Treppe-3-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "3 Stockwerke",
      };
    case "Treppe-4-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "4 Stockwerke",
      };
    case "Treppe-5-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "5 Stockwerke",
      };
    case "Treppe-Mehr-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "Mehr als 5 Stockwerke",
      };

    default:
      return state;
  }
}
export default treppenReducer;
