function b2cReducer(state = [], action) {
  switch (action.type) {
    case "Balkone":
      return {
        ...state,
        leistung: "Balkon",
        beschreibungLeistung: "Kunde will einen Balkon realisieren",
      };
    case "Treppe":
      return {
        ...state,
        leistung: "Treppe",
        beschreibungLeistung: "Kunde will eine Treppe realisieren",
      };
    case "Fenster":
      return {
        ...state,
        leistung: "Fenster",
        beschreibungLeistung: "Kunde will Fenster realisieren",
      };
    case "Gelaender":
      return {
        ...state,
        leistung: "Gelaender",
        beschreibungLeistung: "Kunde will ein Geländer realisieren",
      };
    case "Handlauf":
      return {
        ...state,
        leistung: "Handlauf",
        beschreibungLeistung: "Kunde will einen Handlauf realisieren",
      };
    case "Tore":
      return {
        ...state,
        leistung: "Tore",
        beschreibungLeistung: "Kunde will ein oder mehrere Tore realisieren",
      };
    case "Tueren":
      return {
        ...state,
        leistung: "Tueren",
        beschreibungLeistung: "Kunde will eine Türe realisieren lassen",
      };
    case "Ueberdachungen":
      return {
        ...state,
        leistung: "Ueberdachungen",
        beschreibungLeistung: "Kunde will eine Überdachung realisieren",
      };
    case "B2CAndereLeistung":
      return {
        ...state,
        leistung: "B2CAndereLeistung",
        beschreibungLeistung:
          "Kunde will eine andere Leistung realisiert haben ",
      };
    case "B2CWI":
      return {
        ...state,
        leistung: "B2CWI",
        beschreibungLeistung:
          "Kunde will Wartungen oder Instandsetzungen realisieren",
      };

    default:
      return state;
  }
}
export default b2cReducer;
