import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { gelaenderCreators } from "../../../../../actions/forms/gelaenderCreators";

import * as Yup from "yup";
import { Formik, Form } from "formik";

import {
  onListItemClickedB2CGelaenderLaengeBis10Meter,
  onListItemClickedB2CGelaenderLaengeBis11Meter,
  onListItemClickedB2CGelaenderLaengeBis12Meter,
  onListItemClickedB2CGelaenderLaengeBis3Meter,
  onListItemClickedB2CGelaenderLaengeBis4Meter,
  onListItemClickedB2CGelaenderLaengeBis5Meter,
  onListItemClickedB2CGelaenderLaengeBis6Meter,
  onListItemClickedB2CGelaenderLaengeBis7Meter,
  onListItemClickedB2CGelaenderLaengeBis8Meter,
  onListItemClickedB2CGelaenderLaengeBis9Meter,
  onListItemClickedB2CGelaenderLaengeMehrAls12Meter,
} from "../../../../../actions/useCases/b2c/gelaender/gelaender";

import FurtherQuestions from "../../FurtherQuestions/FurtherQuestions";
import Gelaender from "../Gelaender";
import StandortGelaender from "./StandortGelaender";
import LinearWithPercent40 from "../../../../progress/number/progress40";
import Beschreibung from "../../../../contact/beschreibung";
import TextfieldWrapper from "../../../../materialui/textfield";

export default function LaengeGelaender() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const laengeGelaenderValidationSchema = Yup.object().shape({
    laenge: Yup.string()
      .required("Länge muss ausgefüllt werden.")
      .min(1, "Die Länge muss aus mindestens einer Zahl bestehen."),
  });

  if (zustandComponente == 0) {
    return (
      <Formik
        initialValues={{
          laenge: "",
        }}
        validationSchema={laengeGelaenderValidationSchema}
        onSubmit={(values, actions) => {
          gelaenderCreators.updateGelaenderLaenge(values.laenge);
          actions.setSubmitting(false);
          actions.resetForm({
            laenge: "",
          });
          setZustandComponente(1);
          navigate("beratung/projekt");
        }}
      >
        {(props) => (
          <div className="px-4 pb-4">
            <LinearWithPercent40 />
            <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
              Wie lang soll das Geländer werden?
            </h1>
            <Form
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
              className="form-group pt-4 px-4 pb-4"
              onSubmit={props.handleSubmit}
            >
              <div className="pb-4">
                <TextfieldWrapper
                  type="text"
                  placeholder="Länge in Meter"
                  name="laenge"
                  className="textfield form-control pb-4"
                  required
                />
              </div>
              <button
                key={"Zurueck"}
                type="button"
                className="btn btn-secondary me-md-2 mx-2"
                onClick={() => {
                  setZustandComponente(-1);
                  navigate(-1);
                }}
              >
                ZURÜCK
              </button>

              <button key={"Weiter"} className="btn btn-success" type="submit">
                WEITER
              </button>
            </Form>
          </div>
        )}
      </Formik>
    );
  } else if (zustandComponente == 1) {
    return <Beschreibung />;
  } else if (zustandComponente == -1) {
    return <StandortGelaender />;
  }
}
