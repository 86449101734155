import React, { useState, useCallback } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../materialui/textfield";
import { furtherQuestionsCreators } from "../../actions/forms/furtherQuestionsCreators";
import Grid from "@mui/material/Grid";
import LinearWithPercent80 from "../progress/number/progress80";
import { useNavigate } from "react-router-dom";
import WIWartungZeichnungen from "./wartung-zeichnungen";
import WIWartungKontakt from "./wartung-kontakt";

export default function WIWartungBaustelle() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const navigate = useNavigate();

  const baustelleValidationSchema = Yup.object().shape({
    adresse: Yup.string()
      .required("Adresse muss ausgefüllt werden.")
      .min(6, "Die Adresse muss aus mindestens 6 Zeichen bestehen."),
    postleitzahl: Yup.number()
      .required("Postleitzahl muss ausgefüllt werden.")
      .min(5, "Die Postleitzahl muss aus mindestens 5 Zahlen bestehen."),
    ort: Yup.string()
      .required("Ort muss ausgefüllt werden.")
      .min(3, "Der Ortsname muss aus mindestens 3 Buchstaben bestehen."),
  });

  if (zustandComponente == 0) {
    return (
      <Formik
        initialValues={{
          adresse: "",
          postleitzahl: "",
          ort: "",
        }}
        validationSchema={baustelleValidationSchema}
        onSubmit={(values, actions) => {
          furtherQuestionsCreators.adresse(values.adresse);
          furtherQuestionsCreators.baustellePostleitzahl(values.postleitzahl);
          furtherQuestionsCreators.baustelleOrt(values.ort);
          actions.setSubmitting(false);
          actions.resetForm({
            adresse: "",
            postleitzahl: "",
            ort: "",
          });
          setZustandComponente(1);
          navigate("/beratung/wartungen/kontakt");
        }}
      >
        {(props) => (
          <Form
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
            className="px-4 pb-4"
            onSubmit={props.handleSubmit}
          >
            <LinearWithPercent80 />
            <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
              Wo befindet sich die Baustelle?
            </h1>

            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 16 }}
            >
              <Grid item xs={16} alignItems="left" justifyContent="left">
                <TextfieldWrapper
                  type="text"
                  placeholder="Adresse"
                  name="adresse"
                  className="textfield"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Postleitzahl"
                  name="postleitzahl"
                  className="textfield"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Ort"
                  name="ort"
                  className="textfield"
                  required
                />
              </Grid>
            </Grid>
            <br></br>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 12 }}
            >
              <Grid item xs={6}>
                <button
                  key={"Zurueck"}
                  className="btn btn-secondary me-md-2 mx-2"
                  onClick={() => {
                    setZustandComponente(-1);
                    navigate("/beratung/wartungen/zeichnungen");
                  }}
                  type="button"
                >
                  ZURÜCK
                </button>
              </Grid>
              <Grid item xs={6}>
                <button
                  key={"Weiter"}
                  className="btn btn-success"
                  type="submit"
                >
                  WEITER
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  } else if (zustandComponente == 1) {
    return <WIWartungKontakt />;
  } else if (zustandComponente == -1) {
    return <WIWartungZeichnungen />;
  }
}
