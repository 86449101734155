import useCasesReducer from "./useCases";
import b2cReducer from "./b2c";
import b2bReducer from "./b2b";
import wiReducer from "./wi";
import ueberdachungReducer from "./b2c/ueberdachung";
import balkonReducer from "./b2c/balkon";
import treppenReducer from "./b2c/treppen";
import gelaenderReducer from "./b2c/gelaender";
import futherQuestionsReducer from "./furtherQuestions";

import { combineReducers } from "redux";
import handlaufReducer from "./b2c/handlauf";
import anfrageReducer from "./anfrage";
import idReducer from "./id";
import userReducer from "./user";

const rootReducer = combineReducers({
  b2c: b2cReducer,
  useCases: useCasesReducer,
  ueberdachung: ueberdachungReducer,
  balkon: balkonReducer,
  treppen: treppenReducer,
  gelaender: gelaenderReducer,
  furtherQuestions: futherQuestionsReducer,
  handlauf: handlaufReducer,
  anfrage: anfrageReducer,
  id: idReducer,
  user: userReducer,
});

export default rootReducer;
