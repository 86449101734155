import React, { useState } from "react";
import { useSelector } from "react-redux";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../materialui/textfield";
import { furtherQuestionsCreators } from "../../actions/forms/furtherQuestionsCreators";
import LinearWithPercent50 from "../progress/number/progress50";
import { useNavigate } from "react-router-dom";
import Stockwerke from "../UseCases/B2cComponent/Services/Balkone/Stockwerke";
import BeleuchtungHandlauf from "../UseCases/B2cComponent/Services/Handlauf/BeleuchtungHandlauf";
import StockwerkeTreppen from "../UseCases/B2cComponent/Services/Treppen/StockwerkeTreppen";
import LaengeGelaender from "../UseCases/B2cComponent/Services/Gelaender/LaengeGelaender";
import B2cComponentFunction from "../UseCases/B2cComponent/B2cComponentFunction";
import { setAnfrageData } from "../termin/termin";
import Umsetzung from "./umsetzung";

export default function Beschreibung() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const b2cLeistung = useSelector((state) => state.b2c.leistung);

  const beschreibungValidationSchema = Yup.object().shape({
    beschreibung: Yup.string()
      .required("Beschreibung muss ausgefüllt werden.")
      .min(10, "Die Beschreibung muss aus mindestens 10 Buchstaben bestehen."),
  });

  const navigate = useNavigate();
  if (zustandComponente == 0) {
    return (
      <Formik
        initialValues={{
          beschreibung: "",
        }}
        validationSchema={beschreibungValidationSchema}
        onSubmit={(values, actions) => {
          furtherQuestionsCreators.beschreibung(values.beschreibung);
          actions.setSubmitting(false);
          actions.resetForm({
            beschreibung: "",
          });
          setZustandComponente(1);
          navigate("/beratung/projekt/vorangebot");
        }}
      >
        {(props) => (
          <div className="px-4 pb-4">
            <LinearWithPercent50 />
            <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
              Um was geht es bei dem Bauvorhaben?
            </h1>
            <Form
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
              className="form-group pt-4 px-4 pb-4"
              onSubmit={props.handleSubmit}
            >
              <label
                htmlFor="beschreibungProjekt"
                id="beschreibungProjekt"
              ></label>

              <TextfieldWrapper
                type="text"
                placeholder="Beschreibung"
                name="beschreibung"
                className="textfield form-control pb-4"
                required
              />

              <button
                key={"Zurueck"}
                type="button"
                className="btn btn-secondary me-md-2 mx-2"
                onClick={() => {
                  setZustandComponente(-1);
                  navigate(-1);
                }}
              >
                ZURÜCK
              </button>
              <button key={"Weiter"} className="btn btn-success" type="submit">
                WEITER
              </button>
            </Form>
          </div>
        )}
      </Formik>
    );
  } else if (zustandComponente == 1) {
    return <Umsetzung />;
  } else if (zustandComponente == -1) {
    switch (b2cLeistung) {
      case "Balkon":
        return <Stockwerke />;
      case "Handlauf":
        return <BeleuchtungHandlauf />;
      case "Treppe":
        return <StockwerkeTreppen />;
      case "Gelaender":
        return <LaengeGelaender />;
      case "B2CAndereLeistung":
        return <B2cComponentFunction />;

      default:
        return b2cLeistung;
    }
  }
}
