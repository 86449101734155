import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { balkonCreators } from "../../../../../actions/forms/balkonCreators";
import TextfieldWrapper from "../../../../materialui/textfield";

import * as Yup from "yup";
import { Formik, Form } from "formik";

import { useNavigate } from "react-router-dom";
import Material from "./Material";
import Balkonboden from "./Boden";
import LinearWithPercent15 from "../../../../progress/number/progress15";

export default function Masse() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const masseBalkonValidationSchema = Yup.object().shape({
    masseTiefe: Yup.string()
      .required("Tiefe muss ausgefüllt werden.")
      .min(1, "Die Tiefe muss aus mindestens einer Zahl bestehen."),
    masseLaenge: Yup.string()
      .required("Länge muss ausgefüllt werden.")
      .min(1, "Die Länge muss aus mindestens einer Zahl bestehen."),
  });

  if (zustandComponente == 0) {
    return (
      <Formik
        initialValues={{
          masseTiefe: "",
          masseLaenge: "",
        }}
        validationSchema={masseBalkonValidationSchema}
        onSubmit={(values, actions) => {
          //furtherQuestionsCreators.beschreibung(values.beschreibung);
          balkonCreators.updateBalkonMaßTiefe(values.masseTiefe);
          balkonCreators.updateBalkonLaenge(values.masseLaenge);
          actions.setSubmitting(false);
          actions.resetForm({
            masseTiefe: "",
            masseLaenge: "",
          });
          setZustandComponente(1);
          navigate("beratung/balkone/boden-balkon");
        }}
      >
        {(props) => (
          <div className="px-4 pb-4">
            <LinearWithPercent15 />
            <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
              Welche Maße soll der Balkon haben?
            </h1>

            <Form
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
              className="form-group pt-4 px-4 pb-4"
              onSubmit={props.handleSubmit}
            >
              <div className="pb-4">
                <TextfieldWrapper
                  type="text"
                  placeholder="Tiefe in Meter"
                  name="masseTiefe"
                  className="textfield form-control pb-4"
                  required
                />
                <TextfieldWrapper
                  type="text"
                  placeholder="Länge in Meter"
                  name="masseLaenge"
                  className="textfield form-control pb-4"
                  required
                />
              </div>
              <button
                key={"Zurueck"}
                type="button"
                className="btn btn-secondary me-md-2 mx-2"
                onClick={() => {
                  setZustandComponente(-1);
                  navigate(-1);
                }}
              >
                ZURÜCK
              </button>

              <button key={"Weiter"} className="btn btn-success" type="submit">
                WEITER
              </button>
            </Form>
          </div>
        )}
      </Formik>
    );
  } else if (zustandComponente == 1) {
    return <Balkonboden />;
  } else if (zustandComponente == -1) {
    return <Material />;
  }
}
