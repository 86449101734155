import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onListItemClickedB2CBalkonFarbeAnthrazitGrau,
  onListItemClickedB2CBalkonFarbeWeiß,
  onListItemClickedB2CBalkonFarbeVerzinktOhneWeitereBehandlung,
  onListItemClickedB2CBalkonFarbeSchwarz,
  onListItemClickedB2CBalkonFarbeNachWunsch,
} from "../../../../../actions/useCases/b2c/balkone/balkone";
import Balkongelaendertyp from "./Balkongelaendertyp";
import Masse from "./Masse";
import LinearWithPercent5 from "../../../../progress/number/progress5";
import Material from "./Material";
import Balkone from "../Balkone";

export default function FarbeBalkon() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent5 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Welche Farbe soll dein Balkon haben?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/gelaendertyp-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonFarbeAnthrazitGrau());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Anthrazit Grau</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/gelaendertyp-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonFarbeWeiß());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Weiß</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/gelaendertyp-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CBalkonFarbeVerzinktOhneWeitereBehandlung()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Verzinkt ohne weitere Behandlung</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/gelaendertyp-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonFarbeSchwarz());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Schwarz</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/gelaendertyp-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonFarbeNachWunsch());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Farbe nach Wunsch</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <Material />;
  } else if (zustandComponente == -1) {
    return <Balkone />;
  }
}
