import { FETCH_ALL, CREATE, UPDATE, DELETE } from "../constants/actionTypes";

const anfrageReducer = (anfragen = [], action) => {
  switch (action.type) {
    //FETCH_ALL, CREATE, UPDATE, DELETE
    case FETCH_ALL:
      return action.payload;
    case CREATE:
      return [...anfragen, action.payload];
    case UPDATE:
      return anfragen.map((anfrage) =>
        anfrage._id === action.payload._id ? action.payload : anfrage
      );
    case DELETE:
      return anfragen.filter((anfrage) => anfrage._id !== action.payload);
    //Leistung und Beschreibung der Leistung
    case "CURRENT_ID":
      return {
        ...anfragen,
        currentId: action.payload,
      };
    case "SET_CURRENT_ID":
      return {
        ...anfragen,
        setCurrentId: action.payload,
      };

    case "Balkone":
      return {
        ...anfragen,
        leistung: "Balkon",
        beschreibungLeistung: "Kunde will einen Balkon realisieren",
      };
    case "Treppe":
      return {
        ...anfragen,
        leistung: "Treppe",
        beschreibungLeistung: "Kunde will eine Treppe realisieren",
      };
    case "Fenster":
      return {
        ...anfragen,
        leistung: "Fenster",
        beschreibungLeistung: "Kunde will Fenster realisieren",
      };
    case "Gelaender":
      return {
        ...anfragen,
        leistung: "Gelaender",
        beschreibungLeistung: "Kunde will ein Geländer realisieren",
      };
    case "Handlauf":
      return {
        ...anfragen,
        leistung: "Handlauf",
        beschreibungLeistung: "Kunde will einen Handlauf realisieren",
      };
    case "Tore":
      return {
        ...anfragen,
        leistung: "Tore",
        beschreibungLeistung: "Kunde will ein oder mehrere Tore realisieren",
      };
    case "Tueren":
      return {
        ...anfragen,
        leistung: "Tueren",
        beschreibungLeistung: "Kunde will eine Türe realisieren lassen",
      };
    case "Ueberdachungen":
      return {
        ...anfragen,
        leistung: "Ueberdachungen",
        beschreibungLeistung: "Kunde will eine Überdachung realisieren",
      };
    case "B2CAndereLeistung":
      return {
        ...anfragen,
        leistung: "B2CAndereLeistung",
        beschreibungLeistung:
          "Kunde will eine andere Leistung realisiert haben ",
      };
    case "B2CWI":
      return {
        ...anfragen,
        leistung: "B2CWI",
        beschreibungLeistung:
          "Kunde will Wartungen oder Instandsetzungen realisieren",
      };
    //BESCHREIBUNG; KUNDENINFORMATIONEN, BAUSTELLENINFORMATIONEN, BILDER, SKIZZEN, FINANZIERUNG
    case "CHANGE_PROJEKT_BESCHREIBUNG":
      return {
        ...anfragen,
        beschreibung: action.payload,
      };
    case "CHANGE_PROJEKT_BILDER_BAUSTELLE":
      return {
        ...anfragen,
        bilderBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_BILDER_BAUSTELLE_NAME":
      return {
        ...anfragen,
        bilderBaustelleName: action.payload,
      };
    case "CHANGE_PROJEKT_BILDER_BAUSTELLE_PATH":
      return {
        ...anfragen,
        bilderBaustellePath: action.payload,
      };
    case "CHANGE_PROJEKT_BILDER_BAUSTELLE_PATH_URL":
      return {
        ...anfragen,
        bilderBaustellePathUrl: action.payload,
      };
    case "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE":
      return {
        ...anfragen,
        zeichnungenBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE_NAME":
      return {
        ...anfragen,
        zeichnungenBaustelleName: action.payload,
      };
    case "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE_PATH":
      return {
        ...anfragen,
        zeichnungenBaustellePath: action.payload,
      };
    case "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE_PATH_URL":
      return {
        ...anfragen,
        zeichnungenBaustellePathUrl: action.payload,
      };
    case "CHANGE_PROJEKT_BAUSTELLE_ADRESSE":
      return {
        ...anfragen,
        adresseBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_BAUSTELLE_POSTLEITZAHL":
      return {
        ...anfragen,
        postleitzahlBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_BAUSTELLE_ORT":
      return {
        ...anfragen,
        ortBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_GESCHLECHT":
      return {
        ...anfragen,
        geschlecht: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_VORNAME":
      return {
        ...anfragen,
        vornameAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_NACHNAME":
      return {
        ...anfragen,
        nachnameAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_UNTERNEHMEN":
      return {
        ...anfragen,
        unternehmen: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_ADRESSE":
      return {
        ...anfragen,
        adresseAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_POSTLEITZAHL":
      return {
        ...anfragen,
        postleitzahlAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_ORT":
      return {
        ...anfragen,
        ortAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_TELEFONNUMMER":
      return {
        ...anfragen,
        telefonnummer: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_EMAIL":
      return {
        ...anfragen,
        eMail: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_AGB":
      return {
        ...anfragen,
        agb: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_NEWSLETTER":
      return {
        ...anfragen,
        newsletter: action.payload,
      };

    case "Vorangebot-Ja":
      return {
        ...anfragen,
        vorangebot: "Ja",
      };

    case "Vorangebot-Nein":
      return {
        ...anfragen,
        vorangebot: "Nein",
      };

    case "Umsetzung-Sofort":
      return {
        ...anfragen,
        umsetzung: "Sofort",
      };
    case "Umsetzung-1bis3Monate":
      return {
        ...anfragen,
        umsetzung: "1 bis 3 Monate",
      };
    case "Umsetzung-3bis6Monate":
      return {
        ...anfragen,
        umsetzung: "3 bis 6 Monate",
      };
    case "Umsetzung-6bis12Monate":
      return {
        ...anfragen,
        umsetzung: "6 bis 12 Monate",
      };
    case "Umsetzung-MehrAls12Monate":
      return {
        ...anfragen,
        umsetzung: "Mehr als 12 Monate",
      };

    case "Kaufen":
      return {
        ...anfragen,
        kaufenOderFinanzieren: "Kaufen",
      };
    case "Finanzieren":
      return {
        ...anfragen,
        kaufenOderFinanzieren: "Finanzieren",
      };
    //BALKONE -----------------------------------------------
    case "Vorstellbalkon":
      return {
        ...anfragen,
        artBalkon: action.type,
      };
    case "Hängebalkon":
      return {
        ...anfragen,
        artBalkon: action.type,
      };
    case "Anthrazit Grau":
      return {
        ...anfragen,
        farbeBalkon: action.type,
      };
    case "Weiß":
      return {
        ...anfragen,
        farbeBalkon: action.type,
      };
    case "Verzinkt ohne weitere Behandlung":
      return {
        ...anfragen,
        farbeBalkon: action.type,
      };
    case "Schwarz":
      return {
        ...anfragen,
        farbeBalkon: action.type,
      };
    case "Farbe nach Wunsch":
      return {
        ...anfragen,
        farbeBalkon: action.type,
      };
    case "CHANGE_BALKON_TIEFE":
      return {
        ...anfragen,
        tiefeInMeterBalkon: action.payload,
      };
    case "CHANGE_BALKON_LAENGE":
      return {
        ...anfragen,
        laengeInMeterBalkon: action.payload,
      };
    case "Stahl":
      return {
        ...anfragen,
        materialBalkon: action.type,
      };
    case "Edelstahl":
      return {
        ...anfragen,
        materialBalkon: action.type,
      };

    case "WaschbetonplattenBodenBalkon":
      return {
        ...anfragen,
        bodenBalkon: "Waschbetonplatten",
      };
    case "AluminiumBodenBalkon":
      return {
        ...anfragen,
        bodenBalkon: "Aluminium",
      };
    case "LaerchenholzBodenBalkon":
      return {
        ...anfragen,
        bodenBalkon: "Laerchenholz",
      };
    case "BangkiraiBodenBalkon":
      return {
        ...anfragen,
        bodenBalkon: "Bangkirai",
      };
    case "BelagNachWunschBodenBalkon":
      return {
        ...anfragen,
        bodenBalkon: "Belag nach Wunsch",
      };
    case "MitSenkrechtenStaebenAufbauBalkon":
      return {
        ...anfragen,
        gelaenderAufbauBalkon: "Mit senkrechten Staeben",
      };
    case "HorizontaleFuellungAufbauBalkon":
      return {
        ...anfragen,
        gelaenderAufbauBalkon: "Horizontale Fuellung",
      };
    case "KassettenfuellungMitSenkrechtenStaebenAufbauBalkon":
      return {
        ...anfragen,
        gelaenderAufbauBalkon: "Kassettenfuellung mit senkrechten Staeben",
      };
    case "KassettenfuellungMitHorizontalenStaebenAufbauBalkon":
      return {
        ...anfragen,
        gelaenderAufbauBalkon: "Kassettenfuellung mit horizontalen Staeben",
      };
    case "GlasfuellungAufbauBalkon":
      return {
        ...anfragen,
        gelaenderAufbauBalkon: "Glasfuellung",
      };
    case "MitSeilenAufbauBalkon":
      return {
        ...anfragen,
        gelaenderAufbauBalkon: "Mit Seilen",
      };
    case "Sichtschutz Einseitig":
      return {
        ...anfragen,
        sichtschutzBalkon: "Einseitig",
      };
    case "Sichtschutz Beidseitig":
      return {
        ...anfragen,
        sichtschutzBalkon: "Beidseitig",
      };
    case "Kein Sichtschutz":
      return {
        ...anfragen,
        sichtschutzBalkon: "Keiner",
      };
    case "Glas Sichtschutz":
      return {
        ...anfragen,
        sichtschutzArt: "Glas",
      };
    case "Hartfaserplatten Sichtschutz":
      return {
        ...anfragen,
        sichtschutzArt: "Hartfaserplatten",
      };

    case "Farbe Sichtschutz Anthrazit":
      return {
        ...anfragen,
        sichtschutzFarbeBalkon: "Anthrazit",
      };

    case "Farbe Sichtschutz  Weiß":
      return {
        ...anfragen,
        sichtschutzFarbeBalkon: "Weiß",
      };

    case "Farbe Sichtschutz Grau":
      return {
        ...anfragen,
        sichtschutzFarbeBalkon: "Grau",
      };

    case "Balkondach Ja":
      return {
        ...anfragen,
        dachBalkon: "Ja",
      };
    case "Balkondach Nein":
      return {
        ...anfragen,
        dachBalkon: "Nein",
      };

    case "Balkon Handlauf Ja":
      return {
        ...anfragen,
        handlaufBalkon: "Ja",
      };
    case "Balkon Handlauf Nein":
      return {
        ...anfragen,
        handlaufBalkon: "Nein",
      };
    case "Balkon Beschichtung Verzinkt":
      return {
        ...anfragen,
        beschichtungBalkon: "Verzinkt",
      };
    case "Verzinkt mit Nassbeschichtung (Duplexbeschichtung)":
      return {
        ...anfragen,
        beschichtungBalkon:
          "Verzinkt mit Nassbeschichtung (Duplexbeschichtung)",
      };
    case "Balkon Treppe zum Garten Ja":
      return {
        ...anfragen,
        treppeZumGartenBalkon: "Ja",
      };
    case "Balkon Treppe zum Garten Nein":
      return {
        ...anfragen,
        treppeZumGartenBalkon: "Nein",
      };
    case "Balkon 1-Stockwerk":
      return {
        ...anfragen,
        anzahlStockwerkeBalkon: "1 Stockwerk",
      };
    case "Balkon 2-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeBalkon: "2 Stockwerke",
      };
    case "Balkon 3-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeBalkon: "3 Stockwerke",
      };
    case "Balkon 4-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeBalkon: "4 Stockwerke",
      };
    case "Balkon 5-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeBalkon: "5 Stockwerke",
      };
    case "Balkon Mehr-Als-5-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeBalkon: "Mehr als 5 Stockwerke",
      };
    //TREPPE -----------------------------------------------
    case "Treppe-Einläufig":
      return {
        ...anfragen,
        formTreppe: "Einläufig",
      };
    case "Treppe-Zweiläufig":
      return {
        ...anfragen,
        formTreppe: "Zweiläufig",
      };

    case "Treppe-Dreiäufig":
      return {
        ...anfragen,
        formTreppe: "Dreiäufig",
      };

    case "Treppe-Gewendelt":
      return {
        ...anfragen,
        formTreppe: "Gewendelt",
      };

    case "Treppe-Aufbau-Mit-Senkrechten-Staeben":
      return {
        ...anfragen,
        aufbauGelaenderTreppe: "Mit senkrechten Stäben",
      };
    case "Treppe-Aufbau-Horizontale-Fuellung":
      return {
        ...anfragen,
        aufbauGelaenderTreppe: "Horizontale Füllung",
      };
    case "Treppe-Aufbau-Kassetenfuellung-Mit-Senkrechten-Staeben":
      return {
        ...anfragen,
        aufbauGelaenderTreppe: "Kassetenfüllung mit senkrechten Stäben",
      };
    case "Treppe-Aufbau-Kassetenfuellung-Mit-Horizontalen-Staeben":
      return {
        ...anfragen,
        aufbauGelaenderTreppe: "Kassetenfüllung mit horizontalen Stäben",
      };
    case "Treppe-Aufbau-Glasfuellung":
      return {
        ...anfragen,
        aufbauGelaenderTreppe: "Glasfüllung",
      };
    case "Treppe-Aufbau-Mit-Seilen":
      return {
        ...anfragen,
        aufbauGelaenderTreppe: "Mit Seilen",
      };
    //ausstorieren start
    case "Treppe-Stabsgeländer":
      return {
        ...anfragen,
        gelaenderTreppe: "Stabsgeländer",
      };

    case "Treppe-Seilgeländer":
      return {
        ...anfragen,
        gelaenderTreppe: "Seilgeländer",
      };

    case "Treppe-Glasgeländer":
      return {
        ...anfragen,
        gelaenderTreppe: "Glasgeländer",
      };
    //Aussortieren Ende
    case "Treppe-Stufen-Buchenholz":
      return {
        ...anfragen,
        stufenTreppe: "Buchenholz",
      };
    case "Treppe-Stufen-Eichenholz":
      return {
        ...anfragen,
        stufenTreppe: "Eichenholz",
      };
    case "Treppe-Stufen-Ahornholz":
      return {
        ...anfragen,
        stufenTreppe: "Ahornholz",
      };
    case "Treppe-Stufen-Granit":
      return {
        ...anfragen,
        stufenTreppe: "Granit",
      };
    case "Treppe-Stufen-Granit-Rosa-Beta":
      return {
        ...anfragen,
        stufenTreppe: "Granit Rosa Beta",
      };
    case "Treppe-Stufen-Imperial":
      return {
        ...anfragen,
        stufenTreppe: "Imperial",
      };
    case "Treppe-Stufen-Wannenstufen":
      return {
        ...anfragen,
        stufenTreppe: "Wannenstufen",
      };
    case "Treppe-Stufen-Feinmaschige-Gitterroststufen":
      return {
        ...anfragen,
        stufenTreppe: "Feinmaschige Gitterroststufen",
      };
    case "Treppe-Stufen-Normale-Gitterroststufen":
      return {
        ...anfragen,
        stufenTreppe: "Normale Gitterroststufen",
      };
    case "Treppe-Stufen-Normale-Nach-Wunsch":
      return {
        ...anfragen,
        stufenTreppe: "Stufen nach Wunsch",
      };

    case "Treppe-Material-Boden-Marmor":
      return {
        ...anfragen,
        bodenTreppe: "Marmor",
      };
    case "Treppe-Material-Boden-Holz":
      return {
        ...anfragen,
        bodenTreppe: "Holz",
      };
    case "Treppe-Material-Boden-Edelstahl":
      return {
        ...anfragen,
        bodenTreppe: "Edelstahl",
      };
    case "Treppe-Material-Boden-Metall":
      return {
        ...anfragen,
        bodenTreppe: "Metall",
      };

    case "Treppe-Innen":
      return {
        ...anfragen,
        ortTreppe: "Innen",
      };
    case "Treppe-Aussen":
      return {
        ...anfragen,
        ortTreppe: "Außen",
      };

    case "Treppe-Material-Stahl":
      return {
        ...anfragen,
        materialTreppe: "Stahl",
      };
    case "Treppe-Material-Edelstahl":
      return {
        ...anfragen,
        materialTreppe: "Edelstahl",
      };
    case "Treppe-Material-VerzinkterStahl":
      return {
        ...anfragen,
        materialTreppe: "Verzinkter Stahl",
      };
    case "Treppe-Beschichtung-Nasslackierung":
      return {
        ...anfragen,
        beschichtungTreppe: "Nasslackierung",
      };
    case "Treppe-Beschichtung-Pulverbeschichtung":
      return {
        ...anfragen,
        beschichtungTreppe: "Pulverbeschichtung",
      };

    case "Treppe-1-Stockwerk":
      return {
        ...anfragen,
        anzahlStockwerkeTreppe: "1 Stockwerk",
      };
    case "Treppe-2-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeTreppe: "2 Stockwerke",
      };
    case "Treppe-3-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeTreppe: "3 Stockwerke",
      };
    case "Treppe-4-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeTreppe: "4 Stockwerke",
      };
    case "Treppe-5-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeTreppe: "5 Stockwerke",
      };
    case "Treppe-Mehr-Stockwerke":
      return {
        ...anfragen,
        anzahlStockwerkeTreppe: "Mehr als 5 Stockwerke",
      };
    //GELÄNDER -----------------------------------------------
    case "Gelaender-Anbringung-Balkon":
      return {
        ...anfragen,
        anbringungGelaender: "Balkon",
      };
    case "Gelaender-Anbringung-Treppe":
      return {
        ...anfragen,
        anbringungGelaender: "Treppe",
      };
    case "Gelaender-Anbringung-Gehweg":
      return {
        ...anfragen,
        anbringungGelaender: "Gehweg",
      };
    case "Gelaender-Anbringung-Galerie":
      return {
        ...anfragen,
        anbringungGelaender: "Galerie",
      };
    case "Gelaender-Anbringung-Industriegelaender":
      return {
        ...anfragen,
        anbringungGelaender: "Industriegeländer",
      };
    case "Gelaender-Anbringung-Dach":
      return {
        ...anfragen,
        anbringungGelaender: "Dach",
      };
    case "Gelaender-Anbringung-Fenster-Franzoesisches-Gelaender":
      return {
        ...anfragen,
        anbringungGelaender: "Fenster (Französisches Geländer)",
      };
    case "Gelaender-Anbringung-Sonstige-Orte":
      return {
        ...anfragen,
        anbringungGelaender: "Sonstige Orte",
      };
    case "Material-Stahl":
      return {
        ...anfragen,
        materialGrundgeruestGelaender: "Stahl",
      };
    case "Material-Edelstahl":
      return {
        ...anfragen,
        materialGrundgeruestGelaender: "Edelstahl",
      };

    case "Gelaender-Pfosten-Rohrpfosten-Rund":
      return {
        ...anfragen,
        pfostenGelaender: "Rohrpfosten Rund",
      };
    case "Gelaender-Pfosten-Rohrpfosten-Rechteckig":
      return {
        ...anfragen,
        pfostenGelaender: "Rohrpfosten Rechteckig",
      };
    case "Gelaender-Pfosten-Flachpfosten":
      return {
        ...anfragen,
        pfostenGelaender: "Flachpfosten",
      };

    case "Gelaender-Fuellung-Mit-Senkrechten-Staeben":
      return {
        ...anfragen,
        fuellungGelaender: "Mit senkrechten Stäben",
      };
    case "Gelaender-Fuellung-Horizontale-Fuellung":
      return {
        ...anfragen,
        fuellungGelaender: "Horizontale Füllung",
      };
    case "Gelaender-Fuellung-Kassettenfüllung-Mit-Senkrechten-Staeben":
      return {
        ...anfragen,
        fuellungGelaender: "Kassettenfüllung mit senkrechten Stäben",
      };
    case "Gelaender-Fuellung-Kassettenfüllung-Mit-Horizontalen-Staeben":
      return {
        ...anfragen,
        fuellungGelaender: "Kassettenfüllung mit horizontalen Stäben",
      };
    case "Gelaender-Fuellung-Glasfuellung":
      return {
        ...anfragen,
        fuellungGelaender: "Glasfüllung",
      };
    case "Gelaender-Fuellung-Mit-Seilen":
      return {
        ...anfragen,
        fuellungGelaender: "Mit Seilen",
      };
    case "Gelaender-Fuellung-Material-Edelstahl":
      return {
        ...anfragen,
        fuellungMaterialGelaender: "Edelstahl",
      };
    case "Gelaender-Fuellung-Material-Glas":
      return {
        ...anfragen,
        fuellungMaterialGelaender: "Glas",
      };
    case "Gelaender-Fuellung-Material-Ganzglas":
      return {
        ...anfragen,
        fuellungMaterialGelaender: "Ganzglas",
      };
    case "Gelaender-Form-Geradlaeufig":
      return {
        ...anfragen,
        formGelaender: "Geradläufig",
      };
    case "Gelaender-Form-U-Foermig":
      return {
        ...anfragen,
        formGelaender: "U-förmig",
      };
    case "Gelaender-Form-L-Foermig":
      return {
        ...anfragen,
        formGelaender: "L-förmig",
      };
    case "Gelaender-Form-Sonderform":
      return {
        ...anfragen,
        formGelaender: "Sonderform",
      };

    case "Montage-Außen":
      return {
        ...anfragen,
        montageGelaender: "Außen",
      };
    case "Montage-Innen":
      return {
        ...anfragen,
        montageGelaender: "Innen",
      };

    case "CHANGE_GELAENDER_LAENGE":
      return {
        ...anfragen,
        laengeGelaender: action.payload,
      };
    //HANDLAUF -----------------------------------------------
    case "Handlauf_auf_Glas":
      return {
        ...anfragen,
        artHandlauf: "Auf Glas",
      };
    case "Handlauf_an_der_Wand":
      return {
        ...anfragen,
        artHandlauf: "An der Wand",
      };
    case "Handlauf_auf_Stuetzen":
      return {
        ...anfragen,
        artHandlauf: "Auf Stützen",
      };

    case "Handlauf_Linke_Wandseite":
      return {
        ...anfragen,
        wandseiteHandlauf: "Links",
      };
    case "Handlauf_Rechte_Wandseite":
      return {
        ...anfragen,
        wandseiteHandlauf: "Rechts",
      };

    case "Handlauf_Form_Rund":
      return {
        ...anfragen,
        formHandlauf: "Rund",
      };
    case "Handlauf_Form_Eckig":
      return {
        ...anfragen,
        formHandlauf: "Eckig",
      };
    case "Handlauf_Form_Oval":
      return {
        ...anfragen,
        formHandlauf: "Oval",
      };
    case "Handlauf_Durchmesser_34mm":
      return {
        ...anfragen,
        durchmesserHandlauf: "34mm",
      };
    case "Handlauf_Durchmesser_44mm":
      return {
        ...anfragen,
        durchmesserHandlauf: "44mm",
      };
    case "Handlauf_Durchmesser_48mm":
      return {
        ...anfragen,
        durchmesserHandlauf: "48mm",
      };
    case "Handlauf_Ende_Oben_Gerade":
      return {
        ...anfragen,
        handlaufendeOben: "Gerade",
      };
    case "Handlauf_Ende_Oben_90_Grad_Bogen_nach_Unten":
      return {
        ...anfragen,
        handlaufendeOben: "90 Grad Bogen nach Unten",
      };
    case "Handlauf_Ende_Oben_90_Grad_Bogen_zur_Wand":
      return {
        ...anfragen,
        handlaufendeOben: "90 Grad Bogen zur Wand",
      };
    case "Handlauf_Ende_Unten_Gerade":
      return {
        ...anfragen,
        handlaufendeUnten: "Gerade",
      };
    case "Handlauf_Ende_Unten_90_Grad_Bogen_nach_Unten":
      return {
        ...anfragen,
        handlaufendeUnten: "90 Grad Bogen nach Unten",
      };
    case "Handlauf_Ende_Unten_90_Grad_Bogen_zur_Wand":
      return {
        ...anfragen,
        handlaufendeUnten: "90 Grad Bogen zur Wand",
      };

    case "Handlauf_Endkappe_Oben_Flach":
      return {
        ...anfragen,
        endkappeObenHandlauf: "Flach",
      };

    case "Handlauf_Endkappe_Oben_Gewoelbt":
      return {
        ...anfragen,
        endkappeObenHandlauf: "Gewölbt",
      };

    case "Handlauf_Endkappe_Oben_Halbkugel":
      return {
        ...anfragen,
        endkappeObenHandlauf: "Halbkugel",
      };

    case "Handlauf_Endkappe_Unten_Flach":
      return {
        ...anfragen,
        endkappeUntenHandlauf: "Flach",
      };

    case "Handlauf_Endkappe_Unten_Gewoelbt":
      return {
        ...anfragen,
        endkappeUntenHandlauf: "Gewölbt",
      };

    case "Handlauf_Endkappe_Unten_Halbkugel":
      return {
        ...anfragen,
        endkappeUntenHandlauf: "Halbkugel",
      };

    case "Handlauf_Ohne_Abdeckrosseten":
      return {
        ...anfragen,
        abdeckrossetenHandlauf: "Ohne",
      };

    case "Handlauf_Mit_Abdeckrosseten":
      return {
        ...anfragen,
        abdeckrossetenHandlauf: "Mit",
      };

    case "Handlauf_Material_Eichenholz":
      return {
        ...anfragen,
        materialHandlauf: "Eichenholz",
      };
    case "Handlauf_Material_Buchenholz":
      return {
        ...anfragen,
        materialHandlauf: "Buchenholz",
      };
    case "Handlauf_Material_Edelstahl":
      return {
        ...anfragen,
        materialHandlauf: "Edelstahl",
      };
    case "Handlauf_Material_Stahl":
      return {
        ...anfragen,
        materialHandlauf: "Stahl",
      };
    case "Handlauf_Ort_Innen":
      return {
        ...anfragen,
        ortHandlauf: "Innen",
      };
    case "Handlauf_Ort_Aussen":
      return {
        ...anfragen,
        ortHandlauf: "Außen",
      };

    case "Handlauf_Montage_Ja":
      return {
        ...anfragen,
        montageHandlauf: "Ja",
      };
    case "Handlauf_Montage_Nein":
      return {
        ...anfragen,
        montageHandlauf: "Nein",
      };
    case "Handlauf_Beleuchtung_Nein":
      return {
        ...anfragen,
        beleuchtungHandlauf: "Nein",
      };
    case "Handlauf_Beleuchtung_Mit_LED":
      return {
        ...anfragen,
        beleuchtungHandlauf: "Mit LED",
      };

    case "CHANGE_HANDLAUF_LAENGE":
      return {
        ...anfragen,
        laengeHandlauf: action.payload,
      };

    //WARTUNGEN & INSTANDSETZUNGEN -----------------------------------------------

    case "WI-Maengel-Ja":
      return {
        ...anfragen,
        maengelWI: "Ja",
      };
    case "WI-Maengel-Nein":
      return {
        ...anfragen,
        maengelWI: "Nein",
      };
    case "WI-Wartung-Case-Ja":
      return {
        ...anfragen,
        wartungWI: "Ja",
      };
    case "WI-Wartung-Case-Nein":
      return {
        ...anfragen,
        wartungWI: "Nein",
      };
    case "WI-Wartung-Einmalig":
      return {
        ...anfragen,
        haeufigkeitWI: "Einmalig",
      };
    case "WI-Wartung-Regelmaessig":
      return {
        ...anfragen,
        haeufigkeitWI: "Regelmäßig",
      };
    case "WI-Wartung-Art-Korrigierend":
      return {
        ...anfragen,
        artWI: "Korrigierende Wartung",
      };
    case "WI-Wartung-Art-Vorbeugend":
      return {
        ...anfragen,
        artWI: "Vorbeugende Wartung",
      };
    case "WI-Wartung-Art-Vorausschauend":
      return {
        ...anfragen,
        artWI: "Vorausschauende Wartung",
      };
    case "WI-Wartung-Plan-Ja-Vorhanden":
      return {
        ...anfragen,
        wartungsplanWI: "Ja vorhanden",
      };
    case "WI-Wartung-Plan-Nein-Muss-Erstellt-Werden":
      return {
        ...anfragen,
        wartungsplanWI: "Nein, muss erstellt werden",
      };
    case "WI-Wartung-Inspektion-Ja":
      return {
        ...anfragen,
        inspektionWI: "Ja",
      };
    case "WI-Wartung-Inspektion-Nein":
      return {
        ...anfragen,
        inspektionWI: "Nein",
      };
    case "WI-Wartung-Empfehlungen-Instandsetzungsarbeiten-Ja":
      return {
        ...anfragen,
        empfehlungenInstandsetzungsarbeitenWI: "Ja",
      };
    case "WI-Wartung-Empfehlungen-Instandsetzungsarbeiten-Nein":
      return {
        ...anfragen,
        empfehlungenInstandsetzungsarbeitenWI: "Nein",
      };
    case "WI-Wartung-Instandsetzung-Notwendig":
      return {
        ...anfragen,
        instandsetzungReparaturBeidesWI: "Instandsetzung notwendig",
      };
    case "WI-Wartung-Reparatur-Notwendig":
      return {
        ...anfragen,
        instandsetzungReparaturBeidesWI: "Reparatur notwendig",
      };
    case "WI-Wartung-Beides-Notwendig":
      return {
        ...anfragen,
        instandsetzungReparaturBeidesWI: "Beides notwendig",
      };
    case "WI-Wartung-Dringlichkeit-Ja":
      return {
        ...anfragen,
        dringlichkeitWI: "Ja",
      };
    case "WI-Wartung-Dringlichkeit-Nein":
      return {
        ...anfragen,
        dringlichkeitWI: "Nein",
      };
    case "WI-Wartung-Beginn-Sofort":
      return {
        ...anfragen,
        beginnWI: "Sofort",
      };
    case "WI-Wartung-Beginn-1bis2Wochen":
      return {
        ...anfragen,
        beginnWI: "1 bis2 Wochen",
      };
    case "WI-Wartung-Beginn-2bis4Wochen":
      return {
        ...anfragen,
        beginnWI: "2 bis 4 Wochen",
      };
    case "WI-Wartung-Beginn-1bis3Monaten":
      return {
        ...anfragen,
        beginnWI: "1 bis 3 Monaten",
      };
    case "WI-Wartung-Beginn-3bis6Monaten":
      return {
        ...anfragen,
        beginnWI: "3 bis 6 Monaten",
      };
    case "WI-Wartung-Beginn-InMehrAls6Monaten":
      return {
        ...anfragen,
        beginnWI: "In mehr als 6 Monaten",
      };
    default:
      return anfragen;
  }
};
export default anfrageReducer;
