import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Dir from "./Dir";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./Components/user/login";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

//import img from "./image.png";

import { Provider } from "react-redux";
import store from "./store";
import parseHTML from "html-react-parser";

ReactDOM.render(
  <Auth0Provider
    domain="dev-qk5jk9ep.us.auth0.com"
    clientId="d2gmHGi6G76spErNXnJs9Wumqr1wce88"
    redirectUri={window.location.origin}
    audience="https://api.metallbau-stahlbau-stuttgart.de"
    scope="read:current_user update:current_user_metadata"
  >
    <Provider store={store}>
      <div className="IndexApp">
        <nav className="navbar navbar-expand-xl  navfarbe">
          <div className="container-fluid justify-content-center rechtsordnen">
            <a
              className=""
              href="https://anfrage.metallbau-stahlbau-stuttgart.de"
            >
              <img
                className="navbar-brand navbild"
                src={
                  "https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2020/06/logo-fuer-web.png"
                }
              ></img>
            </a>
          </div>
        </nav>

        <nav className="navbar navbar-expand-xl justify-content-center navfarbegruen">
          <p className=" text-white mt-2 mb-2 px-2 text-center">
            Diese Anwendung befindet sich in der Entwicklung und nimmt noch
            keine Kundenanfragen entgegen.
          </p>
        </nav>

        <App />
      </div>
    </Provider>
  </Auth0Provider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
