function futherQuestionsReducer(state = [], action) {
  switch (action.type) {
    case "CHANGE_PROJEKT_BESCHREIBUNG":
      return {
        ...state,
        Beschreibung: action.payload,
      };
    case "CHANGE_PROJEKT_BILDER_BAUSTELLE":
      return {
        ...state,
        BilderBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_ZEICHNUNGEN_SKIZZEN_BAUSTELLE":
      return {
        ...state,
        ZeichnungenSkizzen: action.payload,
      };
    case "CHANGE_PROJEKT_BAUSTELLE_ADRESSE":
      return {
        ...state,
        AdresseBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_BAUSTELLE_POSTLEITZAHL":
      return {
        ...state,
        PostleitzahlBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_BAUSTELLE_ORT":
      return {
        ...state,
        OrtBaustelle: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_VORNAME":
      return {
        ...state,
        VornameAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_NACHNAME":
      return {
        ...state,
        NachnameAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_UNTERNEHMEN":
      return {
        ...state,
        UnternehmenAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_STRASSE_HAUSNUMMER":
      return {
        ...state,
        StrasseHausnummerAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_POSTLEITZAHL":
      return {
        ...state,
        PostleitzahlAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_ORT":
      return {
        ...state,
        OrtAnsprechpartner: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_TELEFONNUMMER":
      return {
        ...state,
        Telefonnummer: action.payload,
      };
    case "CHANGE_PROJEKT_ANGABEN_ANSPRECHPARTNER_EMAIL":
      return {
        ...state,
        EMail: action.payload,
      };

    case "Vorangebot-Ja":
      return {
        ...state,
        Vorangebot: "Ja",
      };

    case "Vorangebot-Nein":
      return {
        ...state,
        Vorangebot: "Nein",
      };

    case "Umsetzung-Sofort":
      return {
        ...state,
        Umsetzung: "Sofort",
      };
    case "Umsetzung-1bis3Monate":
      return {
        ...state,
        Umsetzung: "1 bis 3 Monate",
      };
    case "Umsetzung-3bis6Monate":
      return {
        ...state,
        Umsetzung: "3 bis 6 Monate",
      };
    case "Umsetzung-6bis12Monate":
      return {
        ...state,
        Umsetzung: "6 bis 12 Monate",
      };
    case "Umsetzung-MehrAls12Monate":
      return {
        ...state,
        Umsetzung: "Mehr als 12 Monate",
      };

    case "Kaufen":
      return {
        ...state,
        KaufenOderFinanzieren: "Kaufen",
      };
    case "Finanzieren":
      return {
        ...state,
        KaufenOderFinanzieren: "Finanzieren",
      };

    default:
      return state;
  }
}
export default futherQuestionsReducer;
