export const onListItemClickedB2CBalkonArtVorstellbalkon = () => {
  return {
    type: "Vorstellbalkon",
  };
};
export const onListItemClickedB2CBalkonArtHängebalkon = () => {
  return {
    type: "Hängebalkon",
  };
};
export const onListItemClickedB2CBalkonFarbeAnthrazitGrau = () => {
  return {
    type: "Anthrazit Grau",
  };
};
export const onListItemClickedB2CBalkonFarbeWeiß = () => {
  return {
    type: "Weiß",
  };
};
export const onListItemClickedB2CBalkonFarbeVerzinktOhneWeitereBehandlung =
  () => {
    return {
      type: "Verzinkt ohne weitere Behandlung",
    };
  };
export const onListItemClickedB2CBalkonFarbeSchwarz = () => {
  return {
    type: "Schwarz",
  };
};
export const onListItemClickedB2CBalkonFarbeNachWunsch = () => {
  return {
    type: "Farbe nach Wunsch",
  };
};

export const onListItemClickedB2CBalkonMaterialStahl = () => {
  return {
    type: "Stahl",
  };
};
export const onListItemClickedB2CBalkonMaterialEdelstahl = () => {
  return {
    type: "Edelstahl",
  };
};
export const onListItemClickedB2CBalkonMaterialSonstiges = () => {
  return {
    type: "Sonstiges Material",
  };
};
export const onListItemClickedB2CBalkonBodenWaschbetonplatten = () => {
  return {
    type: "WaschbetonplattenBodenBalkon",
  };
};
export const onListItemClickedB2CBalkonBodenAluminium = () => {
  return {
    type: "AluminiumBodenBalkon",
  };
};
export const onListItemClickedB2CBalkonBodenLaerchenholz = () => {
  return {
    type: "LaerchenholzBodenBalkon",
  };
};
export const onListItemClickedB2CBalkonBodenBangkirai = () => {
  return {
    type: "BangkiraiBodenBalkon",
  };
};
export const onListItemClickedB2CBalkonBodenBelagNachWunsch = () => {
  return {
    type: "BelagNachWunschBodenBalkon",
  };
};
export const onListItemClickedB2CBalkonGeländerAufbauMitSenkrechtenStaeben =
  () => {
    return {
      type: "MitSenkrechtenStaebenAufbauBalkon",
    };
  };
export const onListItemClickedB2CBalkonGeländerAufbauHorizontaleFuellung =
  () => {
    return {
      type: "HorizontaleFuellungAufbauBalkon",
    };
  };
export const onListItemClickedB2CBalkonGeländerAufbauKassettenfuellungMitSenkrechtenStaeben =
  () => {
    return {
      type: "KassettenfuellungMitSenkrechtenStaebenAufbauBalkon",
    };
  };
export const onListItemClickedB2CBalkonGeländerAufbauKassettenfuellungMitHorizontalenStaeben =
  () => {
    return {
      type: "KassettenfuellungMitHorizontalenStaebenAufbauBalkon",
    };
  };
export const onListItemClickedB2CBalkonGeländerAufbauGlasfuellung = () => {
  return {
    type: "GlasfuellungAufbauBalkon",
  };
};
export const onListItemClickedB2CBalkonGeländerAufbauMitSeilen = () => {
  return {
    type: "MitSeilenAufbauBalkon",
  };
};

export const onListItemClickedB2CBalkonSichtschutzEinseitig = () => {
  return {
    type: "Sichtschutz Einseitig",
  };
};
export const onListItemClickedB2CBalkonSichtschutzBeidseitig = () => {
  return {
    type: "Sichtschutz Beidseitig",
  };
};
export const onListItemClickedB2CBalkonSichtschutzNein = () => {
  return {
    type: "Kein Sichtschutz",
  };
};
export const onListItemClickedB2CBalkonSichtschutzArtGlas = () => {
  return {
    type: "Glas Sichtschutz",
  };
};
export const onListItemClickedB2CBalkonSichtschutzArtHartfaserplatten = () => {
  return {
    type: "Hartfaserplatten Sichtschutz",
  };
};
export const onListItemClickedB2CBalkonSichtschutzFarbeAnthrazit = () => {
  return {
    type: "Farbe Sichtschutz Anthrazit",
  };
};
export const onListItemClickedB2CBalkonSichtschutzFarbeWeiß = () => {
  return {
    type: "Farbe Sichtschutz  Weiß",
  };
};
export const onListItemClickedB2CBalkonSichtschutzFarbeGrau = () => {
  return {
    type: "Farbe Sichtschutz Grau",
  };
};
export const onListItemClickedB2CBalkonDachJa = () => {
  return {
    type: "Balkondach Ja",
  };
};
export const onListItemClickedB2CBalkonDachNein = () => {
  return {
    type: "Balkondach Nein",
  };
};

export const onListItemClickedB2CBalkonGelaenderHandlaufJa = () => {
  return {
    type: "Balkon Handlauf Ja",
  };
};
export const onListItemClickedB2CBalkonGelaenderHandlaufNein = () => {
  return {
    type: "Balkon Handlauf Nein",
  };
};

export const onListItemClickedB2CBalkonVerzinkt = () => {
  return {
    type: "Balkon Beschichtung Verzinkt",
  };
};
export const onListItemClickedB2CBalkonVerzinktMitNassbeschichtung = () => {
  return {
    type: "Verzinkt mit Nassbeschichtung (Duplexbeschichtung)",
  };
};

export const onListItemClickedB2CBalkonTreppeAnGartenJa = () => {
  return {
    type: "Balkon Treppe zum Garten Ja",
  };
};

export const onListItemClickedB2CBalkonTreppeAnGartenNein = () => {
  return {
    type: "Balkon Treppe zum Garten Nein",
  };
};

export const onListItemClickedB2CBalkon1Stockwerk = () => {
  return {
    type: "Balkon 1-Stockwerk",
  };
};

export const onListItemClickedB2CBalkon2Stockwerke = () => {
  return {
    type: "Balkon 2-Stockwerke",
  };
};

export const onListItemClickedB2CBalkon3Stockwerke = () => {
  return {
    type: "Balkon 3-Stockwerke",
  };
};

export const onListItemClickedB2CBalkon4Stockwerke = () => {
  return {
    type: "Balkon 4-Stockwerke",
  };
};

export const onListItemClickedB2CBalkon5Stockwerke = () => {
  return {
    type: "Balkon 5-Stockwerke",
  };
};

export const onListItemClickedB2CBalkonMehrAls5Stockwerke = () => {
  return {
    type: "Balkon Mehr-Als-5-Stockwerke",
  };
};
