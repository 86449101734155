function ueberdachungReducer(state = [], action) {
  switch (action.type) {
    case "Vordach":
      return {
        ...state,
        ArtUeberdachung: "Vordach",
      };
    case "Carport":
      return {
        ...state,
        ArtUeberdachung: "Carport",
      };
    case "Pergola":
      return {
        ...state,
        ArtUeberdachung: "Pergola",
      };
    case "Terassenüberdachung":
      return {
        ...state,
        ArtUeberdachung: "Terassenüberdachung",
      };
    case "Balkonüberdachung":
      return {
        ...state,
        ArtUeberdachung: "Balkonüberdachung",
      };
    case "Haustürüberdachung":
      return {
        ...state,
        ArtUeberdachung: "Haustürüberdachung",
      };
    default:
      return state;
  }
}
export default ueberdachungReducer;
