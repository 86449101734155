import React, { setState, useState } from "react";

import { Link } from "react-router-dom";

import B2bComponent from "./B2bComponent/B2bComponent";
import B2cComponentFunction from "./B2cComponent/B2cComponentFunction";
import WiComponent from "./WiComponent/WiComponent";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  onListItemClickedB2C,
  onListItemClickedB2B,
  onListItemClickedWI,
} from "../../actions/useCases/useCases";
import { render } from "react-dom";
import { Helmet } from "react-helmet";

export default function UseCasesFunction() {
  const useCase = useSelector((state) => state.useCases.useCase);
  const dispatch = useDispatch();

  // Deklariere eine neue State Variable, die wir "zustandComponente" nennen werden
  const [zustandComponente, setZustandComponente] = useState(0);

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <Helmet>
          <title>Anfrage - Was willst du realisieren?</title>
          <meta
            name="description"
            content="Hier kannst du auswählen, was dein Anliegen ist."
          />
        </Helmet>

        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Was willst du realisieren?
        </h1>

        <div className="row row-cols-3  pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"B2C"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2C());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Ich möchte ein Projekt realisieren und brauche Beratung.
              </p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="anfrage"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"B2B"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2B());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Ich weiß bereits was ich will und habe eine Zeichnung vorliegen.
              </p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="wartungen"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"WI"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedWI());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Ich will Wartungen oder Instandsetzungen durchführen lassen.
              </p>
            </div>
          </Link>
        </div>
      </div>
    );
  } else if (zustandComponente == 1) {
    switch (useCase) {
      case "B2C":
        return <B2cComponentFunction />;
      case "B2B":
        return <B2bComponent />;
      case "WI":
        return <WiComponent />;
      default:
        return null;
    }
  }
}
