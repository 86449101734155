import React, { useState, useMemo } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { furtherQuestionsCreators } from "../../actions/forms/furtherQuestionsCreators";
import LinearWithPercent75 from "../progress/number/progress75";
import { useNavigate } from "react-router-dom";
import Bilder from "./bilder";
import Baustelle from "./baustelle";
import Thumb from "./upload/thumb";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";

export default function Zeichnungen() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const navigate = useNavigate();
  const MAX_SIZE = 5242880;
  const MIN_SIZE = 0;

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
    setFieldValue,
  } = useDropzone({
    accept: {},
    /*accept: {
      "application/pdf": [".pdf"],
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "application/acad": [".dwg"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/msexcel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/mspowerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/vnd.oasis.opendocument.text": [".odt"],
      "text/rtf": [".rtf"],
    },*/

    onDrop: (filesZ) => furtherQuestionsCreators.zeichnungen(filesZ),
    minSize: { MIN_SIZE },
    maxSize: { MAX_SIZE },
  });

  const files2 = acceptedFiles.map((file, i) => (
    <Thumb className="pt-2 pb-2" key={i} file={file} />
  ));

  const checkIfFilesAreTooBig = acceptedFiles.map((file, i) => {
    let fileSize = file.size;
    let maxSize = 5242880;
    let valid = true;

    if (fileSize > maxSize) {
      valid = false;
    }
    return valid;
  });

  const bilderValidationSchema = Yup.object().shape({
    recaptcha: Yup.array().nullable(),
    /*.test(
        "is-correct-file",
        "VALIDATION_FIELD_FILE_BIG",
        checkIfFilesAreTooBig
      )*/
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent75 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Gibt es bereits Zeichnungen oder Skizzen?
        </h1>
        <Formik
          initialValues={{ files: [] }}
          validationSchema={bilderValidationSchema}
          onSubmit={(values) => {
            setZustandComponente(1);
            navigate("/beratung/projekt/baustelle");
          }}
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group pt-4 pb-4">
                <Dropzone
                  //accept="image/*, application/pdf "
                  minSize={MIN_SIZE}
                  maxSize={MAX_SIZE}
                  onDrop={(acceptedFiles, rejectedFiles) => {
                    // do nothing if no files
                    if (acceptedFiles.length === 0) {
                      return;
                    }
                    if (rejectedFiles.length === 0) {
                      return <p>rejected</p>;
                    }

                    // on drop we add to the existing files
                    setFieldValue("files", values.files.concat(acceptedFiles));
                  }}
                >
                  {({
                    isDragActive,
                    isDragReject,
                    acceptedFiles,
                    rejectedFiles,
                  }) => {
                    /*   const isFileTooLarge =
                      rejectedFiles.length > 0 &&
                      rejectedFiles[0].size > maxSize;*/
                    if (isDragActive) {
                      return "This file is authorized";
                    }

                    if (isDragReject) {
                      return "This file is not authorized";
                    }
                    if (rejectedFiles) {
                      return "This file is rejected";
                    }
                    /*   if (rejectedFiles) {
                      return "This file is rejected";
                    }*/

                    if (values.files.length === 0) {
                      return (
                        <section className="container">
                          <div
                            {...getRootProps({
                              style,
                            })}
                          >
                            <input {...getInputProps()} />
                            <p>
                              Hier kannst du Zeichnungen oder Skizzen hochladen.
                            </p>
                          </div>
                          <aside>
                            <ul>{files2}</ul>
                          </aside>
                        </section>
                      );
                    }

                    return values.files.map((file, i) => (
                      <Thumb key={i} file={file} />
                    ));
                  }}
                </Dropzone>
              </div>
              <button
                key={"Zurueck"}
                className="btn btn-secondary me-md-2 mx-2"
                type="button"
                onClick={() => {
                  setZustandComponente(-1);
                  navigate("/beratung/projekt/bilder");
                }}
              >
                ZURÜCK
              </button>
              <button key={"Weiter"} className="btn btn-success" type="submit">
                WEITER
              </button>
            </form>
          )}
        </Formik>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <Baustelle />;
  } else if (zustandComponente == -1) {
    return <Bilder />;
  }
}
