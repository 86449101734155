import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onListItemClickedB2CHandlaufDurchmesser34,
  onListItemClickedB2CHandlaufDurchmesser44,
  onListItemClickedB2CHandlaufDurchmesser48,
} from "../../../../../actions/useCases/b2c/handlauf/handlauf";
import Handlauf from "../Handlauf";
import UntereEndeHandlauf from "./UntereEndeHandlauf";
import FormHandlauf from "./FormHandlauf";
import ObereEndeHandlauf from "./ObereEndeHandlauf";
import LinearWithPercent15 from "../../../../progress/number/progress15";

export default function DurchmesserHandlauf() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent15 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Welchen Durchmesser soll das Rohr des Handlaufs haben?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/handlauf/obere-ende-handlauf"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CHandlaufDurchmesser34());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">34mm</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/handlauf/obere-ende-handlauf"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CHandlaufDurchmesser44());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">44mm</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/handlauf/obere-ende-handlauf"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CHandlaufDurchmesser48());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">48mm</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <ObereEndeHandlauf />;
  } else if (zustandComponente == -1) {
    return <FormHandlauf />;
  }
}
