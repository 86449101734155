import { bindActionCreators } from "redux";
import store from "../../store";

const updateBalkonMaßTiefe = (tiefeBalkon) => ({
  type: "CHANGE_BALKON_TIEFE",
  payload: tiefeBalkon,
});
const updateBalkonLaenge = (laengeBalkon) => ({
  type: "CHANGE_BALKON_LAENGE",
  payload: laengeBalkon,
});

export const balkonCreators = bindActionCreators(
  {
    updateBalkonMaßTiefe,
    updateBalkonLaenge,
  },
  store.dispatch
);
