import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onListItemClickedB2CTreppen1Stockwerk,
  onListItemClickedB2CTreppen2Stockwerke,
  onListItemClickedB2CTreppen3Stockwerke,
  onListItemClickedB2CTreppen4Stockwerke,
  onListItemClickedB2CTreppen5Stockwerke,
  onListItemClickedB2CTreppenMehrStockwerke,
} from "../../../../../actions/useCases/b2c/treppen/treppen";

import FurtherQuestions from "../../FurtherQuestions/FurtherQuestions";
import MaterialTreppen from "./MaterialTreppen";
import LinearWithPercent45 from "../../../../progress/number/progress45";
import Beschreibung from "../../../../contact/beschreibung";

export default function StockwerkeTreppen() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent45 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Über wieviele Stockwerke soll die Treppe gehen?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/projekt"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/beschreibung");
                dispatch(onListItemClickedB2CTreppen1Stockwerk());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">1 Stockwerk</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/projekt"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/beschreibung");
                dispatch(onListItemClickedB2CTreppen2Stockwerke());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">2 Stockwerke</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/projekt"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/beschreibung");
                dispatch(onListItemClickedB2CTreppen3Stockwerke());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">3 Stockwerke</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/projekt"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/beschreibung");
                dispatch(onListItemClickedB2CTreppen4Stockwerke());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">4 Stockwerke</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/projekt"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/beschreibung");
                dispatch(onListItemClickedB2CTreppen5Stockwerke());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">5 Stockwerke</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/projekt"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);

                navigate("/beratung/projekt/beschreibung");
                dispatch(onListItemClickedB2CTreppenMehrStockwerke());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Mehr Stockwerke</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <Beschreibung />;
  } else if (zustandComponente == -1) {
    return <MaterialTreppen />;
  }
}
