export const onListItemClickedQuestionsVorangebotJa = () => {
  return {
    type: "Vorangebot-Ja",
  };
};
export const onListItemClickedQuestionsVorangebotNein = () => {
  return {
    type: "Vorangebot-Nein",
  };
};

export const onListItemClickedQuestionsUmsetzungSofort = () => {
  return {
    type: "Umsetzung-Sofort",
  };
};
export const onListItemClickedQuestionsUmsetzung1bis3Monate = () => {
  return {
    type: "Umsetzung-1bis3Monate",
  };
};
export const onListItemClickedQuestionsUmsetzung3bis6Monate = () => {
  return {
    type: "Umsetzung-3bis6Monate",
  };
};
export const onListItemClickedQuestionsUmsetzung6bis12Monate = () => {
  return {
    type: "Umsetzung-6bis12Monate",
  };
};
export const onListItemClickedQuestionsUmsetzungMehrAls12Monate = () => {
  return {
    type: "Umsetzung-MehrAls12Monate",
  };
};

export const onListItemClickedQuestionsKaufen = () => {
  return {
    type: "Kaufen",
  };
};
export const onListItemClickedQuestionsFinanzieren = () => {
  return {
    type: "Finanzieren",
  };
};
