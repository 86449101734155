export const onListItemClickedB2CTreppenEinlaeufig = () => {
  return {
    type: "Treppe-Einläufig",
  };
};

export const onListItemClickedB2CTreppenZweilaeufig = () => {
  return {
    type: "Treppe-Zweiläufig",
  };
};

export const onListItemClickedB2CTreppenDreilaeufig = () => {
  return {
    type: "Treppe-Dreiäufig",
  };
};

export const onListItemClickedB2CTreppenGewendelt = () => {
  return {
    type: "Treppe-Gewendelt",
  };
};
//Stufenausführung
export const onListItemClickedB2CTreppenMaterialStufenBuchenholz = () => {
  return {
    type: "Treppe-Stufen-Buchenholz",
  };
};
export const onListItemClickedB2CTreppenMaterialStufenEichenholz = () => {
  return {
    type: "Treppe-Stufen-Eichenholz",
  };
};
export const onListItemClickedB2CTreppenMaterialMaterialStufenAhornholz =
  () => {
    return {
      type: "Treppe-Stufen-Ahornholz",
    };
  };
export const onListItemClickedB2CTreppenMaterialStufenGranit = () => {
  return {
    type: "Treppe-Stufen-Granit",
  };
};
export const onListItemClickedB2CTreppenMaterialStufenGranitRosaBeta = () => {
  return {
    type: "Treppe-Stufen-Granit-Rosa-Beta",
  };
};
export const onListItemClickedB2CTreppenMaterialStufenImperial = () => {
  return {
    type: "Treppe-Stufen-Imperial",
  };
};
export const onListItemClickedB2CTreppenMaterialStufenWannenstufen = () => {
  return {
    type: "Treppe-Stufen-Wannenstufen",
  };
};
export const onListItemClickedB2CTreppenMaterialStufenFeinmaschigeGitterroststufen =
  () => {
    return {
      type: "Treppe-Stufen-Feinmaschige-Gitterroststufen",
    };
  };
export const onListItemClickedB2CTreppenMaterialStufenNormaleGitterroststufen =
  () => {
    return {
      type: "Treppe-Stufen-Normale-Gitterroststufen",
    };
  };
export const onListItemClickedB2CTreppenMaterialStufenNachWunsch = () => {
  return {
    type: "Treppe-Stufen-Nach-Wunsch",
  };
};
//Treppengeländer
export const onListItemClickedB2CTreppenAufbauMitSenkrechtenStaeben = () => {
  return {
    type: "Treppe-Aufbau-Mit-Senkrechten-Staeben",
  };
};

export const onListItemClickedB2CTreppenAufbauHorizontaleFuellung = () => {
  return {
    type: "Treppe-Aufbau-Horizontale-Fuellung",
  };
};

export const onListItemClickedB2CTreppenAufbauKassetenfuellungMitSenkrechtenStaeben =
  () => {
    return {
      type: "Treppe-Aufbau-Kassetenfuellung-Mit-Senkrechten-Staeben",
    };
  };

export const onListItemClickedB2CTreppenAufbauKassetenfuellungMitHorizontalenStaeben =
  () => {
    return {
      type: "Treppe-Aufbau-Kassetenfuellung-Mit-Horizontalen-Staeben",
    };
  };

export const onListItemClickedB2CTreppenAufbauGlasfuellung = () => {
  return {
    type: "Treppe-Aufbau-Glasfuellung",
  };
};

export const onListItemClickedB2CTreppenAufbauMitSeilen = () => {
  return {
    type: "Treppe-Aufbau-Mit-Seilen",
  };
};

//Ende von Aufbau Treppengeländer

export const onListItemClickedB2CTreppenMaterialBodenMarmor = () => {
  return {
    type: "Treppe-Material-Boden-Marmor",
  };
};
export const onListItemClickedB2CTreppenMaterialBodenHolz = () => {
  return {
    type: "Treppe-Material-Boden-Holz",
  };
};

export const onListItemClickedB2CTreppenMaterialBodenEdelstahl = () => {
  return {
    type: "Treppe-Material-Boden-Edelstahl",
  };
};

export const onListItemClickedB2CTreppenMaterialBodenMetall = () => {
  return {
    type: "Treppe-Material-Boden-Metall",
  };
};

export const onListItemClickedB2CTreppenInnen = () => {
  return {
    type: "Treppe-Innen",
  };
};

export const onListItemClickedB2CTreppenAussen = () => {
  return {
    type: "Treppe-Aussen",
  };
};

export const onListItemClickedB2CTreppenMaterialStahl = () => {
  return {
    type: "Treppe-Material-Stahl",
  };
};

export const onListItemClickedB2CTreppenMaterialEdelstahl = () => {
  return {
    type: "Treppe-Material-Edelstahl",
  };
};

export const onListItemClickedB2CTreppenMaterialVerzinkterStahl = () => {
  return {
    type: "Treppe-Material-VerzinkterStahl",
  };
};

export const onListItemClickedB2CTreppenBeschichtungNasslackierung = () => {
  return {
    type: "Treppe-Beschichtung-Nasslackierung",
  };
};

export const onListItemClickedB2CTreppenBeschichtungPulverbeschichtung = () => {
  return {
    type: "Treppe-Beschichtung-Pulverbeschichtung",
  };
};

export const onListItemClickedB2CTreppen1Stockwerk = () => {
  return {
    type: "Treppe-1-Stockwerk",
  };
};

export const onListItemClickedB2CTreppen2Stockwerke = () => {
  return {
    type: "Treppe-2-Stockwerke",
  };
};

export const onListItemClickedB2CTreppen3Stockwerke = () => {
  return {
    type: "Treppe-3-Stockwerke",
  };
};
export const onListItemClickedB2CTreppen4Stockwerke = () => {
  return {
    type: "Treppe-4-Stockwerke",
  };
};

export const onListItemClickedB2CTreppen5Stockwerke = () => {
  return {
    type: "Treppe-5-Stockwerke",
  };
};

export const onListItemClickedB2CTreppenMehrStockwerke = () => {
  return {
    type: "Treppe-Mehr-Stockwerke",
  };
};
