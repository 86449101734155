export const onListItemClickedWIMaengelJa = () => {
  return {
    type: "WI-Maengel-Ja",
  };
};
export const onListItemClickedWIMaengelNein = () => {
  return {
    type: "WI-Maengel-Nein",
  };
};
export const onListItemClickedWIWartungCaseJa = () => {
  return {
    type: "WI-Wartung-Case-Ja",
  };
};
export const onListItemClickedWIWartungCaseNein = () => {
  return {
    type: "WI-Wartung-Case-Nein",
  };
};
export const onListItemClickedWIWartungEinmalig = () => {
  return {
    type: "WI-Wartung-Einmalig",
  };
};

export const onListItemClickedWIWartungRegelmaessig = () => {
  return {
    type: "WI-Wartung-Regelmaessig",
  };
};

export const onListItemClickedWIWartungArtKorrigierend = () => {
  return {
    type: "WI-Wartung-Art-Korrigierend",
  };
};

export const onListItemClickedWIWartungArtVorbeugend = () => {
  return {
    type: "WI-Wartung-Art-Vorbeugend",
  };
};

export const onListItemClickedWIWartungArtVorausschauend = () => {
  return {
    type: "WI-Wartung-Art-Vorausschauend",
  };
};

export const onListItemClickedWIWartungPlanJaVorhanden = () => {
  return {
    type: "WI-Wartung-Plan-Ja-Vorhanden",
  };
};

export const onListItemClickedWIWartungPlanNeinMussErstelltWerden = () => {
  return {
    type: "WI-Wartung-Plan-Nein-Muss-Erstellt-Werden",
  };
};

export const onListItemClickedWIWartungInspektionJa = () => {
  return {
    type: "WI-Wartung-Inspektion-Ja",
  };
};
export const onListItemClickedWIWartungInspektionNein = () => {
  return {
    type: "WI-Wartung-Inspektion-Nein",
  };
};
export const onListItemClickedWIWartungEmpfehlungenInstandsetzungsarbeitenJa =
  () => {
    return {
      type: "WI-Wartung-Empfehlungen-Instandsetzungsarbeiten-Ja",
    };
  };
export const onListItemClickedWIWartungEmpfehlungenInstandsetzungsarbeitenNein =
  () => {
    return {
      type: "WI-Wartung-Empfehlungen-Instandsetzungsarbeiten-Nein",
    };
  };
export const onListItemClickedWIWartungInstandsetzungUndOderReparaturInstandsetzungNotwendig =
  () => {
    return {
      type: "WI-Wartung-Instandsetzung-Notwendig",
    };
  };
export const onListItemClickedWIWartungInstandsetzungUndOderReparaturReparaturNotwendig =
  () => {
    return {
      type: "WI-Wartung-Reparatur-Notwendig",
    };
  };
export const onListItemClickedWIWartungInstandsetzungUndOderReparaturBeidesNotwendig =
  () => {
    return {
      type: "WI-Wartung-Beides-Notwendig",
    };
  };
export const onListItemClickedWIWartungDringlichkeitJa = () => {
  return {
    type: "WI-Wartung-Dringlichkeit-Ja",
  };
};
export const onListItemClickedWIWartungDringlichkeitNein = () => {
  return {
    type: "WI-Wartung-Dringlichkeit-Nein",
  };
};

export const onListItemClickedWIWartungBeginnSofort = () => {
  return {
    type: "WI-Wartung-Beginn-Sofort",
  };
};
export const onListItemClickedWIWartungBeginn1bis2Wochen = () => {
  return {
    type: "WI-Wartung-Beginn-1bis2Wochen",
  };
};
export const onListItemClickedWIWartungBeginn2bis4Wochen = () => {
  return {
    type: "WI-Wartung-Beginn-2bis4Wochen",
  };
};
export const onListItemClickedWIWartungBeginn1bis3Monaten = () => {
  return {
    type: "WI-Wartung-Beginn-1bis3Monaten",
  };
};
export const onListItemClickedWIWartungBeginn3bis6Monaten = () => {
  return {
    type: "WI-Wartung-Beginn-3bis6Monaten",
  };
};
export const onListItemClickedWIWartungBeginnInMehrAls6Monaten = () => {
  return {
    type: "WI-Wartung-Beginn-InMehrAls6Monaten",
  };
};
