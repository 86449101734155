export const onListItemClickedB2CBalkone = () => {
  return {
    type: "Balkone",
  };
};
export const onListItemClickedB2CFenster = () => {
  return {
    type: "Fenster",
  };
};
export const onListItemClickedB2CGelaender = () => {
  return {
    type: "Gelaender",
  };
};
export const onListItemClickedB2CHandlauf = () => {
  return {
    type: "Handlauf",
  };
};
export const onListItemClickedB2CTore = () => {
  return {
    type: "Tore",
  };
};
export const onListItemClickedB2CTueren = () => {
  return {
    type: "Tueren",
  };
};
export const onListItemClickedB2CUeberdachungen = () => {
  return {
    type: "Ueberdachungen",
  };
};
export const onListItemClickedB2CTreppe = () => {
  return {
    type: "Treppe",
  };
};
export const onListItemClickedB2CAndereLeistung = () => {
  return {
    type: "B2CAndereLeistung",
  };
};
export const onListItemClickedB2CWI = () => {
  return {
    type: "B2CWI",
  };
};
