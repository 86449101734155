import { bindActionCreators } from "redux";
import store from "../../store";

const updateID = (currentId) => ({
  type: "CURRENTID",
  payload: currentId,
});
const setID = (setCurrentId) => ({
  type: "SETCURRENTID",
  payload: setCurrentId,
});

export const boundToIdCreators = bindActionCreators(
  {
    currentId: updateID,
    setCurrentId: setID,
  },
  store.dispatch
);
