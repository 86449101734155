import React from "react";
import { Paper } from "@material-ui/core";
import useStyles from "./styles";
import B2cComponentFunction from "../Components/UseCases/B2cComponent/B2cComponentFunction";

const Form = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <B2cComponentFunction />
    </Paper>
  );
};

export default Form;
