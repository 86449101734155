function handlaufReducer(state = [], action) {
  switch (action.type) {
    case "Handlauf_auf_Glas":
      return {
        ...state,
        Art: "Auf Glas",
      };
    case "Handlauf_an_der_Wand":
      return {
        ...state,
        Art: "An der Wand",
      };
    case "Handlauf_auf_Stuetzen":
      return {
        ...state,
        Art: "Auf Stützen",
      };

    case "Handlauf_Linke_Wandseite":
      return {
        ...state,
        Wandseite: "Links",
      };
    case "Handlauf_Rechte_Wandseite":
      return {
        ...state,
        Wandseite: "Rechts",
      };

    case "Handlauf_Form_Rund":
      return {
        ...state,
        Form: "Rund",
      };
    case "Handlauf_Form_Eckig":
      return {
        ...state,
        Form: "Eckig",
      };
    case "Handlauf_Form_Oval":
      return {
        ...state,
        Form: "Oval",
      };
    case "Handlauf_Durchmesser_337mm":
      return {
        ...state,
        Durchmesser: "337mm",
      };
    case "Handlauf_Durchmesser_434mm":
      return {
        ...state,
        Durchmesser: "434mm",
      };

    case "Handlauf_Ende_Oben_Gerade":
      return {
        ...state,
        HandlaufendeOben: "Gerade",
      };
    case "Handlauf_Ende_Oben_90_Grad_Bogen_nach_Unten":
      return {
        ...state,
        HandlaufendeOben: "90 Grad Bogen nach Unten",
      };
    case "Handlauf_Ende_Oben_90_Grad_Bogen_zur_Wand":
      return {
        ...state,
        HandlaufendeOben: "90 Grad Bogen zur Wand",
      };
    case "Handlauf_Ende_Unten_Gerade":
      return {
        ...state,
        HandlaufendeUnten: "Gerade",
      };
    case "Handlauf_Ende_Unten_90_Grad_Bogen_nach_Unten":
      return {
        ...state,
        HandlaufendeUnten: "90 Grad Bogen nach Unten",
      };
    case "Handlauf_Ende_Unten_90_Grad_Bogen_zur_Wand":
      return {
        ...state,
        HandlaufendeUnten: "90 Grad Bogen zur Wand",
      };

    case "Handlauf_Endkappe_Oben_Flach":
      return {
        ...state,
        EndkappeOben: "Flach",
      };

    case "Handlauf_Endkappe_Oben_Gewoelbt":
      return {
        ...state,
        EndkappeOben: "Gewölbt",
      };

    case "Handlauf_Endkappe_Oben_Halbkugel":
      return {
        ...state,
        EndkappeOben: "Halbkugel",
      };

    case "Handlauf_Endkappe_Unten_Flach":
      return {
        ...state,
        EndkappeUnten: "Flach",
      };

    case "Handlauf_Endkappe_Unten_Gewoelbt":
      return {
        ...state,
        EndkappeUnten: "Gewölbt",
      };

    case "Handlauf_Endkappe_Unten_Halbkugel":
      return {
        ...state,
        EndkappeUnten: "Halbkugel",
      };

    case "Handlauf_Ohne_Abdeckrosseten":
      return {
        ...state,
        Abdeckrosseten: "Ohne",
      };

    case "Handlauf_Mit_Abdeckrosseten":
      return {
        ...state,
        Abdeckrosseten: "Mit",
      };

    case "Handlauf_Material_V2A":
      return {
        ...state,
        Material: "V2A",
      };
    case "Handlauf_Material_V4A":
      return {
        ...state,
        Material: "V4A",
      };
    case "Handlauf_Montage_Ja":
      return {
        ...state,
        Montage: "Ja",
      };
    case "Handlauf_Montage_Nein":
      return {
        ...state,
        Montage: "Nein",
      };
    case "Handlauf_Beleuchtung_Nein":
      return {
        ...state,
        Beleuchtung: "Nein",
      };
    case "Handlauf_Beleuchtung_Mit_LED":
      return {
        ...state,
        Beleuchtung: "Mit LED",
      };

    case "CHANGE_HANDLAUF_LAENGE":
      return {
        ...state,
        Laenge: action.payload,
      };

    default:
      return state;
  }
}
export default handlaufReducer;
