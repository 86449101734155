import React, { useEffect, useState } from "react";

const Login = () => {
  return (
    <div className="px-4 pb-4">
      <h1>login</h1>{" "}
    </div>
  );
};
export default Login;
