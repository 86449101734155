const idReducer = (ids = [], action) => {
  switch (action.type) {
    case "CURRENTID":
      return {
        ...ids,
        currentId: action.payload,
      };
    case "SETCURRENTID":
      return {
        ...ids,
        setCurrentId: action.payload,
      };

    default:
      return ids;
  }
};
export default idReducer;
