import React, { useState } from "react";

export default function Tore() {
  return (
    <div>
      <h1>Tore</h1>
      <p className="text_wrapper">Welche Art von Toren willst du?</p>
    </div>
  );
}
