import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handlaufCreators } from "../../../../../actions/forms/handlaufCreators";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../../../../materialui/textfield";

import MaterialHandlauf from "./MaterialHandlauf";
import AbdeckrossetenHandlauf from "./AbdeckrossetenHandlauf";
import LinearWithPercent35 from "../../../../progress/number/progress35";

export default function LaengeHandlauf() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const laengeHandlaufValidationSchema = Yup.object().shape({
    laengeHandlauf: Yup.string()
      .required("Länge muss ausgefüllt werden.")
      .min(1, "Die Länge muss aus mindestens einer Zahl bestehen."),
  });

  if (zustandComponente == 0) {
    return (
      <Formik
        initialValues={{
          laengeHandlauf: "",
        }}
        validationSchema={laengeHandlaufValidationSchema}
        onSubmit={(values, actions) => {
          //furtherQuestionsCreators.beschreibung(values.beschreibung);
          handlaufCreators.updateHandlaufLaenge(values.laengeHandlauf);
          actions.setSubmitting(false);
          actions.resetForm({
            laengeHandlauf: "",
          });
          setZustandComponente(1);
          navigate("beratung/handlauf/material-handlauf");
        }}
      >
        {(props) => (
          <div className="px-4 pb-4">
            <LinearWithPercent35 />
            <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
              Wie lang soll der Handlauf werden?
            </h1>
            <Form
              sx={{
                "& > :not(style)": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
              className="form-group pt-4 px-4 pb-4"
              onSubmit={props.handleSubmit}
            >
              <div className="pb-4">
                <TextfieldWrapper
                  type="text"
                  placeholder="Länge in Meter"
                  name="laengeHandlauf"
                  className="textfield form-control pb-4"
                  required
                />
              </div>
              <button
                key={"Zurueck"}
                type="button"
                className="btn btn-secondary me-md-2 mx-2"
                onClick={() => {
                  setZustandComponente(-1);
                  navigate(-1);
                }}
              >
                ZURÜCK
              </button>

              <button key={"Weiter"} className="btn btn-success" type="submit">
                WEITER
              </button>
            </Form>
          </div>
        )}
      </Formik>
    );
  } else if (zustandComponente == 1) {
    return <MaterialHandlauf />;
  } else if (zustandComponente == -1) {
    return <AbdeckrossetenHandlauf />;
  }
}
