import * as api from "../api";
import { FETCH_ALL, CREATE, UPDATE, DELETE } from "../constants/actionTypes";

//Action Creators

export const getAnfragen = () => async (dispatch) => {
  try {
    const { data } = await api.fetchAnfragen();

    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const createAnfrage = (anfrage) => async (dispatch) => {
  try {
    const { data } = await api.createAnfrage(anfrage);

    dispatch({ type: CREATE, payload: data });
  } catch (error) {
    //console.log(error.message);
  }
};

export const updateAnfrage = (id, anfrage) => async (dispatch) => {
  try {
    const { data } = await api.updateAnfrage(id, anfrage);

    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteAnfrage = (id) => async (dispatch) => {
  try {
    await api.deleteAnfrage(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error.message);
  }
};

export const postAnfrage = (anfrage) => async (dispatch) => {
  try {
    const { data } = await api.postAnfrage(anfrage);

    dispatch({ type: CREATE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};
