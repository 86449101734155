import axios from "axios";

import { oAuth } from "../middleware/oAuth";

const tokenOptions = {
  method: "POST",
  url: "https://dev-qk5jk9ep.us.auth0.com/oauth/token",
  headers: { "content-type": "application/json" },
  body: '{"client_id":"fZfUTbFTz4xhKLXWdEBlGX1PndZSkSPF","client_secret":"qmhc7oYMBx43UOSVjgzT2y4wcPggVIzRv_fXe_Kifv-Mm4u5RePCcc28NYVGtTqM","audience":"https://api.metallbau-stahlbau-stuttgart.de","grant_type":"client_credentials"}',
};

const url =
  process.env.REACT_APP_PORT_FOR_API_SERVER ||
  "https://ms-stuttgart-api.herokuapp.com/anfragen";
/*const accessToken =
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IndITU5icGlzYkhxTVNOU0ZtR2NsNiJ9.eyJpc3MiOiJodHRwczovL2Rldi1xazVqazllcC51cy5hdXRoMC5jb20vIiwic3ViIjoiZlpmVVRiRlR6NHhoS0xYV2RFQmxHWDFQbmRaU2tTUEZAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vYXBpLm1ldGFsbGJhdS1zdGFobGJhdS1zdHV0dGdhcnQuZGUiLCJpYXQiOjE2NTM5NTk4NzUsImV4cCI6MTY1NDA0NjI3NSwiYXpwIjoiZlpmVVRiRlR6NHhoS0xYV2RFQmxHWDFQbmRaU2tTUEYiLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.EjtU-QaFfFlpMt6x9A7Y_DTnvUo7kuCIuD29RSrpaT9vqDWk8ZbIUuV59scupUR7Mou8zsLsWxX2ls1emDpd7QLE9DDmzJ9gNrDvQur4DmWLcnNw2dEXSgF3av0gxuTBYjVLESIAkBCs2_cALu0aw5LAQ5ucSKhtbNnUCv1WHX_-tGqmH0FDpbdyTDY2a20qlvJHqHG7f03yUZ0hYMhWHIe-l73h5C7PwDwW3u6LbOCzNLFVJsp2TlmKqCQsPMmsif-HKQFzf2sM6ktKLaph6x8kd1kJ1fqkHPwu6pPh14ylOBBPQ2ormttBMm5UYCKNo7ZPAb7xyVsac1Ka-AzEVA";

*/
const accessToken = "";

export const fetchAnfragen = () => axios.get(url);
export const createAnfrage = (newAnfrage) =>
  axios.post(url, newAnfrage, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
export const updateAnfrage = (id, updatedAnfrage) =>
  axios.patch(`${url}/${id}`, updatedAnfrage);
export const deleteAnfrage = (id) => axios.delete(`${url}/${id}`);

export const postAnfrage = (token, newAnfrage) =>
  axios.post(url, newAnfrage, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
