function balkonReducer(state = [], action) {
  switch (action.type) {
    case "Vorstellbalkon":
      return {
        ...state,
        Art: action.type,
      };
    case "Hängebalkon":
      return {
        ...state,
        Art: action.type,
      };
    case "CHANGE_BALKON_TIEFE":
      return {
        ...state,
        TiefeInMeter: action.payload,
      };
    case "CHANGE_BALKON_LAENGE":
      return {
        ...state,
        LaengeInMeter: action.payload,
      };
    case "Stahl":
      return {
        ...state,
        Material: action.type,
      };
    case "Edelstahl":
      return {
        ...state,
        Material: action.type,
      };
    case "Sonstiges Material":
      return {
        ...state,
        Material: action.type,
      };

    case "Waschbetonplatten":
      return {
        ...state,
        Boden: action.type,
      };
    case "Aluminium":
      return {
        ...state,
        Boden: action.type,
      };
    case "Laerchenholz":
      return {
        ...state,
        Boden: action.type,
      };
    case "Bangkirai":
      return {
        ...state,
        Boden: action.type,
      };
    case "Belag nach Wunsch":
      return {
        ...state,
        Boden: action.type,
      };

    case "Rundstabgeländer":
      return {
        ...state,
        Gelaender: action.type,
      };
    case "Flachstabgeländer":
      return {
        ...state,
        Gelaender: action.type,
      };
    case "Geschlossenes Geländer":
      return {
        ...state,
        Gelaender: action.type,
      };

    case "Sichtschutz Einseitig":
      return {
        ...state,
        Sichtschutz: "Einseitig",
      };
    case "Sichtschutz Beidseitig":
      return {
        ...state,
        Sichtschutz: "Beidseitig",
      };
    case "Kein Sichtschutz":
      return {
        ...state,
        Sichtschutz: "Keiner",
      };

    case "Farbe Sichtschutz Anthrazit":
      return {
        ...state,
        SichtschutzFarbe: "Anthrazit",
      };

    case "Farbe Sichtschutz  Weiß":
      return {
        ...state,
        SichtschutzFarbe: "Weiß",
      };

    case "Farbe Sichtschutz Grau":
      return {
        ...state,
        SichtschutzFarbe: "Grau",
      };

    case "Balkondach Ja":
      return {
        ...state,
        Balkondach: "Ja",
      };
    case "Balkondach Nein":
      return {
        ...state,
        Balkondach: "Nein",
      };

    case "Balkon Handlauf Ja":
      return {
        ...state,
        Handlauf: "Ja",
      };
    case "Balkon Handlauf Nein":
      return {
        ...state,
        Handlauf: "Nein",
      };
    case "Balkon Beschichtung Verzinkt":
      return {
        ...state,
        Beschichtung: "Verzinkt",
      };
    case "Balkon Beschichtung Pulverbeschichtet":
      return {
        ...state,
        Beschichtung: "Pulverbeschichtet",
      };
    case "Balkon Treppe zum Garten Ja":
      return {
        ...state,
        TreppeZumGarten: "Ja",
      };
    case "Balkon Treppe zum Garten Nein":
      return {
        ...state,
        TreppeZumGarten: "Nein",
      };
    case "Balkon 1-Stockwerk":
      return {
        ...state,
        AnzahlStockwerke: "1 Stockwerk",
      };
    case "Balkon 2-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "2 Stockwerke",
      };
    case "Balkon 3-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "3 Stockwerke",
      };
    case "Balkon 4-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "4 Stockwerke",
      };
    case "Balkon 5-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "5 Stockwerke",
      };
    case "Balkon Mehr-Als-5-Stockwerke":
      return {
        ...state,
        AnzahlStockwerke: "Mehr als 5 Stockwerke",
      };
    default:
      return state;
  }
}
export default balkonReducer;
