import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  onListItemClickedQuestionsUmsetzungSofort,
  onListItemClickedQuestionsUmsetzung1bis3Monate,
  onListItemClickedQuestionsUmsetzung3bis6Monate,
  onListItemClickedQuestionsUmsetzung6bis12Monate,
  onListItemClickedQuestionsUmsetzungMehrAls12Monate,
  onListItemClickedQuestionsFinanzieren,
  onListItemClickedQuestionsKaufen,
  onListItemClickedQuestionsVorangebotJa,
  onListItemClickedQuestionsVorangebotNein,
} from "../../../../actions/furtherQuestions";
import { furtherQuestionsCreators } from "../../../../actions/forms/furtherQuestionsCreators";
import { useNavigate } from "react-router-dom";
import Stockwerke from "../Services/Balkone/Stockwerke";
import StockwerkeTreppen from "../Services/Treppen/StockwerkeTreppen";
import LaengeGelaender from "../Services/Gelaender/LaengeGelaender";
import BeleuchtungHandlauf from "../Services/Handlauf/BeleuchtungHandlauf";
import { createAnfrage, updateAnfrage } from "../../../..//actions/anfrage.js";
import { boundToIdCreators } from "../../../../actions/forms/idCreators";
import SignUpContainer from "../../../user/signupContainer";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "./style.css";
import { Radio, RadioGroup } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import LinearWithValueLabel from "../../../progress/progressticker";
import LinearWithValueBaustelle from "../../../progress/progressbaustelle";
import LinearWithValueBeschreibung from "../../../progress/progressbeschreibung";
import LinearWithValueBilder from "../../../progress/progressbilder";
import LinearWithValueKaufenFinanzieren from "../../../progress/progresskaufenfinanzieren";
import LinearWithValueUmsetzung from "../../../progress/progressumsetzung";
import LinearWithValueZeichnungen from "../../../progress/progresszeichnungen";
import LinearWithValueVorangebot from "../../../progress/progressvorangebot";
import Termin from "../../../termin/termin";
import { useForm } from "react-hook-form";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useFormik } from "formik";
import TextfieldWrapper from "../../../materialui/textfield";
import Baustelle from "../../../contact/baustelle";
import Beschreibung from "../../../contact/beschreibung";

const FurtherQuestions = () => {
  let currentId = useSelector((state) => state.id.currentId);
  let setCurrentId = useSelector((state) => state.id.setCurrentId);

  const dispatch = useDispatch();
  const bilderBaustelleInput = React.createRef();
  const zeichnungenSkizzeInput = React.createRef();
  const navigate = useNavigate();
  const b2cLeistung = useSelector((state) => state.b2c.leistung);
  const anfrageState = useSelector((state) => state.anfrage);
  const idState = useSelector((state) => state.id);

  const [anfrageData, setAnfrageData] = useState({
    //ID
    currentId: idState.currentId,
    setCurrendId: idState.setCurrentId,
    //Leistung
    leistung: anfrageState.leistung,
    beschreibungLeistung: anfrageState.beschreibungLeistung,
    //Balkon
    artBalkon: anfrageState.artBalkon,
    materialBalkon: anfrageState.materialBalkon,
    tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
    laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
    bodenBalkon: anfrageState.bodenBalkon,
    gelaenderBalkon: anfrageState.gelaenderBalkon,
    sichtschutzBalkon: anfrageState.sichtschutzBalkon,
    sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
    dachBalkon: anfrageState.dachBalkon,
    handlaufBalkon: anfrageState.handlaufBalkon,
    beschichtungBalkon: anfrageState.beschichtungBalkon,
    treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
    anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
    //Treppe
    formTreppe: anfrageState.formTreppe,
    gelaenderTreppe: anfrageState.gelaenderTreppe,
    bodenTreppe: anfrageState.bodenTreppe,
    ortTreppe: anfrageState.ortTreppe,
    materialTreppe: anfrageState.materialTreppe,
    anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
    //Geländer
    artGelaender: anfrageState.artGelaender,
    materialGelaender: anfrageState.materialGelaender,
    montageGelaender: anfrageState.montageGelaender,
    //Handlauf
    laengeGelaender: anfrageState.laengeGelaender,
    artHandlauf: anfrageState.artHandlauf,
    wandseiteHandlauf: anfrageState.wandseiteHandlauf,
    formHandlauf: anfrageState.formHandlauf,
    durchmesserHandlauf: anfrageState.durchmesserHandlauf,
    handlaufendeOben: anfrageState.handlaufendeOben,
    handlaufendeUnten: anfrageState.handlaufendeUnten,
    endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
    endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
    abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
    materialHandlauf: anfrageState.materialHandlauf,
    montageHandlauf: anfrageState.montageHandlauf,
    beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
    laengeHandlauf: anfrageState.laengeHandlauf,
    //Kundeninformationen
    beschreibung: anfrageState.beschreibung,
    vorangebot: anfrageState.vorangebot,
    umsetzung: anfrageState.umsetzung,
    kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
    bilderBaustelle: anfrageState.bilderBaustelle,
    zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
    postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
    ortBaustelle: anfrageState.ortBaustelle,
    vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
    nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
    unternehmenAnsprechpartner: anfrageState.unternehmenAnsprechpartner,
    strasseHausnummerAnsprechpartner:
      anfrageState.strasseHausnummerAnsprechpartner,
    postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
    ortAnsprechpartner: anfrageState.ortAnsprechpartner,
    telefonnummer: anfrageState.telefonnummer,
  });

  const anfrageS = useSelector((state) =>
    currentId
      ? state.anfrage.find((message) => message._id === currentId)
      : null
  );

  useEffect(() => {
    if (anfrageS) setAnfrageData(anfrageS);
  }, [anfrageS]);

  const clear = () => {
    currentId = 0;
    setCurrentId = 0;
    boundToIdCreators.setCurrentId(currentId);
    boundToIdCreators.setCurrentId(setCurrentId);
    setAnfrageData({
      leistung: "",
      beschreibungLeistung: "",
      artBalkon: "",
      materialBalkon: "",
      tiefeInMeterBalkon: "",
      laengeInMeterBalkon: "",
      bodenBalkon: "",
      gelaenderBalkon: "",
      sichtschutzBalkon: "",
      sichtschutzFarbeBalkon: "",
      dachBalkon: "",
      handlaufBalkon: "",
      beschichtungBalkon: "",
      treppeZumGartenBalkon: "",
      anzahlStockwerkeBalkon: "",
      formTreppe: "",
      gelaenderTreppe: "",
      bodenTreppe: "",
      ortTreppe: "",
      materialTreppe: "",
      anzahlStockwerkeTreppe: "",
      artGelaender: "",
      materialGelaender: "",
      montageGelaender: "",
      laengeGelaender: "",
      artHandlauf: "",
      wandseiteHandlauf: "",
      formHandlauf: "",
      durchmesserHandlauf: "",
      handlaufendeOben: "",
      handlaufendeUnten: "",
      endkappeObenHandlauf: "",
      endkappeUntenHandlauf: "",
      abdeckrossetenHandlauf: "",
      materialHandlauf: "",
      montageHandlauf: "",
      beleuchtungHandlauf: "",
      laengeHandlauf: "",
      beschreibung: "",
      vorangebot: "",
      umsetzung: "",
      kaufenOderFinanzieren: "",
      bilderBaustelle: "",
      zeichnungenBaustelle: "",
      postleitzahlBaustelle: "",
      ortBaustelle: "",
      vornameAnsprechpartner: "",
      nachnameAnsprechpartner: "",
      unternehmenAnsprechpartner: "",
      strasseHausnummerAnsprechpartner: "",
      postleitzahlAnsprechpartner: "",
      ortAnsprechpartner: "",
      telefonnummer: "",
    });
  };
  const handleSubmit = async (e) => {
    if (currentId === 0) {
      setAnfrageData({
        //ID
        currentId: idState.currentId,
        setCurrendId: idState.setCurrentId,
        //Leistung
        leistung: anfrageState.leistung,
        beschreibungLeistung: anfrageState.beschreibungLeistung,
        //Balkon
        artBalkon: anfrageState.artBalkon,
        materialBalkon: anfrageState.materialBalkon,
        tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
        laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
        bodenBalkon: anfrageState.bodenBalkon,
        gelaenderBalkon: anfrageState.gelaenderBalkon,
        sichtschutzBalkon: anfrageState.sichtschutzBalkon,
        sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
        dachBalkon: anfrageState.dachBalkon,
        handlaufBalkon: anfrageState.handlaufBalkon,
        beschichtungBalkon: anfrageState.beschichtungBalkon,
        treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
        anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
        //Treppe
        formTreppe: anfrageState.formTreppe,
        gelaenderTreppe: anfrageState.gelaenderTreppe,
        bodenTreppe: anfrageState.bodenTreppe,
        ortTreppe: anfrageState.ortTreppe,
        materialTreppe: anfrageState.materialTreppe,
        anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
        //Geländer
        artGelaender: anfrageState.artGelaender,
        materialGelaender: anfrageState.materialGelaender,
        montageGelaender: anfrageState.montageGelaender,
        //Handlauf
        laengeGelaender: anfrageState.laengeGelaender,
        artHandlauf: anfrageState.artHandlauf,
        wandseiteHandlauf: anfrageState.wandseiteHandlauf,
        formHandlauf: anfrageState.formHandlauf,
        durchmesserHandlauf: anfrageState.durchmesserHandlauf,
        handlaufendeOben: anfrageState.handlaufendeOben,
        handlaufendeUnten: anfrageState.handlaufendeUnten,
        endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
        endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
        abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
        materialHandlauf: anfrageState.materialHandlauf,
        montageHandlauf: anfrageState.montageHandlauf,
        beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
        laengeHandlauf: anfrageState.laengeHandlauf,
        //Kundeninformationen
        beschreibung: anfrageState.beschreibung,
        vorangebot: anfrageState.vorangebot,
        umsetzung: anfrageState.umsetzung,
        kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
        bilderBaustelle: anfrageState.bilderBaustelle,
        zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
        postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
        ortBaustelle: anfrageState.ortBaustelle,
        vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
        nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
        unternehmenAnsprechpartner: anfrageState.unternehmenAnsprechpartner,
        strasseHausnummerAnsprechpartner:
          anfrageState.strasseHausnummerAnsprechpartner,
        postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
        ortAnsprechpartner: anfrageState.ortAnsprechpartner,
        telefonnummer: anfrageState.telefonnummer,
      });
      setAnfrageData((prevState) => {
        dispatch(createAnfrage(prevState));
        return prevState;
      });
      clear();
    } else {
      setAnfrageData({
        //ID
        currentId: idState.currentId,
        setCurrendId: idState.setCurrentId,
        //Leistung
        leistung: anfrageState.leistung,
        beschreibungLeistung: anfrageState.beschreibungLeistung,
        //Balkon
        artBalkon: anfrageState.artBalkon,
        materialBalkon: anfrageState.materialBalkon,
        tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
        laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
        bodenBalkon: anfrageState.bodenBalkon,
        gelaenderBalkon: anfrageState.gelaenderBalkon,
        sichtschutzBalkon: anfrageState.sichtschutzBalkon,
        sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
        dachBalkon: anfrageState.dachBalkon,
        handlaufBalkon: anfrageState.handlaufBalkon,
        beschichtungBalkon: anfrageState.beschichtungBalkon,
        treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
        anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
        //Treppe
        formTreppe: anfrageState.formTreppe,
        gelaenderTreppe: anfrageState.gelaenderTreppe,
        bodenTreppe: anfrageState.bodenTreppe,
        ortTreppe: anfrageState.ortTreppe,
        materialTreppe: anfrageState.materialTreppe,
        anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
        //Geländer
        artGelaender: anfrageState.artGelaender,
        materialGelaender: anfrageState.materialGelaender,
        montageGelaender: anfrageState.montageGelaender,
        //Handlauf
        laengeGelaender: anfrageState.laengeGelaender,
        artHandlauf: anfrageState.artHandlauf,
        wandseiteHandlauf: anfrageState.wandseiteHandlauf,
        formHandlauf: anfrageState.formHandlauf,
        durchmesserHandlauf: anfrageState.durchmesserHandlauf,
        handlaufendeOben: anfrageState.handlaufendeOben,
        handlaufendeUnten: anfrageState.handlaufendeUnten,
        endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
        endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
        abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
        materialHandlauf: anfrageState.materialHandlauf,
        montageHandlauf: anfrageState.montageHandlauf,
        beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
        laengeHandlauf: anfrageState.laengeHandlauf,
        //Kundeninformationen
        beschreibung: anfrageState.beschreibung,
        vorangebot: anfrageState.vorangebot,
        umsetzung: anfrageState.umsetzung,
        kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
        bilderBaustelle: anfrageState.bilderBaustelle,
        zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
        postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
        ortBaustelle: anfrageState.ortBaustelle,
        vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
        nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
        unternehmenAnsprechpartner: anfrageState.unternehmenAnsprechpartner,
        strasseHausnummerAnsprechpartner:
          anfrageState.strasseHausnummerAnsprechpartner,
        postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
        ortAnsprechpartner: anfrageState.ortAnsprechpartner,
        telefonnummer: anfrageState.telefonnummer,
      });
      setAnfrageData((prevState) => {
        dispatch(updateAnfrage(currentId, prevState));
        return prevState;
      });
      clear();
    }
  };

  const handleOnBaustelleClick = useCallback(
    () => navigate("beratung/projekt/baustelle", { replace: true }),
    [navigate]
  );

  const [zustandComponenteFQ, setZustandComponenteFQ] = useState(0);

  const baustelleValidationSchema2 = Yup.object().shape({
    adresse: Yup.string()
      .required("Adresse muss ausgefüllt werden.")
      .min(6, "Die Adresse muss aus mindestens 6 Zeichen bestehen."),
    adresse2: Yup.string()
      .required("Adresse muss ausgefüllt werden.")
      .min(6, "Die Adresse muss aus mindestens 6 Zeichen bestehen."),
    postleitzahl: Yup.number()
      .required("Postleitzahl muss ausgefüllt werden.")
      .min(5, "Die Postleitzahl muss aus 5 Zahlen bestehen."),
    ort: Yup.string()
      .required("Ort muss ausgefüllt werden.")
      .min(3, "Der Ortsname muss aus mindestens 3 Buchstaben bestehen."),
  });

  if (zustandComponenteFQ === 0) {
    return <Beschreibung />;
  } else if (zustandComponenteFQ === 1) {
    return (
      <div className="px-4 pb-4">
        <LinearWithValueVorangebot />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Soll dir ein Vorangebot gemacht werden?
        </h1>
        <br />
        <p>
          Info: Ein Vorangebot lässt man sich machen, wenn man sich erst noch
          informieren möchte und noch nicht beschlossen hat bei welchem
          Metallbauer man sein Vorhaben realisieren will.
        </p>
        <div className="row row-cols-2 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(2);
                dispatch(onListItemClickedQuestionsVorangebotJa());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Ja</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(2);
                dispatch(onListItemClickedQuestionsVorangebotNein());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Nein</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponenteFQ(0);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 2) {
    return (
      <div className="px-4 pb-4">
        <LinearWithValueUmsetzung />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wann soll das Vorhaben umgesetzt werden?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzungSofort());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Sofort</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzung1bis3Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 1 bis 3 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzung3bis6Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 3 bis 6 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzung6bis12Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 6 bis 12 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzungMehrAls12Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in mehr als 12 Monaten</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponenteFQ(1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 3) {
    return (
      <div className="px-4 pb-4">
        <LinearWithValueKaufenFinanzieren />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Willst du dein Vorhaben lieber kaufen oder finanzieren?
        </h1>
        <div className="row row-cols-2 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(4);
                dispatch(onListItemClickedQuestionsKaufen());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Kaufen</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(4);
                dispatch(onListItemClickedQuestionsFinanzieren());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Finanzieren</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponenteFQ(2);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 4) {
    return (
      <div className="px-4 pb-4">
        <LinearWithValueBilder />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Gibt es Bilder der Baustelle?
        </h1>
        <div name="bilderBaustelle" className="pt-4 pb-4">
          <label className="form-label">
            Hier kannst du Bilder der Baustelle hochladen.
          </label>
          <input
            onChange={async (e) =>
              furtherQuestionsCreators.updateProjektBaustellenBilder(
                e.target.files
              )
            }
            className="form-control"
            name="dateiBilderBaustelle[]"
            ref={bilderBaustelleInput}
            id="BilderBaustelle[]"
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
            multiple
          />
        </div>

        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(3);
          }}
        >
          ZURÜCK
        </button>
        <button
          key={"Weiter"}
          className="btn btn-success"
          onClick={() => {
            setZustandComponenteFQ(5);
          }}
        >
          WEITER
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 5) {
    return (
      <div className="px-4 pb-4">
        <LinearWithValueZeichnungen />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Gibt es bereits Zeichnungen oder Skizzen?
        </h1>
        <div name="SkizzenZeichnungen" className="pt-4 pb-4">
          <label className="form-label">
            Hier kannst du Zeichnungen oder Skizzen hochladen.
          </label>
          <input
            className="form-control"
            name="dateiZeichnungSkizze[]"
            id="dateiZeichnungSkizze[]"
            type="file"
            ref={zeichnungenSkizzeInput}
            onChange={async (e) =>
              furtherQuestionsCreators.updateProjektZeichnungenSkizzenBaustelle(
                e.target.files
              )
            }
            multiple
          />
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(4);
          }}
        >
          ZURÜCK
        </button>
        <button
          key={"Weiter"}
          className="btn btn-success"
          onClick={() => {
            handleOnBaustelleClick();
            setZustandComponenteFQ(6);
          }}
        >
          WEITER
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 6) {
    return <Baustelle />;
  } else if (zustandComponenteFQ === 7) {
    return (
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
        className="px-4 pb-4"
      >
        <LinearWithValueLabel />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Angaben zur Person oder Unternehmen
        </h1>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 16 }}
        >
          <Grid item xs={9} alignItems="left" justifyContent="left">
            <p align="left" className="angabenp">
              Nur noch wenige Angaben und wir können dir dein persönliches
              Angebot erstellen.
            </p>
            <FormControl align="left" className="pt-2 pb-2 float-left">
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Herr"
                  control={<Radio />}
                  label="Herr"
                  className="geschlecht"
                />
                <FormControlLabel
                  value="Frau"
                  control={<Radio />}
                  label="Frau"
                  className="geschlecht"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              id="outlined-vorname"
              label="Vorname"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenVorname(
                  e.target.value
                )
              }
            />
            <TextField
              id="outlined-nachname"
              label="Nachname"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenNachname(
                  e.target.value
                )
              }
            />
            <TextField
              id="outlined-unternehmen"
              label="Unternehmen (optional)"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenUnternehmen(
                  e.target.value
                )
              }
            />
            <FormLabel
              className="pt-4 pb-2  fs-5 fw-normal lh-lg float-left"
              id="outlined-adresse"
            >
              Adresse
            </FormLabel>
            <TextField
              id="outlined-adresse"
              label="Straße und Hausnummer"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerStrasseHausnummer(
                  e.target.value
                )
              }
            />
            <TextField
              id="outlined-postleitzahl"
              label="Postleitzahl"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerPostzeitzahl(
                  e.target.value
                )
              }
            />
            <TextField
              id="outlined-ort"
              label="Ort"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerOrt(
                  e.target.value
                )
              }
            />
            <FormLabel
              className="pt-4 pb-2  fs-5 fw-normal lh-lg float-left"
              id="outlined-adresse"
            >
              Kontaktdaten
            </FormLabel>
            <TextField
              id="outlined-email"
              label="E-Mail-Adresse"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerEMail(
                  e.target.value
                )
              }
            />

            <TextField
              id="outlined-telefonnummer"
              label="Telefonnummer"
              variant="outlined"
              className="textfield"
              size="small"
              onChange={(e) =>
                furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerTelefonnummer(
                  e.target.value
                )
              }
            />
            <FormGroup className="pb-4 pt-4">
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Ich akzeptiere die allgemeinen Geschäftsbedingungen der M&S Stuttgart GmbH."
                align="left"
                className="pb-2"
              />
              <FormControlLabel
                control={
                  <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />
                }
                label="Ja, M&S Stuttgart GmbH darf mit nützliche Tipps und Informationen rund um das Thema Handwerk zuschicken. Die Abmeldung ist jederzeit möglich."
                className="font-size-checkboxen"
                align="left"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={7}>
            <p>Wird noch gefüllt</p>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 16 }}
        >
          <Grid item xs={2} md={8} sm={4}>
            <button
              key={"Zurueck"}
              className="btn btn-secondary me-md-2 mx-2"
              type="button"
              onClick={() => {
                setZustandComponenteFQ(6);
              }}
            >
              ZURÜCK
            </button>
          </Grid>
          <Grid item xs={2} md={8} sm={4}>
            <button
              key={"Weiter"}
              className="btn btn-success"
              type="button"
              onClick={() => {
                setZustandComponenteFQ(8);
                handleSubmit();
              }}
            >
              Weiter
            </button>
          </Grid>
        </Grid>
      </Box>
    );
  } else if (zustandComponenteFQ === 8) {
    return (
      <div className="px-4 pb-4">
        <Termin />
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(7);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === -1) {
    switch (b2cLeistung) {
      case "Balkon":
        return <Stockwerke />;
      case "Handlauf":
        return <BeleuchtungHandlauf />;
      case "Treppe":
        return <StockwerkeTreppen />;
      case "Gelaender":
        return <LaengeGelaender />;
      default:
        return b2cLeistung;
    }
  }
};
export default FurtherQuestions;
