import { bindActionCreators } from "redux";
import store from "../../store";

const updateGelaenderLaenge = (laenge) => ({
  type: "CHANGE_GELAENDER_LAENGE",
  payload: laenge,
});

export const gelaenderCreators = bindActionCreators(
  {
    updateGelaenderLaenge,
  },
  store.dispatch
);
