import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onListItemClickedB2CTreppenAufbauMitSenkrechtenStaeben,
  onListItemClickedB2CTreppenAufbauHorizontaleFuellung,
  onListItemClickedB2CTreppenAufbauKassetenfuellungMitSenkrechtenStaeben,
  onListItemClickedB2CTreppenAufbauKassetenfuellungMitHorizontalenStaeben,
  onListItemClickedB2CTreppenAufbauGlasfuellung,
  onListItemClickedB2CTreppenAufbauMitSeilen,
} from "../../../../../actions/useCases/b2c/treppen/treppen";

import Treppen from "../Treppen";
import BodenTreppen from "./BodenTreppen";
import LinearWithPercent10 from "../../../../progress/number/progress10";

export default function GelaenderTreppen() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent10 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wie soll das Geländer der Treppe gefüllt sein?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/treppen/boden-treppe"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CTreppenAufbauMitSenkrechtenStaeben()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Mit senkrechten Stäben</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/treppen/boden-treppe"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CTreppenAufbauHorizontaleFuellung()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Horizontale Füllung</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/treppen/boden-treppe"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CTreppenAufbauKassetenfuellungMitSenkrechtenStaeben()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Kassetenfüllung mit senkrechten Stäben
              </p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/treppen/boden-treppe"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CTreppenAufbauKassetenfuellungMitHorizontalenStaeben()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Kassetenfüllung mit horizontalen Stäben
              </p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/treppen/boden-treppe"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CTreppenAufbauGlasfuellung());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Glasfüllung</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/treppen/boden-treppe"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CTreppenAufbauMitSeilen());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Mit Seilen</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <BodenTreppen />;
  } else if (zustandComponente == -1) {
    return <Treppen />;
  }
}
