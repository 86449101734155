import { bindActionCreators } from "redux";
import store from "../../store";

const updateSignupBenutzername = (benutzername) => ({
  type: "CHANGE_SIGNUP_BENUTZERNAME",
  payload: benutzername,
});
const updateSignupEmail = (email) => ({
  type: "CHANGE_SIGNUP_EMAIL",
  payload: email,
});

const updateSignupPasswort = (passwort) => ({
  type: "CHANGE_SIGNUP_PASSWORT",
  payload: passwort,
});
const updateSignupPasswortWdh = (passwortWdh) => ({
  type: "CHANGE_SIGNUP_PASSWORT_WDH",
  payload: passwortWdh,
});

export const boundToUserCreators = bindActionCreators(
  {
    benutzername: updateSignupBenutzername,
    email: updateSignupEmail,
    passwort: updateSignupPasswort,
    passwortWdh: updateSignupPasswortWdh,
    updateSignupBenutzername,
    updateSignupEmail,
    updateSignupPasswort,
    updateSignupPasswortWdh,
  },
  store.dispatch
);
