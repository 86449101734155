import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LinearWithPercent95 from "../progress/number/progress95";
import "./style.css";
import {
  createAnfrage,
  updateAnfrage,
  postAnfrage,
} from "../../actions/anfrage.js";
import { boundToIdCreators } from "../../actions/forms/idCreators";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Grid } from "@mui/material";

const Termin = () => {
  const dispatch = useDispatch();
  const anfrageState = useSelector((state) => state.anfrage);
  const idState = useSelector((state) => state.id);
  let currentId = useSelector((state) => state.id.currentId);
  let setCurrentId = useSelector((state) => state.id.setCurrentId);

  //Save in State

  const [anfrageData, setAnfrageData] = useState({
    //ID
    currentId: idState.currentId,
    setCurrendId: idState.setCurrentId,
    //Leistung
    leistung: anfrageState.leistung,
    beschreibungLeistung: anfrageState.beschreibungLeistung,
    //Balkon
    artBalkon: anfrageState.artBalkon,
    farbeBalkon: anfrageState.farbeBalkon,
    materialBalkon: anfrageState.materialBalkon,
    tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
    laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
    bodenBalkon: anfrageState.bodenBalkon,
    gelaenderAufbauBalkon: anfrageState.gelaenderAufbauBalkon,
    sichtschutzBalkon: anfrageState.sichtschutzBalkon,
    sichtschutzArt: anfrageState.sichtschutzArt,
    sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
    dachBalkon: anfrageState.dachBalkon,
    handlaufBalkon: anfrageState.handlaufBalkon,
    beschichtungBalkon: anfrageState.beschichtungBalkon,
    treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
    anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
    //Treppe
    formTreppe: anfrageState.formTreppe,
    aufbauGelaenderTreppe: anfrageState.aufbauGelaenderTreppe,
    stufenTreppe: anfrageState.stufenTreppe,
    ortTreppe: anfrageState.ortTreppe,
    materialTreppe: anfrageState.materialTreppe,
    beschichtungTreppe: anfrageState.beschichtungTreppe,
    anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
    //Geländer
    anbringungGelaender: anfrageState.anbringungGelaender,
    materialGrundgeruestGelaender: anfrageState.materialGrundgeruestGelaender,
    pfostenGelaender: anfrageState.pfostenGelaender,
    fuellungGelaender: anfrageState.fuellungGelaender,
    fuellungMaterialGelaender: anfrageState.fuellungMaterialGelaender,
    formGelaender: anfrageState.formGelaender,
    montageGelaender: anfrageState.montageGelaender,
    laengeGelaender: anfrageState.laengeGelaender,
    //Handlauf
    artHandlauf: anfrageState.artHandlauf,
    wandseiteHandlauf: anfrageState.wandseiteHandlauf,
    formHandlauf: anfrageState.formHandlauf,
    durchmesserHandlauf: anfrageState.durchmesserHandlauf,
    handlaufendeOben: anfrageState.handlaufendeOben,
    handlaufendeUnten: anfrageState.handlaufendeUnten,
    endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
    endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
    abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
    laengeHandlauf: anfrageState.laengeHandlauf,
    materialHandlauf: anfrageState.materialHandlauf,
    ortHandlauf: anfrageState.ortHandlauf,
    montageHandlauf: anfrageState.montageHandlauf,
    beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,

    //Wartungen & Instandsetzung
    maengelWI: anfrageState.maengelWI,
    wartungWI: anfrageState.wartungWI,
    haeufigkeitWI: anfrageState.haeufigkeitWI,
    artWI: anfrageState.artWI,
    wartungsplanWI: anfrageState.wartungsplanWI,
    inspektionWI: anfrageState.inspektionWI,
    empfehlungenInstandsetzungsarbeitenWI:
      anfrageState.empfehlungenInstandsetzungsarbeitenWI,
    instandsetzungReparaturBeidesWI:
      anfrageState.instandsetzungReparaturBeidesWI,
    dringlichkeitWI: anfrageState.dringlichkeitWI,
    beginnWI: anfrageState.beginnWI,
    //Kundeninformationen
    beschreibung: anfrageState.beschreibung,
    vorangebot: anfrageState.vorangebot,
    umsetzung: anfrageState.umsetzung,
    kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
    bilderBaustelle: anfrageState.bilderBaustelle,

    zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
    adresseBaustelle: anfrageState.adresseBaustelle,
    postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
    ortBaustelle: anfrageState.ortBaustelle,
    vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
    nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
    unternehmen: anfrageState.unternehmen,
    adresseAnsprechpartner: anfrageState.adresseAnsprechpartner,
    postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
    ortAnsprechpartner: anfrageState.ortAnsprechpartner,
    telefonnummer: anfrageState.telefonnummer,
    email: anfrageState.eMail,
    agb: anfrageState.agb,
    newsletter: anfrageState.newsletter,
  });

  const anfrageS = useSelector((state) =>
    currentId
      ? state.anfrage.find((message) => message._id === currentId)
      : null
  );

  useEffect(() => {
    if (anfrageS) setAnfrageData(anfrageS);
  }, [anfrageS]);

  useEffect(() => {
    handleSubmit2();
  }, []);

  const clear = () => {
    currentId = 0;
    setCurrentId = 0;
    boundToIdCreators.setCurrentId(currentId);
    boundToIdCreators.setCurrentId(setCurrentId);
    setAnfrageData({
      leistung: "",
      beschreibungLeistung: "",
      //Balkon
      artBalkon: "",
      farbeBalkon: "",
      materialBalkon: "",
      tiefeInMeterBalkon: "",
      laengeInMeterBalkon: "",
      bodenBalkon: "",
      gelaenderAufbauBalkon: "",
      sichtschutzBalkon: "",
      sichtschutzArt: "",
      sichtschutzFarbeBalkon: "",
      dachBalkon: "",
      handlaufBalkon: "",
      beschichtungBalkon: "",
      treppeZumGartenBalkon: "",
      anzahlStockwerkeBalkon: "",
      //Treppe
      formTreppe: "",
      aufbauGelaenderTreppe: "",
      stufenTreppe: "",
      ortTreppe: "",
      materialTreppe: "",
      beschichtungTreppe: "",
      anzahlStockwerkeTreppe: "",
      //Geländer
      anbringungGelaender: "",
      materialGrundgeruestGelaender: "",
      pfostenGelaender: "",
      fuellungGelaender: "",
      fuellungMaterialGelaender: "",
      formGelaender: "",
      montageGelaender: "",
      laengeGelaender: "",
      //Handlauf
      artHandlauf: "",
      wandseiteHandlauf: "",
      formHandlauf: "",
      durchmesserHandlauf: "",
      handlaufendeOben: "",
      handlaufendeUnten: "",
      endkappeObenHandlauf: "",
      endkappeUntenHandlauf: "",
      abdeckrossetenHandlauf: "",
      materialHandlauf: "",
      ortHandlauf: "",
      montageHandlauf: "",
      beleuchtungHandlauf: "",
      laengeHandlauf: "",
      //Wartungen & Instandsetzung
      maengelWI: "",
      wartungWI: "",
      haeufigkeitWI: "",
      artWI: "",
      wartungsplanWI: "",
      inspektionWI: "",
      empfehlungenInstandsetzungsarbeitenWI: "",
      instandsetzungReparaturBeidesWI: "",
      dringlichkeitWI: "",
      beginnWI: "",
      //Kontakt
      beschreibung: "",
      vorangebot: "",
      umsetzung: "",
      kaufenOderFinanzieren: "",
      bilderBaustelle: "",
      zeichnungenBaustelle: "",
      adresseBaustelle: "",
      postleitzahlBaustelle: "",
      ortBaustelle: "",
      vornameAnsprechpartner: "",
      nachnameAnsprechpartner: "",
      unternehmen: "",
      strasseHausnummerAnsprechpartner: "",
      postleitzahlAnsprechpartner: "",
      ortAnsprechpartner: "",
      email: "",
      telefonnummer: "",
      agb: "",
      newsletter: "",
    });
  };
  const handleSubmit2 = async (e) => {
    if (currentId === 0) {
      setAnfrageData({
        //ID
        currentId: idState.currentId,
        setCurrendId: idState.setCurrentId,
        //Leistung
        leistung: anfrageState.leistung,
        beschreibungLeistung: anfrageState.beschreibungLeistung,
        //Balkon
        artBalkon: anfrageState.artBalkon,
        farbeBalkon: anfrageState.farbeBalkon,
        materialBalkon: anfrageState.materialBalkon,
        tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
        laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
        bodenBalkon: anfrageState.bodenBalkon,
        gelaenderAufbauBalkon: anfrageState.gelaenderAufbauBalkon,
        sichtschutzBalkon: anfrageState.sichtschutzBalkon,
        sichtschutzArt: anfrageState.sichtschutzArt,
        sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
        dachBalkon: anfrageState.dachBalkon,
        handlaufBalkon: anfrageState.handlaufBalkon,
        beschichtungBalkon: anfrageState.beschichtungBalkon,
        treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
        anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
        //Treppe
        formTreppe: anfrageState.formTreppe,
        aufbauGelaenderTreppe: anfrageState.aufbauGelaenderTreppe,
        stufenTreppe: anfrageState.stufenTreppe,
        ortTreppe: anfrageState.ortTreppe,
        materialTreppe: anfrageState.materialTreppe,
        beschichtungTreppe: anfrageState.beschichtungTreppe,
        anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
        //Geländer
        anbringungGelaender: anfrageState.anbringungGelaender,
        materialGrundgeruestGelaender:
          anfrageState.materialGrundgeruestGelaender,
        pfostenGelaender: anfrageState.pfostenGelaender,
        fuellungGelaender: anfrageState.fuellungGelaender,
        fuellungMaterialGelaender: anfrageState.fuellungMaterialGelaender,
        formGelaender: anfrageState.formGelaender,
        montageGelaender: anfrageState.montageGelaender,
        laengeGelaender: anfrageState.laengeGelaender,
        //Handlauf
        artHandlauf: anfrageState.artHandlauf,
        wandseiteHandlauf: anfrageState.wandseiteHandlauf,
        formHandlauf: anfrageState.formHandlauf,
        durchmesserHandlauf: anfrageState.durchmesserHandlauf,
        handlaufendeOben: anfrageState.handlaufendeOben,
        handlaufendeUnten: anfrageState.handlaufendeUnten,
        endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
        endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
        abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
        laengeHandlauf: anfrageState.laengeHandlauf,
        materialHandlauf: anfrageState.materialHandlauf,
        ortHandlauf: anfrageState.ortHandlauf,
        montageHandlauf: anfrageState.montageHandlauf,
        beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
        //Wartungen & Instandsetzung
        maengelWI: anfrageState.maengelWI,
        wartungWI: anfrageState.wartungWI,
        haeufigkeitWI: anfrageState.haeufigkeitWI,
        artWI: anfrageState.artWI,
        wartungsplanWI: anfrageState.wartungsplanWI,
        inspektionWI: anfrageState.inspektionWI,
        empfehlungenInstandsetzungsarbeitenWI:
          anfrageState.empfehlungenInstandsetzungsarbeitenWI,
        instandsetzungReparaturBeidesWI:
          anfrageState.instandsetzungReparaturBeidesWI,
        dringlichkeitWI: anfrageState.dringlichkeitWI,
        beginnWI: anfrageState.beginnWI,
        //Kundeninformationen
        beschreibung: anfrageState.beschreibung,
        vorangebot: anfrageState.vorangebot,
        umsetzung: anfrageState.umsetzung,
        kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
        bilderBaustelle: anfrageState.bilderBaustelle,
        zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
        adresseBaustelle: anfrageState.adresseBaustelle,
        postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
        ortBaustelle: anfrageState.ortBaustelle,
        vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
        nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
        unternehmen: anfrageState.unternehmen,
        adresseAnsprechpartner: anfrageState.adresseAnsprechpartner,
        postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
        ortAnsprechpartner: anfrageState.ortAnsprechpartner,
        telefonnummer: anfrageState.telefonnummer,
        email: anfrageState.eMail,
        agb: anfrageState.agb,
        newsletter: anfrageState.newsletter,
      });

      //Hier muss auch der Bilder Upload geschehen

      //Config für Axios Post

      const url2 =
        process.env.REACT_APP_LOCALHOST_UPLOAD_DATA ||
        "https://ms-stuttgart-api.herokuapp.com/data/upload";

      //Post mit FormData für Files

      //Form Data und For Schleife
      const formData = new FormData();
      formData.append("mail", anfrageState.eMail);
      const bilder = anfrageState.bilderBaustelle;
      const zeichnungen = anfrageState.zeichnungenBaustelle;

      if (bilder === undefined && zeichnungen === undefined) {
        //Nichtstun ansonsten
        //console.log("Bilder & Zeichnungen sind undefined");
        //Axios Type Post
      }
      if (bilder === undefined && zeichnungen != undefined) {
        //console.log("Bilder sind undefined");
        for (let i = 0; i < zeichnungen.length; i++) {
          formData.append("file", zeichnungen[i], zeichnungen[i].name);
        }
        //Axios Type Post
        axios({
          method: "post",
          url: url2,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //console.log(response.status);
          })
          .catch(function (response) {
            //console.log(response.status);
            console.error(response);
          });
      }
      if (bilder != undefined && zeichnungen === undefined) {
        //console.log("Zeichnungen sind undefined");
        for (let i = 0; i < bilder.length; i++) {
          formData.append("file", bilder[i], bilder[i].name);
        }
        //Axios Type Post
        axios({
          method: "post",
          url: url2,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //console.log(response.status);
          })
          .catch(function (response) {
            //console.log(response.status);
            console.error(response);
          });
      }
      if (bilder != undefined && zeichnungen != undefined) {
        for (let i = 0; i < bilder.length; i++) {
          formData.append("file", bilder[i], bilder[i].name);
        }

        for (let i = 0; i < zeichnungen.length; i++) {
          formData.append("file", zeichnungen[i], zeichnungen[i].name);
        }
        //Axios Type Post
        axios({
          method: "post",
          url: url2,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //console.log(response.status);
          })
          .catch(function (response) {
            //console.log(response.status);
            console.error(response);
          });
      }

      setAnfrageData((prevState) => {
        dispatch(createAnfrage(prevState));

        /*dispatch(postAnfrage(prevState));*/

        return prevState;
      });
      clear();
    } else {
      setAnfrageData({
        //ID
        currentId: idState.currentId,
        setCurrendId: idState.setCurrentId,
        //Leistung
        leistung: anfrageState.leistung,
        beschreibungLeistung: anfrageState.beschreibungLeistung,
        //Balkon
        artBalkon: anfrageState.artBalkon,
        farbeBalkon: anfrageState.farbeBalkon,
        materialBalkon: anfrageState.materialBalkon,
        tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
        laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
        bodenBalkon: anfrageState.bodenBalkon,
        gelaenderAufbauBalkon: anfrageState.gelaenderAufbauBalkon,
        sichtschutzBalkon: anfrageState.sichtschutzBalkon,
        sichtschutzArt: anfrageState.sichtschutzArt,
        sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
        dachBalkon: anfrageState.dachBalkon,
        handlaufBalkon: anfrageState.handlaufBalkon,
        beschichtungBalkon: anfrageState.beschichtungBalkon,
        treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
        anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
        //Treppe
        formTreppe: anfrageState.formTreppe,
        aufbauGelaenderTreppe: anfrageState.aufbauGelaenderTreppe,
        stufenTreppe: anfrageState.stufenTreppe,
        ortTreppe: anfrageState.ortTreppe,
        materialTreppe: anfrageState.materialTreppe,
        beschichtungTreppe: anfrageState.beschichtungTreppe,
        anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
        //Geländer
        anbringungGelaender: anfrageState.anbringungGelaender,
        materialGrundgeruestGelaender:
          anfrageState.materialGrundgeruestGelaender,
        pfostenGelaender: anfrageState.pfostenGelaender,
        fuellungGelaender: anfrageState.fuellungGelaender,
        fuellungMaterialGelaender: anfrageState.fuellungMaterialGelaender,
        formGelaender: anfrageState.formGelaender,
        montageGelaender: anfrageState.montageGelaender,
        laengeGelaender: anfrageState.laengeGelaender,
        //Handlauf
        artHandlauf: anfrageState.artHandlauf,
        wandseiteHandlauf: anfrageState.wandseiteHandlauf,
        formHandlauf: anfrageState.formHandlauf,
        durchmesserHandlauf: anfrageState.durchmesserHandlauf,
        handlaufendeOben: anfrageState.handlaufendeOben,
        handlaufendeUnten: anfrageState.handlaufendeUnten,
        endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
        endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
        abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
        laengeHandlauf: anfrageState.laengeHandlauf,
        materialHandlauf: anfrageState.materialHandlauf,
        ortHandlauf: anfrageState.ortHandlauf,
        montageHandlauf: anfrageState.montageHandlauf,
        beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
        //Wartungen & Instandsetzung
        maengelWI: anfrageState.maengelWI,
        wartungWI: anfrageState.wartungWI,
        haeufigkeitWI: anfrageState.haeufigkeitWI,
        artWI: anfrageState.artWI,
        wartungsplanWI: anfrageState.wartungsplanWI,
        inspektionWI: anfrageState.inspektionWI,
        empfehlungenInstandsetzungsarbeitenWI:
          anfrageState.empfehlungenInstandsetzungsarbeitenWI,
        instandsetzungReparaturBeidesWI:
          anfrageState.instandsetzungReparaturBeidesWI,
        dringlichkeitWI: anfrageState.dringlichkeitWI,
        beginnWI: anfrageState.beginnWI,
        //Kundeninformationen
        beschreibung: anfrageState.beschreibung,
        vorangebot: anfrageState.vorangebot,
        umsetzung: anfrageState.umsetzung,
        kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
        bilderBaustelle: anfrageState.bilderBaustelle,
        zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
        adresseBaustelle: anfrageState.adresseBaustelle,
        postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
        ortBaustelle: anfrageState.ortBaustelle,
        vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
        nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
        unternehmen: anfrageState.unternehmen,
        adresseAnsprechpartner: anfrageState.adresseAnsprechpartner,
        postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
        ortAnsprechpartner: anfrageState.ortAnsprechpartner,
        telefonnummer: anfrageState.telefonnummer,
        email: anfrageState.eMail,
        agb: anfrageState.agb,
        newsletter: anfrageState.newsletter,
      });
      setAnfrageData((prevState) => {
        dispatch(updateAnfrage(currentId, prevState));
        return prevState;
      });
      clear();
    }
  };

  return (
    <div className="px-4 pb-4 ">
      <LinearWithPercent95 />
      <h1 className="bigheadline text-center fs-3 fw-normal lh-lg pt-4">
        Deine Angebotsanfrage war erfolgreich
      </h1>
      <br></br>
      <div className="px-4 pb-4 pt-4 erfolgsmeldung">
        <p className="dankemeldung">
          Vielen Dank - Deine Angebotsanfrage war erfolgreich!
        </p>
        <br></br>
        <p align="left">So geht es für dich weiter:</p>
        <ul>
          <li>
            {" "}
            <p className="stichpunkt" align="left">
              Einer unserer Experten wird deine Anfrage bearbeiten.
            </p>
          </li>
          <li>
            <p className="stichpunkt" align="left">
              Zur Erstellung deines Angebotes ist ggf. ein kurzes Telefonat oder
              Zoom Gespräch notwendig.
            </p>
          </li>
          <li>
            {" "}
            <p className="stichpunkt" align="left">
              Der Anruf erfolgt von der Nummer <b>+49 7146 4072622</b>.{" "}
            </p>
          </li>
          <li>
            {" "}
            <p className="stichpunkt" align="left">
              Oder mittels der Meeting Software Zoom.{" "}
            </p>
          </li>
        </ul>

        <br></br>
        <p align="left">
          Wir freuen uns, dir ein auf deine Befürfnisse angepasstes Angebot
          machen zu können.
        </p>
        <p align="left">Dein M&S Stuttgart Team</p>
      </div>
      <div className="px-4 pb-4">
        <h1 className="bigheadline text-center fs-3 fw-normal lh-lg pt-4">
          Wir rufen Dich zu deinem Wunschtermin zurück
        </h1>
        <p>Platzhalter für Programm</p>
      </div>
      <div className="px-4 pb-4">
        <h1 className="bigheadline text-center fs-3 fw-normal lh-lg pt-4">
          So buchst du einen Termin
        </h1>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={4} md={4} className="pt-2 pb-2">
            <h2 className="subheadline pt-2 pb-2">Termin vereinbaren</h2>
            <p className="subtext">
              Im ersten Schritt vereinbarst du den Termin. Dazu suchst du dir
              aus, wann du Zeit hast, klickst im Terminkalender einen
              entsprechenden Termin an und hinterlegst uns deinen Namen,
              Telefonnummer und deine E-Mail-Adresse.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={4} className="pt-2 pb-2">
            <h2 className="subheadline pt-2 pb-2">Informationen per Mail</h2>
            <p className="subtext">
              Wenn deine Buchung abgeschlossen ist, schicken wir dir eine Mail
              mit den Informationen zu deinem Termin. Hier ist auch der Zoom
              Beitrittslink enthalten, mit dem du an dem kostenlosen
              Beratungsgespräch teilnehmen kannst.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={4} className="pt-2 pb-2">
            <h2 className="subheadline pt-2 pb-2">Zoom Gespräch</h2>
            <p className="subtext">
              An dem vereinbarten Tag findet unser Beratungsgespräch statt.
              Hierzu klicks du auf den Beitrittlink. Einer unserer Kundenberater
              bzw. Meister wird sich dann mit dir über dein Projekt unterhalten.
            </p>
          </Grid>
        </Grid>
      </div>
      <div className="px-4 pb-4">
        <h1 className="bigheadline text-center fs-3 fw-normal lh-lg pt-4">
          Informationen zu Zoom
        </h1>
        <img
          className="zoomimage pb-4 pt-4"
          src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/elementor/thumbs/hand-6024028_1920-p63e7nedm863exo8jxt1tjirhehdvreog9e0ne5kao.jpg"
          title="hand-6024028_1920"
          alt="hand-6024028_1920"
        ></img>
        <p className="pb-2 pt-2">
          Zoom ist eine Software für Videokonferenzen. In einem Zoom Meeting
          können mehrere Nutzer über eine App sowie Webanwendung oder
          Desktop-Software miteinander kommunizieren. Die Coronakrise hat
          gezeigt, dass auch wir als Handwerker unsere Dienstleistungen
          digitalisieren müssen. Daher findet unser kostenloses
          Beratungsgespräch mithilfe von Zoom statt.
        </p>
        <Button
          href="https://zoom.us/signup"
          target="_blank"
          variant="outline-success"
        >
          Zoom herunterladen
        </Button>
      </div>
      <div className="px-4 pb-4">
        <h1 className="bigheadline text-center fs-3 fw-normal lh-lg pt-4 pb-4">
          Nach dem Gespäch: In drei Schritten zu deinem Projekt
        </h1>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={4} md={4} className="pt-2 pb-2">
            <h2 className="subheadline pt-2 pb-2">Auftrag & Zeichnung</h2>
            <p className="subtext">
              Nach dem Beratungsgespräch erstellen wir ein Angebot. Nach der
              Bestätigung geht der Auftrag an unseren Zeichner. Vor dem Start
              wird überprüft, ob alles passt.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={4} className="pt-2 pb-2">
            <h2 className="subheadline pt-2 pb-2">
              Realisierung deines Projekts
            </h2>
            <p className="subtext">
              Jetzt beginnt die Realisierung deines Projekts. Je nach Material
              geben wir den Teilauftrag entweder an eines unser
              Partnerunternehmen oder machen uns selber an die Realisierung des
              Auftrags.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={4} className="pt-2 pb-2">
            <h2 className="subheadline pt-2 pb-2">Montage durch unser Team</h2>
            <p className="subtext">
              Im Anschluss schicken wir eines unserer Teams zu dir und nehmen
              die Montage vor. Danach sind wir in unserem Wartungs- und
              Serviceangebot jederzeit für dich da.{" "}
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Termin;
