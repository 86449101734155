import React, { setState, useState } from "react";
import Balkone from "./Services/Balkone";
import Treppen from "./Services/Treppen";
import Gelaender from "./Services/Gelaender";
import Handlauf from "./Services/Handlauf";
import Tore from "./Services/Tore";
import Tueren from "./Services/Tueren";
import Fenster from "./Services/Fenster";
import Ueberdachungen from "./Services/Ueberdachungen";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import UseCasesFunction from "../UseCasesFunction";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  onListItemClickedB2CBalkone,
  onListItemClickedB2CFenster,
  onListItemClickedB2CGelaender,
  onListItemClickedB2CHandlauf,
  onListItemClickedB2CTore,
  onListItemClickedB2CTueren,
  onListItemClickedB2CUeberdachungen,
  onListItemClickedB2CTreppe,
  onListItemClickedB2CAndereLeistung,
  onListItemClickedB2CWI,
} from "../../../actions/useCases/b2c/b2c";

import { render } from "react-dom";
import { Helmet } from "react-helmet";
import B2bComponent from "../B2bComponent/B2bComponent";
import WiComponent from "../WiComponent/WiComponent";
import Login from "../../user/login";
import LinearWithPercent0 from "../../progress/number/progress0";
import Beschreibung from "../../contact/beschreibung";
import WIMaengel from "../../wartung-instandsetzung/wartung-maengel";

export default function B2cComponentFunction() {
  const b2cLeistung = useSelector((state) => state.anfrage.leistung);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const leistung = useSelector((state) => state.anfrage.leistung);

  const [anfrageData, setAnfrageData] = useState({
    balkon: "",
    treppe: "",
    gelaender: "",
    handlauf: "",
    andereLeistungen: "",
    wartungenInstandsetrzungen: "",
  });

  // Deklariere eine neue State Variable, die wir "zustandComponente" nennen werden
  const [zustandComponente, setZustandComponente] = useState(0);

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <Helmet>
          <title>Welche Leistungen sollen wir für dich realisieren?</title>
          <meta
            name="description"
            content="Wähle aus was du mit uns umsetzen möchtest"
          />
        </Helmet>
        <LinearWithPercent0 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Welche Leistung sollen wir für dich realisieren?
        </h1>

        <div className="row row-cols-3 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"Balkone"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkone());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Balkone</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/treppen"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4 divRand pt-4 pb-4"
              key={"Treppe"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CTreppe());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Treppe</p>
            </div>
          </Link>
          {/*<div
            calssName="col breakpointColsM divRand pt-4 pb-4"
            key={"Fenster"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CFenster());
            }}
          >
            <p className="text_wrapper">Fenster</p>
          </div>*/}
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"Gelaender"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaender());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Geländer</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/handlauf"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"Handlauf"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CHandlauf());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Handlauf</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/andere-leistung"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"B2CAndereLeistung"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CAndereLeistung());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Andere Leistungen</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/wartungen"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              key={"B2CWI"}
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CWI());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Wartungen, Reparaturen & Instandsetzungen
              </p>
            </div>
          </Link>
          {/*<div
            calssName="col breakpointColsM divRand pt-4 pb-4"
            key={"Tore"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CTore());
            }}
          >
                        <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
          <p className="text_wrapper">Tore</p>
          </div>
          <div
            calssName="col breakpointColsM divRand pt-4 pb-4"
            key={"Tueren"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CTueren());
            }}
          >
                        <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
            <p className="text_wrapper">Türen</p>
          </div>
          <div
            calssName="col breakpointColsM divRand pt-4 pb-4"
            key={"Ueberdachungen"}
            onClick={() => {
              setZustandComponente(1);
              dispatch(onListItemClickedB2CUeberdachungen());
            }}
          >
                        <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
            <p className="text_wrapper">Überdachungen</p>
          </div>*/}
        </div>
        {/*
       
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
       
       
       */}
      </div>
    );
  } else if (zustandComponente == 1) {
    switch (b2cLeistung) {
      case "Balkon":
        return <Balkone />;
      case "Treppe":
        return <Treppen />;
      case "Fenster":
        return <Fenster />;
      case "Gelaender":
        return <Gelaender />;
      case "Handlauf":
        return <Handlauf />;
      case "Tore":
        return <Tore />;
      case "Tueren":
        return <Tueren />;
      case "Ueberdachungen":
        return <Ueberdachungen />;
      case "B2CAndereLeistung":
        return <Beschreibung />;
      case "B2CWI":
        return <WIMaengel />;
      case "LOGIN":
        return <Login />;
      default:
        return null;
    }
  } else if (zustandComponente == -1) {
    return <UseCasesFunction />;
  }
}

function zurueck() {
  return <h1>Geht noch nicht</h1>;
}
