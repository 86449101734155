import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import PasswordStr from "./passwortStr";
import "./style.css";
import { boundToUserCreators } from "../../actions/user/signup";
import { boundToIdCreators } from "../../actions/forms/idCreators";
import { createUser } from "../../api/user.js";
import Termin from "../termin/termin";

const Signup = ({
  history,
  onSubmit,
  onChange,
  errors,
  user,
  score,
  btnTxt,
  type,
  pwMask,
  onPwChange,
}) => {
  const currentId = useSelector((state) => state.id.currentId);
  const setCurrentId = useSelector((state) => state.id.setCurrentId);
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    id: userState.id,
    benutzername: userState.benutzername,
    email: userState.email,
    passwort: userState.passwort,
    passwortwdh: userState.passwortwdh,
  });

  const users = useSelector((state) =>
    currentId ? state.user.find((message) => message._id === currentId) : null
  );

  useEffect(() => {
    if (users) setUserData(users);
  }, [users]);

  const clearUser = () => {
    setUserData({
      id: "",
      benutzername: "",
      email: "",
      passwort: "",
      passwortwdh: "",
    });
  };

  const handleSignup = async (e) => {
    setUserData({
      id: userState.id,
      benutzername: userState.benutzername,
      email: userState.email,
      passwort: userState.passwort,
      passwortwdh: userState.passwortwdh,
    });
    setUserData((prevState) => {
      dispatch(createUser(prevState));
      return prevState;
    });
    clearUser();
  };

  const [zustandComponente, setZustandComponente] = useState(0);
  if (zustandComponente === 0) {
    return (
      <div className="px-4 pb-4 loginBox">
        <h1 className="signup-headline fs-3 fw-normal lh-lg pt-4">
          Account erstellen
        </h1>
        {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}

        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-required-benutzername"
            label="Benutzername"
            variant="outlined"
            required
            onChange={(e) =>
              boundToUserCreators.updateSignupBenutzername(e.target.value)
            }
          />
          <TextField
            id="outlined-required-email"
            label="E-Mail-Adresse"
            variant="outlined"
            required
            onChange={(e) =>
              boundToUserCreators.updateSignupEmail(e.target.value)
            }
          />
          <TextField
            id="outlined-password-input"
            label="Passwort"
            type="password"
            variant="outlined"
            autoComplete="current-password"
            required
            onChange={(e) =>
              boundToUserCreators.updateSignupPasswort(e.target.value)
            }
            errorText={errors.password}
          />

          <div className="pwStrRow">
            {score >= 1 && (
              <div>
                <PasswordStr score={score} />
                <Button
                  className="pwShowHideBtn"
                  label={btnTxt}
                  onClick={pwMask}
                  style={{
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                />
              </div>
            )}
          </div>
          <TextField
            id="outlined-password-wdh-input"
            label="Passwort bestätigen"
            type="password"
            autoComplete="current-password"
            onChange={(e) =>
              boundToUserCreators.updateSignupPasswortWdh(e.target.value)
            }
            placeholder="Passwort bestätigen"
            required
          />
          <br />
          <Button
            className="signUpSubmit"
            primary={true}
            type="submit"
            label="Registrieren"
            variant="contained"
            size="large"
            color="success"
            onClick={() => {
              handleSignup();
            }}
          >
            Registrieren
          </Button>
        </Box>
        <p>
          Hast du bereits einen Account? <br />
          <a href="/">Login</a>
        </p>
      </div>
    );
  } else if (zustandComponente === 1) {
    return <Termin />;
  }
};
export default Signup;
