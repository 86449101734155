import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Ueberdachungen from "../Ueberdachungen";

export default function Vordach() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">Vordach</h1>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == -1) {
    return <Ueberdachungen />;
  }
}
