import React, { useState } from "react";
import B2cComponentFunction from "../B2cComponentFunction";
import FurtherQuestions from "../FurtherQuestions/FurtherQuestions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { gelaenderCreators } from "../../../../actions/forms/gelaenderCreators";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  onListItemClickedB2CGelaenderAnbringungBalkon,
  onListItemClickedB2CGelaenderAnbringungTreppe,
  onListItemClickedB2CGelaenderAnbringungGehweg,
  onListItemClickedB2CGelaenderAnbringungGalerie,
  onListItemClickedB2CGelaenderAnbringungIndustriegelaender,
  onListItemClickedB2CGelaenderAnbringungDach,
  onListItemClickedB2CGelaenderAnbringungFensterFranzoesischesGelaender,
  onListItemClickedB2CGelaenderAnbringungSonstigeOrte,
} from "../../../../actions/useCases/b2c/gelaender/gelaender";
import MaterialGelaender from "./Gelaender/MaterialGelaender";
import LinearWithPercent10 from "../../../progress/number/progress10";

export default function Gelaender() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent10 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wo soll das Geländer angebracht werden?
        </h1>
        <div className="row row-cols-4 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderAnbringungBalkon());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Balkon</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderAnbringungTreppe());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Treppe</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderAnbringungGehweg());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Gehweg</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderAnbringungGalerie());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Galerie</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CGelaenderAnbringungIndustriegelaender()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Industriegeländer</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderAnbringungDach());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Dach</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CGelaenderAnbringungFensterFranzoesischesGelaender()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Fenster (Französisches Geländer)</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/material-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderAnbringungSonstigeOrte());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Sonstige Orte</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <MaterialGelaender />;
  } else if (zustandComponente == -1) {
    return <B2cComponentFunction />;
  }
}
