function useCaseReducer(state = [], action) {
  switch (action.type) {
    case "B2C":
      return {
        ...state,
        useCase: "B2C",
        beschreibungUseCase:
          "Kunde möchte ein Projekt realisieren und braucht Beratung.",
      };
    case "B2B":
      return {
        ...state,
        useCase: "B2B",
        beschreibungUseCase:
          "Kunde weiß bereits was er bzw. sie will und hat bereits eine Zeichnung vorliegen",
      };
    case "WI":
      return {
        ...state,
        useCase: "WI",
        beschreibungUseCase:
          "Kunde will Wartungen oder Instandsetzungen durchführen lassen.",
      };
    default:
      return state;
  }
}
export default useCaseReducer;
