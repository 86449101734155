import axios from "axios";

const tokenEndpoint = "https://dev-qk5jk9ep.us.auth0.com/oauth/token";

const oAuth = (req, res, next) => {
  var code = req.query.code;
  if (!code) {
    res.status(401).send("Missing authorization code");
  }

  const params = new URLSearchParams();
  params.append("grand_type", "client_credentials");

  params.append("client_id", "fZfUTbFTz4xhKLXWdEBlGX1PndZSkSPF");
  params.append(
    "client_secret",
    "qmhc7oYMBx43UOSVjgzT2y4wcPggVIzRv_fXe_Kifv-Mm4u5RePCcc28NYVGtTqM"
  );
  params.append("audience", "https://api.metallbau-stahlbau-stuttgart.de");
  params.append("code", code);
  params.append("grand_type", "authorization_code");
  params.append("redirect_uri", "http://localhost:3000");

  axios
    .post(tokenEndpoint, params)
    .then((response) => {
      req.outh = response.data;
      next();
    })
    .catch((err) => {
      console.log(err);
      res.status(403).json(`Reason: ${err.message}`);
    });
};
module.export = oAuth;
