import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onListItemClickedB2CGelaenderFuellungMitSenkrechtenStaeben,
  onListItemClickedB2CGelaenderFuellungHorizontaleFuellung,
  onListItemClickedB2CGelaenderFuellungKassettenfüllungMitSenkrechtenStaeben,
  onListItemClickedB2CGelaenderFuellungKassettenfüllungMitHorizontalenStaeben,
  onListItemClickedB2CGelaenderFuellungGlasfuellung,
  onListItemClickedB2CGelaenderFuellungMitSeilen,
} from "../../../../../actions/useCases/b2c/gelaender/gelaender";

import LinearWithPercent25 from "../../../../progress/number/progress25";
import PfostenGelaender from "./PfostenGelaender";
import FuellungMaterialGelaender from "./FuellungMaterialGelaender";

export default function FuellungGelaender() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent25 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wie soll das Geländer gefüllt sein?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/standort-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CGelaenderFuellungMitSenkrechtenStaeben()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Mit senkrechten Stäben</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/standort-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CGelaenderFuellungHorizontaleFuellung()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Horizontale Füllung</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/standort-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CGelaenderFuellungKassettenfüllungMitSenkrechtenStaeben()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Kassettenfüllung mit senkrechten Stäben
              </p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/standort-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(
                  onListItemClickedB2CGelaenderFuellungKassettenfüllungMitHorizontalenStaeben()
                );
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">
                Kassettenfüllung mit horizontalen Stäben
              </p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/standort-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderFuellungGlasfuellung());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Glasfüllung</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/gelaender/standort-gelaender"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CGelaenderFuellungMitSeilen());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Mit Seilen</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <FuellungMaterialGelaender />;
  } else if (zustandComponente == -1) {
    return <PfostenGelaender />;
  }
}
