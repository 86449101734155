import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  onListItemClickedQuestionsFinanzieren,
  onListItemClickedQuestionsKaufen,
} from "../../actions/furtherQuestions";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useFormik } from "formik";
import TextfieldWrapper from "../materialui/textfield";
import { furtherQuestionsCreators } from "../../actions/forms/furtherQuestionsCreators";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearWithPercent65 from "../progress/number/progress65";
import Kontakt from "./kontakt";
import { useNavigate } from "react-router-dom";
import Umsetzung from "./umsetzung";
import Bilder from "./bilder";

export default function KaufFinanzierung() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent65 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Willst du dein Vorhaben lieber kaufen oder finanzieren?
        </h1>
        <div className="row row-cols-2 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/bilder");
                dispatch(onListItemClickedQuestionsKaufen());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Kaufen</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/bilder");
                dispatch(onListItemClickedQuestionsFinanzieren());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Finanzieren</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          type="submit"
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate("/beratung/projekt/umsetzung");
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <Bilder />;
  } else if (zustandComponente == -1) {
    return <Umsetzung />;
  }
}
