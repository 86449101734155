import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  onListItemClickedQuestionsUmsetzungSofort,
  onListItemClickedQuestionsUmsetzung1bis3Monate,
  onListItemClickedQuestionsUmsetzung3bis6Monate,
  onListItemClickedQuestionsUmsetzung6bis12Monate,
  onListItemClickedQuestionsUmsetzungMehrAls12Monate,
  onListItemClickedQuestionsFinanzieren,
  onListItemClickedQuestionsKaufen,
  onListItemClickedQuestionsVorangebotJa,
  onListItemClickedQuestionsVorangebotNein,
} from "../../../actions/furtherQuestions";
import { furtherQuestionsCreators } from "../../../actions/forms/furtherQuestionsCreators";
import { useNavigate } from "react-router-dom";
import Stockwerke from "../B2cComponent/Services/Balkone/Stockwerke";
import StockwerkeTreppen from "../B2cComponent/Services/Treppen/StockwerkeTreppen";
import LaengeGelaender from "../B2cComponent/Services/Gelaender/LaengeGelaender";
import BeleuchtungHandlauf from "../B2cComponent/Services/Handlauf/BeleuchtungHandlauf";
import { createAnfrage, updateAnfrage } from "../../../actions/anfrage";
import { boundToIdCreators } from "../../../actions/forms/idCreators";
import validator from "validator";
import B2cComponentFunction from "../B2cComponent/B2cComponentFunction";
import SignUpContainer from "../../user/signupContainer";

const B2bComponent = () => {
  let currentId = useSelector((state) => state.id.currentId);
  let setCurrentId = useSelector((state) => state.id.setCurrentId);
  const dispatch = useDispatch();
  const bilderBaustelleInput = React.createRef();
  const zeichnungenSkizzeInput = React.createRef();
  const navigate = useNavigate();
  const anfrageState = useSelector((state) => state.anfrage);
  const idState = useSelector((state) => state.id);

  const [anfrageData, setAnfrageData] = useState({
    //ID
    currentId: idState.currentId,
    setCurrendId: idState.setCurrentId,
    //Leistung
    leistung: anfrageState.leistung,
    beschreibungLeistung: anfrageState.beschreibungLeistung,
    //Balkon
    artBalkon: anfrageState.artBalkon,
    materialBalkon: anfrageState.materialBalkon,
    tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
    laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
    bodenBalkon: anfrageState.bodenBalkon,
    gelaenderBalkon: anfrageState.gelaenderBalkon,
    sichtschutzBalkon: anfrageState.sichtschutzBalkon,
    sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
    dachBalkon: anfrageState.dachBalkon,
    handlaufBalkon: anfrageState.handlaufBalkon,
    beschichtungBalkon: anfrageState.beschichtungBalkon,
    treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
    anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
    //Treppe
    formTreppe: anfrageState.formTreppe,
    gelaenderTreppe: anfrageState.gelaenderTreppe,
    bodenTreppe: anfrageState.bodenTreppe,
    ortTreppe: anfrageState.ortTreppe,
    materialTreppe: anfrageState.materialTreppe,
    anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
    //Geländer
    artGelaender: anfrageState.artGelaender,
    materialGelaender: anfrageState.materialGelaender,
    montageGelaender: anfrageState.montageGelaender,
    //Handlauf
    laengeGelaender: anfrageState.laengeGelaender,
    artHandlauf: anfrageState.artHandlauf,
    wandseiteHandlauf: anfrageState.wandseiteHandlauf,
    formHandlauf: anfrageState.formHandlauf,
    durchmesserHandlauf: anfrageState.durchmesserHandlauf,
    handlaufendeOben: anfrageState.handlaufendeOben,
    handlaufendeUnten: anfrageState.handlaufendeUnten,
    endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
    endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
    abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
    materialHandlauf: anfrageState.materialHandlauf,
    montageHandlauf: anfrageState.montageHandlauf,
    beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
    laengeHandlauf: anfrageState.laengeHandlauf,
    //Kundeninformationen
    beschreibung: anfrageState.beschreibung,
    vorangebot: anfrageState.vorangebot,
    umsetzung: anfrageState.umsetzung,
    kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
    bilderBaustelle: anfrageState.bilderBaustelle,
    zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
    postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
    ortBaustelle: anfrageState.ortBaustelle,
    vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
    nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
    unternehmenAnsprechpartner: anfrageState.unternehmenAnsprechpartner,
    strasseHausnummerAnsprechpartner:
      anfrageState.strasseHausnummerAnsprechpartner,
    postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
    ortAnsprechpartner: anfrageState.ortAnsprechpartner,
    telefonnummer: anfrageState.telefonnummer,
  });

  const anfrageS = useSelector((state) =>
    currentId
      ? state.anfrage.find((message) => message._id === currentId)
      : null
  );

  useEffect(() => {
    if (anfrageS) setAnfrageData(anfrageS);
  }, [anfrageS]);

  const clear = () => {
    currentId = 0;
    setCurrentId = 0;
    boundToIdCreators.setCurrentId(currentId);
    boundToIdCreators.setCurrentId(setCurrentId);
    setAnfrageData({
      leistung: "",
      beschreibungLeistung: "",
      artBalkon: "",
      materialBalkon: "",
      tiefeInMeterBalkon: "",
      laengeInMeterBalkon: "",
      bodenBalkon: "",
      gelaenderBalkon: "",
      sichtschutzBalkon: "",
      sichtschutzFarbeBalkon: "",
      dachBalkon: "",
      handlaufBalkon: "",
      beschichtungBalkon: "",
      treppeZumGartenBalkon: "",
      anzahlStockwerkeBalkon: "",
      formTreppe: "",
      gelaenderTreppe: "",
      bodenTreppe: "",
      ortTreppe: "",
      materialTreppe: "",
      anzahlStockwerkeTreppe: "",
      artGelaender: "",
      materialGelaender: "",
      montageGelaender: "",
      laengeGelaender: "",
      artHandlauf: "",
      wandseiteHandlauf: "",
      formHandlauf: "",
      durchmesserHandlauf: "",
      handlaufendeOben: "",
      handlaufendeUnten: "",
      endkappeObenHandlauf: "",
      endkappeUntenHandlauf: "",
      abdeckrossetenHandlauf: "",
      materialHandlauf: "",
      montageHandlauf: "",
      beleuchtungHandlauf: "",
      laengeHandlauf: "",
      beschreibung: "",
      vorangebot: "",
      umsetzung: "",
      kaufenOderFinanzieren: "",
      bilderBaustelle: "",
      zeichnungenBaustelle: "",
      postleitzahlBaustelle: "",
      ortBaustelle: "",
      vornameAnsprechpartner: "",
      nachnameAnsprechpartner: "",
      unternehmenAnsprechpartner: "",
      strasseHausnummerAnsprechpartner: "",
      postleitzahlAnsprechpartner: "",
      ortAnsprechpartner: "",
      telefonnummer: "",
    });
  };
  const handleSubmit = async (e) => {
    if (currentId === 0) {
      setAnfrageData({
        //ID
        currentId: idState.currentId,
        setCurrendId: idState.setCurrentId,
        //Leistung
        leistung: anfrageState.leistung,
        beschreibungLeistung: anfrageState.beschreibungLeistung,
        //Balkon
        artBalkon: anfrageState.artBalkon,
        materialBalkon: anfrageState.materialBalkon,
        tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
        laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
        bodenBalkon: anfrageState.bodenBalkon,
        gelaenderBalkon: anfrageState.gelaenderBalkon,
        sichtschutzBalkon: anfrageState.sichtschutzBalkon,
        sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
        dachBalkon: anfrageState.dachBalkon,
        handlaufBalkon: anfrageState.handlaufBalkon,
        beschichtungBalkon: anfrageState.beschichtungBalkon,
        treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
        anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
        //Treppe
        formTreppe: anfrageState.formTreppe,
        gelaenderTreppe: anfrageState.gelaenderTreppe,
        bodenTreppe: anfrageState.bodenTreppe,
        ortTreppe: anfrageState.ortTreppe,
        materialTreppe: anfrageState.materialTreppe,
        anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
        //Geländer
        artGelaender: anfrageState.artGelaender,
        materialGelaender: anfrageState.materialGelaender,
        montageGelaender: anfrageState.montageGelaender,
        //Handlauf
        laengeGelaender: anfrageState.laengeGelaender,
        artHandlauf: anfrageState.artHandlauf,
        wandseiteHandlauf: anfrageState.wandseiteHandlauf,
        formHandlauf: anfrageState.formHandlauf,
        durchmesserHandlauf: anfrageState.durchmesserHandlauf,
        handlaufendeOben: anfrageState.handlaufendeOben,
        handlaufendeUnten: anfrageState.handlaufendeUnten,
        endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
        endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
        abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
        materialHandlauf: anfrageState.materialHandlauf,
        montageHandlauf: anfrageState.montageHandlauf,
        beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
        laengeHandlauf: anfrageState.laengeHandlauf,
        //Kundeninformationen
        beschreibung: anfrageState.beschreibung,
        vorangebot: anfrageState.vorangebot,
        umsetzung: anfrageState.umsetzung,
        kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
        bilderBaustelle: anfrageState.bilderBaustelle,
        zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
        postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
        ortBaustelle: anfrageState.ortBaustelle,
        vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
        nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
        unternehmenAnsprechpartner: anfrageState.unternehmenAnsprechpartner,
        strasseHausnummerAnsprechpartner:
          anfrageState.strasseHausnummerAnsprechpartner,
        postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
        ortAnsprechpartner: anfrageState.ortAnsprechpartner,
        telefonnummer: anfrageState.telefonnummer,
      });
      setAnfrageData((prevState) => {
        dispatch(createAnfrage(prevState));
        return prevState;
      });
      clear();
    } else {
      setAnfrageData({
        //ID
        currentId: idState.currentId,
        setCurrendId: idState.setCurrentId,
        //Leistung
        leistung: anfrageState.leistung,
        beschreibungLeistung: anfrageState.beschreibungLeistung,
        //Balkon
        artBalkon: anfrageState.artBalkon,
        materialBalkon: anfrageState.materialBalkon,
        tiefeInMeterBalkon: anfrageState.tiefeInMeterBalkon,
        laengeInMeterBalkon: anfrageState.laengeInMeterBalkon,
        bodenBalkon: anfrageState.bodenBalkon,
        gelaenderBalkon: anfrageState.gelaenderBalkon,
        sichtschutzBalkon: anfrageState.sichtschutzBalkon,
        sichtschutzFarbeBalkon: anfrageState.sichtschutzFarbeBalkon,
        dachBalkon: anfrageState.dachBalkon,
        handlaufBalkon: anfrageState.handlaufBalkon,
        beschichtungBalkon: anfrageState.beschichtungBalkon,
        treppeZumGartenBalkon: anfrageState.treppeZumGartenBalkon,
        anzahlStockwerkeBalkon: anfrageState.anzahlStockwerkeBalkon,
        //Treppe
        formTreppe: anfrageState.formTreppe,
        gelaenderTreppe: anfrageState.gelaenderTreppe,
        bodenTreppe: anfrageState.bodenTreppe,
        ortTreppe: anfrageState.ortTreppe,
        materialTreppe: anfrageState.materialTreppe,
        anzahlStockwerkeTreppe: anfrageState.anzahlStockwerkeTreppe,
        //Geländer
        artGelaender: anfrageState.artGelaender,
        materialGelaender: anfrageState.materialGelaender,
        montageGelaender: anfrageState.montageGelaender,
        //Handlauf
        laengeGelaender: anfrageState.laengeGelaender,
        artHandlauf: anfrageState.artHandlauf,
        wandseiteHandlauf: anfrageState.wandseiteHandlauf,
        formHandlauf: anfrageState.formHandlauf,
        durchmesserHandlauf: anfrageState.durchmesserHandlauf,
        handlaufendeOben: anfrageState.handlaufendeOben,
        handlaufendeUnten: anfrageState.handlaufendeUnten,
        endkappeObenHandlauf: anfrageState.endkappeObenHandlauf,
        endkappeUntenHandlauf: anfrageState.endkappeUntenHandlauf,
        abdeckrossetenHandlauf: anfrageState.abdeckrossetenHandlauf,
        materialHandlauf: anfrageState.materialHandlauf,
        montageHandlauf: anfrageState.montageHandlauf,
        beleuchtungHandlauf: anfrageState.beleuchtungHandlauf,
        laengeHandlauf: anfrageState.laengeHandlauf,
        //Kundeninformationen
        beschreibung: anfrageState.beschreibung,
        vorangebot: anfrageState.vorangebot,
        umsetzung: anfrageState.umsetzung,
        kaufenOderFinanzieren: anfrageState.kaufenOderFinanzieren,
        bilderBaustelle: anfrageState.bilderBaustelle,
        zeichnungenBaustelle: anfrageState.zeichnungenBaustelle,
        postleitzahlBaustelle: anfrageState.postleitzahlBaustelle,
        ortBaustelle: anfrageState.ortBaustelle,
        vornameAnsprechpartner: anfrageState.vornameAnsprechpartner,
        nachnameAnsprechpartner: anfrageState.nachnameAnsprechpartner,
        unternehmenAnsprechpartner: anfrageState.unternehmenAnsprechpartner,
        strasseHausnummerAnsprechpartner:
          anfrageState.strasseHausnummerAnsprechpartner,
        postleitzahlAnsprechpartner: anfrageState.postleitzahlAnsprechpartner,
        ortAnsprechpartner: anfrageState.ortAnsprechpartner,
        telefonnummer: anfrageState.telefonnummer,
      });
      setAnfrageData((prevState) => {
        dispatch(updateAnfrage(currentId, prevState));
        return prevState;
      });
      clear();
    }
  };

  const [zustandComponenteFQ, setZustandComponenteFQ] = useState(0);

  const onFileBaustelleChange = (event) => {
    this.anfrageState({
      bilderBaustelle: event.target.files[0],
    });
  };

  const onFileBaustelleUpload = () => {};

  const onFileZeichnungenChange = (event) => {
    this.anfrageState({
      zeichnungenBaustelle: event.target.files[0],
    });
  };
  const onFileZeichnungenUpload = () => {};

  const [error, setError] = useState(false);

  if (zustandComponenteFQ === 0) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Um was geht es bei dem Bauvorhaben?
        </h1>
        <form className="form-group pt-4 pb-4">
          <label htmlFor="beschreibungProjekt" id="beschreibungProjekt"></label>
          <textarea
            type="text"
            name="beschreibungProjekt"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektBeschreibung(e.target.value)
            }
          ></textarea>
        </form>

        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
        <button
          key={"Weiter"}
          className="btn btn-success"
          onClick={() => {
            setZustandComponenteFQ(1);
          }}
        >
          WEITER
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 1) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Soll dir ein Vorangebot gemacht werden?
        </h1>
        <br />
        <p>
          Info: Ein Vorangebot lässt man sich machen, wenn man sich erst noch
          informieren möchte und noch nicht beschlossen hat bei welchem
          Metallbauer man sein Vorhaben realisieren will.
        </p>
        <div className="row row-cols-2 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(2);
                dispatch(onListItemClickedQuestionsVorangebotJa());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Ja</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(2);
                dispatch(onListItemClickedQuestionsVorangebotNein());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Nein</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponenteFQ(0);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 2) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wann soll das Vorhaben umgesetzt werden?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzungSofort());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Sofort</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzung1bis3Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 1 bis 3 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzung3bis6Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 3 bis 6 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzung6bis12Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 6 bis 12 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(3);
                dispatch(onListItemClickedQuestionsUmsetzungMehrAls12Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in mehr als 12 Monaten</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponenteFQ(1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 3) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Willst du dein Vorhaben lieber kaufen oder finanzieren?
        </h1>
        <div className="row row-cols-2 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(4);
                dispatch(onListItemClickedQuestionsKaufen());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Kaufen</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponenteFQ(4);
                dispatch(onListItemClickedQuestionsFinanzieren());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Finanzieren</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponenteFQ(2);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 4) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Gibt es Bilder der Baustelle?
        </h1>
        <div name="bilderBaustelle" className="pt-4 pb-4">
          <label className="form-label">
            Hier kannst du Bilder der Baustelle hochladen.
          </label>
          <input
            onChange={async (e) =>
              furtherQuestionsCreators.updateProjektBaustellenBilder(
                e.target.files
              )
            }
            className="form-control"
            name="dateiBilderBaustelle[]"
            ref={bilderBaustelleInput}
            id="BilderBaustelle[]"
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
            multiple
          />
        </div>

        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(3);
          }}
        >
          ZURÜCK
        </button>
        <button
          key={"Weiter"}
          className="btn btn-success"
          onClick={() => {
            setZustandComponenteFQ(5);
          }}
        >
          WEITER
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 5) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Gibt es bereits Zeichnungen oder Skizzen?
        </h1>
        <div name="SkizzenZeichnungen" className="pt-4 pb-4">
          <label className="form-label">
            Hier kannst du Zeichnungen oder Skizzen hochladen.
          </label>
          <input
            className="form-control"
            name="dateiZeichnungSkizze[]"
            id="dateiZeichnungSkizze[]"
            type="file"
            ref={zeichnungenSkizzeInput}
            onChange={async (e) =>
              furtherQuestionsCreators.updateProjektZeichnungenSkizzenBaustelle(
                e.target.files
              )
            }
            multiple
          />
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(4);
          }}
        >
          ZURÜCK
        </button>
        <button
          key={"Weiter"}
          className="btn btn-success"
          onClick={() => {
            setZustandComponenteFQ(6);
          }}
        >
          WEITER
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 6) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wo befindet sich die Baustelle?
        </h1>
        <div className="form-group pt-4 pb-4">
          <input
            type="text"
            name="adresseProjekt"
            className="form-control"
            placeholder="Adresse"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektBaustelleAdresse(
                e.target.value
              )
            }
          ></input>
          <br />
          <input
            type="text"
            name="plzProjekt"
            className="form-control"
            placeholder="Postleitzahl"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektBaustellePostleitzahl(
                e.target.value
              )
            }
          ></input>
          <br />
          <input
            type="text"
            name="ortProjekt"
            className="form-control"
            placeholder="Ort"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektBaustelleOrt(e.target.value)
            }
          ></input>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(5);
          }}
        >
          ZURÜCK
        </button>
        <button
          key={"Weiter"}
          className="btn btn-success"
          onClick={() => {
            setZustandComponenteFQ(7);
          }}
        >
          WEITER
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 7) {
    return (
      <div className="px-4 pb-4">
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Angaben zur Person oder Unternehmen
        </h1>
        <div className="pt-4 pb-4 ">
          <h2 className="fs-4 fw-normal lh-lg pb-2">Ansprechpartner</h2>
          <input
            type="text"
            name="vorname"
            placeholder="Vorname"
            className="form-control pl-4 pr-4"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenVorname(
                e.target.value
              )
            }
          ></input>
          <br />
          <input
            type="text"
            name="nachname"
            placeholder="Nachname"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenNachname(
                e.target.value
              )
            }
          ></input>
          <br />
          <input
            type="text"
            name="unternehmen"
            placeholder="Unternehmen (optional)"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenUnternehmen(
                e.target.value
              )
            }
          ></input>
          <br />
          <br />
          <h2 className="fs-4 fw-normal lh-lg pb-2">Adresse</h2>
          <input
            type="text"
            name="strassePersonOUnternehmen"
            placeholder="Straße und Hausnummer"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerStrasseHausnummer(
                e.target.value
              )
            }
          ></input>
          <br />
          <input
            type="text"
            name="postleitzahlPersonOUnternehmen"
            placeholder="Postzeitzahl"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerPostzeitzahl(
                e.target.value
              )
            }
          ></input>
          <br />
          <input
            type="text"
            name="ortPersonOUnternehmen"
            placeholder="Ort"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerOrt(
                e.target.value
              )
            }
          ></input>
          <br />
          <br />
          <h2 className="fs-4 fw-normal lh-lg pb-2">Kontakt</h2>
          <input
            type="text"
            name="telefonnummer"
            placeholder="Telefonnummer*"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerTelefonnummer(
                e.target.value
              )
            }
            required
          ></input>
          <br />
          <input
            type="text"
            name="email"
            placeholder="E-Mail*"
            className="form-control"
            onChange={(e) =>
              furtherQuestionsCreators.updateProjektAngabenAnsprechpartnerEMail(
                e.target.value
              )
            }
            required
          ></input>
        </div>

        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(6);
          }}
        >
          ZURÜCK
        </button>

        <button
          key={"Weiter"}
          className="btn btn-success"
          onClick={() => {
            setZustandComponenteFQ(8);
            handleSubmit();
          }}
        >
          Anfrage senden
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === 8) {
    return (
      <div className="px-4 pb-4">
        <SignUpContainer />
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2 mx-2"
          onClick={() => {
            setZustandComponenteFQ(7);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponenteFQ === -1) {
    return <B2cComponentFunction />;
  }
};
export default B2bComponent;
