import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onListItemClickedB2CBalkonMaterialEdelstahl,
  onListItemClickedB2CBalkonMaterialSonstiges,
  onListItemClickedB2CBalkonMaterialStahl,
} from "../../../../../actions/useCases/b2c/balkone/balkone";
import Masse from "./Masse";
import Balkone from "../Balkone";
import LinearWithPercent10 from "../../../../progress/number/progress10";
import FarbeBalkon from "./FarbeBalkon";

export default function Material() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent10 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Aus welchem Material soll der Balkon sein?
        </h1>
        <div className="row row-cols-2 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/masse-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonMaterialStahl());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Stahl</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/masse-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonMaterialEdelstahl());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Edelstahl</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <Masse />;
  } else if (zustandComponente == -1) {
    return <FarbeBalkon />;
  }
}
