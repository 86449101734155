import React, { useState } from "react";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import Login from "./Components/user/login";
import { onListItemClickedLogin } from "./actions/user/login";

export default function Footer() {
  const dispatch = useDispatch();

  return (
    <footer className="footer">
      <p className="h5">
        <small>07146 4072622</small>
      </p>
      <p>
        <small>(Mo-Fr: 8-17 Uhr)</small>
      </p>
      <div className="row row-cols-3 pt-3 pb-2 breiterow">
        <div className="col-xs-2 breitecol ">
          <a
            className="farbelink"
            href="https://metallbau-stahlbau-stuttgart.de/impressum/"
          >
            <small>Impressum</small>
          </a>
        </div>
        <div className="col-xs-2 breitecol">
          <a
            className="farbelink"
            href="https://metallbau-stahlbau-stuttgart.de/agb/"
          >
            <small>AGB</small>
          </a>
        </div>
        <div className="col-xs-2 breitecol">
          <a
            className="farbelink"
            href="https://metallbau-stahlbau-stuttgart.de/datenschutzerklaerung/"
          >
            <small>Datenschutz</small>
          </a>
        </div>
      </div>
      <div>
        <small>2021-2022 | M&S STUTTGART GMBH</small>
      </div>
    </footer>
  );
}
