export const onListItemClickedB2CGelaenderAnbringungBalkon = () => {
  return {
    type: "Gelaender-Anbringung-Balkon",
  };
};

export const onListItemClickedB2CGelaenderAnbringungTreppe = () => {
  return {
    type: "Gelaender-Anbringung-Treppe",
  };
};

export const onListItemClickedB2CGelaenderAnbringungGehweg = () => {
  return {
    type: "Gelaender-Anbringung-Gehweg",
  };
};

export const onListItemClickedB2CGelaenderAnbringungGalerie = () => {
  return {
    type: "Gelaender-Anbringung-Galerie",
  };
};

export const onListItemClickedB2CGelaenderAnbringungIndustriegelaender = () => {
  return {
    type: "Gelaender-Anbringung-Industriegelaender",
  };
};

export const onListItemClickedB2CGelaenderAnbringungDach = () => {
  return {
    type: "Gelaender-Anbringung-Dach",
  };
};

export const onListItemClickedB2CGelaenderAnbringungFensterFranzoesischesGelaender =
  () => {
    return {
      type: "Gelaender-Anbringung-Fenster-Franzoesisches-Gelaender",
    };
  };

export const onListItemClickedB2CGelaenderAnbringungSonstigeOrte = () => {
  return {
    type: "Gelaender-Anbringung-Sonstige-Orte",
  };
};

//Zum Aussortieren Start
export const onListItemClickedB2CGelaenderArtBalkongelaender = () => {
  return {
    type: "Geländerart-Balkongeländer",
  };
};

export const onListItemClickedB2CGelaenderArtTreppengelaender = () => {
  return {
    type: "Geländerart-Treppengeländer",
  };
};
export const onListItemClickedB2CGelaenderArtStabsgelaender = () => {
  return {
    type: "Geländerart-Stabsgeländer",
  };
};
export const onListItemClickedB2CGelaenderArtSeilgelaender = () => {
  return {
    type: "Geländerart-Seilgeländer",
  };
};
export const onListItemClickedB2CGelaenderArtGlasgelaender = () => {
  return {
    type: "Geländerart-Glasgeländer",
  };
};
//Zum Aussortieren Ende

export const onListItemClickedB2CGelaenderMaterialStahl = () => {
  return {
    type: "Material-Stahl",
  };
};
export const onListItemClickedB2CGelaenderMaterialEdelstahl = () => {
  return {
    type: "Material-Edelstahl",
  };
};

export const onListItemClickedB2CGelaenderPfostenRohrpfostenRund = () => {
  return {
    type: "Gelaender-Pfosten-Rohrpfosten-Rund",
  };
};

export const onListItemClickedB2CGelaenderPfostenRohrpfostenRechteckig = () => {
  return {
    type: "Gelaender-Pfosten-Rohrpfosten-Rechteckig",
  };
};
export const onListItemClickedB2CGelaenderPfostenFlachpfosten = () => {
  return {
    type: "Gelaender-Pfosten-Flachpfosten",
  };
};

export const onListItemClickedB2CGelaenderFuellungMitSenkrechtenStaeben =
  () => {
    return {
      type: "Gelaender-Fuellung-Mit-Senkrechten-Staeben",
    };
  };
export const onListItemClickedB2CGelaenderFuellungHorizontaleFuellung = () => {
  return {
    type: "Gelaender-Fuellung-Horizontale-Fuellung",
  };
};
export const onListItemClickedB2CGelaenderFuellungKassettenfüllungMitSenkrechtenStaeben =
  () => {
    return {
      type: "Gelaender-Fuellung-Kassettenfüllung-Mit-Senkrechten-Staeben",
    };
  };
export const onListItemClickedB2CGelaenderFuellungKassettenfüllungMitHorizontalenStaeben =
  () => {
    return {
      type: "Gelaender-Fuellung-Kassettenfüllung-Mit-Horizontalen-Staeben",
    };
  };
export const onListItemClickedB2CGelaenderFuellungGlasfuellung = () => {
  return {
    type: "Gelaender-Fuellung-Glasfuellung",
  };
};
export const onListItemClickedB2CGelaenderFuellungMitSeilen = () => {
  return {
    type: "Gelaender-Fuellung-Mit-Seilen",
  };
};

export const onListItemClickedB2CGelaenderFuellungMaterialEdelstahl = () => {
  return {
    type: "Gelaender-Fuellung-Material-Edelstahl",
  };
};
export const onListItemClickedB2CGelaenderFuellungMaterialGlas = () => {
  return {
    type: "Gelaender-Fuellung-Material-Glas",
  };
};
export const onListItemClickedB2CGelaenderFuellungMaterialGanzglas = () => {
  return {
    type: "Gelaender-Fuellung-Material-Ganzglas",
  };
};

export const onListItemClickedB2CGelaenderFormGeradlaeufig = () => {
  return {
    type: "Gelaender-Form-Geradlaeufig",
  };
};
export const onListItemClickedB2CGelaenderFormU = () => {
  return {
    type: "Gelaender-Form-U-Foermig",
  };
};
export const onListItemClickedB2CGelaenderFormL = () => {
  return {
    type: "Gelaender-Form-L-Foermig",
  };
};
export const onListItemClickedB2CGelaenderFormSonderform = () => {
  return {
    type: "Gelaender-Form-Sonderform",
  };
};

export const onListItemClickedB2CGelaenderMontageAussen = () => {
  return {
    type: "Montage-Außen",
  };
};

export const onListItemClickedB2CGelaenderMontageInnen = () => {
  return {
    type: "Montage-Innen",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis3Meter = () => {
  return {
    type: "Laenge-bis3Meter",
  };
};
export const onListItemClickedB2CGelaenderLaengeBis4Meter = () => {
  return {
    type: "Laenge-bis4Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis5Meter = () => {
  return {
    type: "Laenge-bis5Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis6Meter = () => {
  return {
    type: "Laenge-bis6Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis7Meter = () => {
  return {
    type: "Laenge-bis7Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis8Meter = () => {
  return {
    type: "Laenge-bis8Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis9Meter = () => {
  return {
    type: "Laenge-bis9Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis10Meter = () => {
  return {
    type: "Laenge-bis10Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis11Meter = () => {
  return {
    type: "Laenge-bis11Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeBis12Meter = () => {
  return {
    type: "Laenge-bis12Meter",
  };
};

export const onListItemClickedB2CGelaenderLaengeMehrAls12Meter = () => {
  return {
    type: "Laenge-MehrAls12Meter",
  };
};
