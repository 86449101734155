export const onListItemClickedB2CHandlaufGlas = () => {
  return {
    type: "Handlauf_auf_Glas",
  };
};

export const onListItemClickedB2CHandlaufWand = () => {
  return {
    type: "Handlauf_an_der_Wand",
  };
};

export const onListItemClickedB2CHandlaufStuetzen = () => {
  return {
    type: "Handlauf_auf_Stuetzen",
  };
};

export const onListItemClickedB2CHandlaufLinkeWandseite = () => {
  return {
    type: "Handlauf_Linke_Wandseite",
  };
};

export const onListItemClickedB2CHandlaufRechteWandseite = () => {
  return {
    type: "Handlauf_Rechte_Wandseite",
  };
};

export const onListItemClickedB2CHandlaufFormRund = () => {
  return {
    type: "Handlauf_Form_Rund",
  };
};

export const onListItemClickedB2CHandlaufFormEckig = () => {
  return {
    type: "Handlauf_Form_Eckig",
  };
};

export const onListItemClickedB2CHandlaufFormOval = () => {
  return {
    type: "Handlauf_Form_Oval",
  };
};

export const onListItemClickedB2CHandlaufDurchmesser34 = () => {
  return {
    type: "Handlauf_Durchmesser_34mm",
  };
};

export const onListItemClickedB2CHandlaufDurchmesser44 = () => {
  return {
    type: "Handlauf_Durchmesser_44mm",
  };
};
export const onListItemClickedB2CHandlaufDurchmesser48 = () => {
  return {
    type: "Handlauf_Durchmesser_48mm",
  };
};

export const onListItemClickedB2CHandlaufendeObenGerade = () => {
  return {
    type: "Handlauf_Ende_Oben_Gerade",
  };
};

export const onListItemClickedB2CHandlaufendeOben90GradBogenNachUnten = () => {
  return {
    type: "Handlauf_Ende_Oben_90_Grad_Bogen_nach_Unten",
  };
};

export const onListItemClickedB2CHandlaufendeOben90GradBogenZurWand = () => {
  return {
    type: "Handlauf_Ende_Oben_90_Grad_Bogen_zur_Wand",
  };
};

export const onListItemClickedB2CHandlaufendeUntenGerade = () => {
  return {
    type: "Handlauf_Ende_Unten_Gerade",
  };
};
export const onListItemClickedB2CHandlaufendeUnten90GradBogenNachUnten = () => {
  return {
    type: "Handlauf_Ende_Unten_90_Grad_Bogen_nach_Unten",
  };
};

export const onListItemClickedB2CHandlaufendeUnten90GradBogenZurWand = () => {
  return {
    type: "Handlauf_Ende_Unten_90_Grad_Bogen_zur_Wand",
  };
};

export const onListItemClickedB2CHandlaufObereEndkappeFlach = () => {
  return {
    type: "Handlauf_Endkappe_Oben_Flach",
  };
};

export const onListItemClickedB2CHandlaufObereEndkappeGewoelbt = () => {
  return {
    type: "Handlauf_Endkappe_Oben_Gewoelbt",
  };
};

export const onListItemClickedB2CHandlaufObereEndkappeHalbkugel = () => {
  return {
    type: "Handlauf_Endkappe_Oben_Halbkugel",
  };
};

export const onListItemClickedB2CHandlaufUntereEndkappeFlach = () => {
  return {
    type: "Handlauf_Endkappe_Unten_Flach",
  };
};

export const onListItemClickedB2CHandlaufUntereEndkappeGewoelbt = () => {
  return {
    type: "Handlauf_Endkappe_Unten_Gewoelbt",
  };
};

export const onListItemClickedB2CHandlaufUntereEndkappeHalbkugel = () => {
  return {
    type: "Handlauf_Endkappe_Unten_Halbkugel",
  };
};

export const onListItemClickedB2CHandlaufOhneAbdeckrosseten = () => {
  return {
    type: "Handlauf_Ohne_Abdeckrosseten",
  };
};

export const onListItemClickedB2CHandlaufMitAbdeckrosseten = () => {
  return {
    type: "Handlauf_Mit_Abdeckrosseten",
  };
};

export const onListItemClickedB2CHandlaufMaterialEichenholz = () => {
  return {
    type: "Handlauf_Material_Eichenholz",
  };
};

export const onListItemClickedB2CHandlaufMaterialBuchenholz = () => {
  return {
    type: "Handlauf_Material_Buchenholz",
  };
};
export const onListItemClickedB2CHandlaufMaterialEdelstahl = () => {
  return {
    type: "Handlauf_Material_Edelstahl",
  };
};
export const onListItemClickedB2CHandlaufMaterialStahl = () => {
  return {
    type: "Handlauf_Material_Stahl",
  };
};

export const onListItemClickedB2CHandlaufOrtInnen = () => {
  return {
    type: "Handlauf_Ort_Innen",
  };
};
export const onListItemClickedB2CHandlaufOrtAussen = () => {
  return {
    type: "Handlauf_Ort_Aussen",
  };
};

export const onListItemClickedB2CHandlaufMontageJa = () => {
  return {
    type: "Handlauf_Montage_Ja",
  };
};
export const onListItemClickedB2CHandlaufMontageNein = () => {
  return {
    type: "Handlauf_Montage_Nein",
  };
};

export const onListItemClickedB2CHandlaufBeleuchtetNein = () => {
  return {
    type: "Handlauf_Beleuchtung_Nein",
  };
};

export const onListItemClickedB2CHandlaufBeleuchtungMitLED = () => {
  return {
    type: "Handlauf_Beleuchtung_Mit_LED",
  };
};
