import { bindActionCreators } from "redux";
import store from "../../store";

const updateHandlaufLaenge = (laenge) => ({
  type: "CHANGE_HANDLAUF_LAENGE",
  payload: laenge,
});

export const handlaufCreators = bindActionCreators(
  {
    updateHandlaufLaenge,
  },
  store.dispatch
);
