import React, { useState, useMemo } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { furtherQuestionsCreators } from "../../actions/forms/furtherQuestionsCreators";
import LinearWithPercent70 from "../progress/number/progress70";
import { useNavigate } from "react-router-dom";
import Thumb from "../contact/upload/thumb";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import WIWartungBeschreibung from "./wartung-beschreibung";
import WIWartungBaustelle from "./wartung-baustelle";
import WIWartungBilder from "./wartung-bilder";

export default function WIWartungZeichnungen() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const navigate = useNavigate();
  const MAX_SIZE = 5242880;

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    setFieldValue,
  } = useDropzone({
    accept: {},
    onDrop: (files) => {
      //Der Action Creator ist dazu da, eine Verknüpfung zwischen dem Upload auf der MongoDB und dem lokalen File Upload (für das Versenden von Mails) zu setzen.
      furtherQuestionsCreators.zeichnungen(files);
    },
    maxSize: { MAX_SIZE },
  });

  const files2 = acceptedFiles.map((file, i) => (
    <Thumb className="pt-2 pb-2" key={i} file={file} />
  ));

  const bilderValidationSchema = Yup.object().shape({
    recaptcha: Yup.array(),
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent70 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Gibt es Zeichnungen oder Skizzen des Wartungs- &
          Instandsetzungsvorhabens?
        </h1>
        <Formik
          initialValues={{ files: [] }}
          validationSchema={bilderValidationSchema}
          onSubmit={(values) => {
            setZustandComponente(1);
            navigate("/beratung/wartungen/baustelle");
          }}
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group pt-4 pb-4">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    // do nothing if no files
                    if (acceptedFiles.length === 0) {
                      return;
                    }

                    // on drop we add to the existing files
                    setFieldValue("files", values.files.concat(acceptedFiles));
                  }}
                >
                  {({
                    isDragActive,
                    isDragReject,
                    acceptedFiles,
                    rejectedFiles,
                  }) => {
                    if (isDragActive) {
                      return "This file is authorized";
                    }

                    if (isDragReject) {
                      return "This file is not authorized";
                    }

                    if (values.files.length === 0) {
                      return (
                        <section className="container">
                          <div
                            {...getRootProps({
                              style,
                            })}
                          >
                            <input {...getInputProps()} />
                            <p>
                              Hier kannst du Zeichnungen oder Skizzen hochladen.
                            </p>
                          </div>
                          <aside>
                            <ul>{files2}</ul>
                          </aside>
                        </section>
                      );
                    }

                    return values.files.map((file, i) => (
                      <Thumb key={i} file={file} />
                    ));
                  }}
                </Dropzone>
              </div>
              <button
                key={"Zurueck"}
                className="btn btn-secondary me-md-2 mx-2"
                type="button"
                onClick={() => {
                  setZustandComponente(-1);
                  navigate("/beratung/wartungen/bilder");
                }}
              >
                ZURÜCK
              </button>
              <button key={"Weiter"} className="btn btn-success" type="submit">
                WEITER
              </button>
            </form>
          )}
        </Formik>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <WIWartungBaustelle />;
  } else if (zustandComponente == -1) {
    return <WIWartungBilder />;
  }
}
