import React, { useState } from "react";
import B2cComponentFunction from "../B2cComponentFunction";
import FurtherQuestions from "../FurtherQuestions/FurtherQuestions";
import { balkonCreators } from "../../../../actions/forms/balkonCreators";
import { Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  onListItemClickedB2CBalkonArtHängebalkon,
  onListItemClickedB2CBalkonArtVorstellbalkon,
} from "../../../../actions/useCases/b2c/balkone/balkone";
import LinearWithPercent5 from "../../../progress/number/progress5";
import FarbeBalkon from "./Balkone/FarbeBalkon";

export default function Balkone() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent5 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Welche Art von Balkon sollen wir für dich realisieren?
        </h1>
        <div className="row row-cols-2 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/material-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonArtVorstellbalkon());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Vorstellbalkon</p>
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/material-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonArtHängebalkon());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Hängebalkon</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <FarbeBalkon />;
  } else if (zustandComponente == -1) {
    return <B2cComponentFunction />;
  }
}
