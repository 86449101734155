import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  onListItemClickedQuestionsUmsetzungSofort,
  onListItemClickedQuestionsUmsetzung1bis3Monate,
  onListItemClickedQuestionsUmsetzung3bis6Monate,
  onListItemClickedQuestionsUmsetzung6bis12Monate,
  onListItemClickedQuestionsUmsetzungMehrAls12Monate,
} from "../../actions/furtherQuestions";

import LinearWithPercent60 from "../progress/number/progress60";
import { useNavigate } from "react-router-dom";
import Beschreibung from "./beschreibung";
import Bilder from "./bilder";

export default function Umsetzung() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent60 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Wann soll das Vorhaben umgesetzt werden?
        </h1>
        <div className="row row-cols-3 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/finanzierung");
                dispatch(onListItemClickedQuestionsUmsetzungSofort());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Sofort</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/finanzierung");
                dispatch(onListItemClickedQuestionsUmsetzung1bis3Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 1 bis 3 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/finanzierung");
                dispatch(onListItemClickedQuestionsUmsetzung3bis6Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 3 bis 6 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/finanzierung");
                dispatch(onListItemClickedQuestionsUmsetzung6bis12Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in 6 bis 12 Monaten</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/finanzierung");
                dispatch(onListItemClickedQuestionsUmsetzungMehrAls12Monate());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">in mehr als 12 Monaten</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          type="submit"
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate("/beratung/projekt/vorangebot");
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <Bilder />;
  } else if (zustandComponente == -1) {
    return <Beschreibung />;
  }
}
