import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  onListItemClickedB2CBalkonDachJa,
  onListItemClickedB2CBalkonDachNein,
} from "../../../../../actions/useCases/b2c/balkone/balkone";
import Balkone from "../Balkone";
import HandlaufBalkon from "./HandlaufBalkon";
import Sichtschutz from "./Sichtschutz";
import FarbeSichtschutz from "./FarbeSichtschutz";
import LinearWithPercent30 from "../../../../progress/number/progress30";

export default function Balkondach() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sichtschutzGewaehlt = useSelector((state) => state.balkon.Sichtschutz);

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent30 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Soll der (oberste) Balkon ein Dach haben?
        </h1>
        <div className="row row-cols-2 pt-4 pb-4">
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/handlauf-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonDachJa());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Ja</p>
              {/* Bei Ja Fragen aus Komponente Balkonüberdachung laden - if Verzweigung */}
            </div>
          </Link>
          <Link
            className="link-dark text-decoration-none p-2 breakpointColsM"
            to="beratung/balkone/handlauf-balkon"
          >
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                dispatch(onListItemClickedB2CBalkonDachNein());
                navigate(1);
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Nein</p>
            </div>
          </Link>
        </div>
        <button
          key={"Zurueck"}
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate(-1);
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <HandlaufBalkon />;
  } else if (zustandComponente == -1) {
    switch (sichtschutzGewaehlt) {
      case "Keiner":
        return <Sichtschutz />;
      case "Einseitig":
        return <FarbeSichtschutz />;
      case "Beidseitig":
        return <FarbeSichtschutz />;
      default:
        return <Sichtschutz />;
    }
  }
}
