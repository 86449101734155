import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Balkone from "./Components/UseCases/B2cComponent/Services/Balkone";
import Treppen from "./Components/UseCases/B2cComponent/Services/Treppen";
import Footer from "./footer";
import Handlauf from "./Components/UseCases/B2cComponent/Services/Handlauf";
import Gelaender from "./Components/UseCases/B2cComponent/Services/Gelaender";
import B2bComponent from "./Components/UseCases/B2bComponent/B2bComponent";
import WiComponent from "./Components/UseCases/WiComponent/WiComponent";
import FurtherQuestions from "./Components/UseCases/B2cComponent/FurtherQuestions/FurtherQuestions";
import Stockwerke from "./Components/UseCases/B2cComponent/Services/Balkone/Stockwerke";
import Gartentreppe from "./Components/UseCases/B2cComponent/Services/Balkone/Gartentreppe";
import Beschichtung from "./Components/UseCases/B2cComponent/Services/Balkone/Beschichtung";
import Sichtschutz from "./Components/UseCases/B2cComponent/Services/Balkone/Sichtschutz";
import FarbeSichtschutz from "./Components/UseCases/B2cComponent/Services/Balkone/FarbeSichtschutz";
import Balkondach from "./Components/UseCases/B2cComponent/Services/Balkone/Balkondach";
import HandlaufBalkon from "./Components/UseCases/B2cComponent/Services/Balkone/HandlaufBalkon";
import Material from "./Components/UseCases/B2cComponent/Services/Balkone/Material";
import Masse from "./Components/UseCases/B2cComponent/Services/Balkone/Masse";
import Balkonboden from "./Components/UseCases/B2cComponent/Services/Balkone/Boden";
import Balkongelaendertyp from "./Components/UseCases/B2cComponent/Services/Balkone/Balkongelaendertyp";
import GelaenderTreppen from "./Components/UseCases/B2cComponent/Services/Treppen/GelaenderTreppen";
import InnenAussenTreppen from "./Components/UseCases/B2cComponent/Services/Treppen/InnenAussenTreppen";
import MaterialTreppen from "./Components/UseCases/B2cComponent/Services/Treppen/MaterialTreppen";
import StockwerkeTreppen from "./Components/UseCases/B2cComponent/Services/Treppen/StockwerkeTreppen";
import BodenTreppen from "./Components/UseCases/B2cComponent/Services/Treppen/BodenTreppen";
import MaterialGelaender from "./Components/UseCases/B2cComponent/Services/Gelaender/MaterialGelaender";
import StandortGelaender from "./Components/UseCases/B2cComponent/Services/Gelaender/StandortGelaender";
import LaengeGelaender from "./Components/UseCases/B2cComponent/Services/Gelaender/LaengeGelaender";
import WandseiteHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/WandseiteHandlauf";
import FormHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/FormHandlauf";
import DurchmesserHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/DurchmesserHandlauf";
import ObereEndeHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/ObereEndeHandlauf";
import UntereEndeHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/UntereEndeHandlauf";
import ObereEndkappeHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/ObereEndkappeHandlauf";
import UntereEndkappeHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/UntereEndkappeHandlauf";
import AbdeckrossetenHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/AbdeckrossetenHandlauf";
import LaengeHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/LaengeHandlauf";
import MaterialHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/MaterialHandlauf";
import MontageHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/MontageHandlauf";
import BeleuchtungHandlauf from "./Components/UseCases/B2cComponent/Services/Handlauf/BeleuchtungHandlauf";
import Login from "./Components/user/login";
import Signup from "./Components/user/signup";
import NotFound from "./notFound";
import B2cComponentFunction from "./Components/UseCases/B2cComponent/B2cComponentFunction";
import { HashRouter } from "react-router-dom";
import Form from "./Components/form";
import { useDispatch } from "react-redux";
import { getAnfragen } from "./actions/anfrage.js";
import { boundToIdCreators } from "../src/actions/forms/idCreators";
import { BrowserRouter } from "react-router-dom";

import Baustelle from "./Components/contact/baustelle";
import Kontakt from "./Components/contact/kontakt";
import Termin from "./Components/termin/termin";
import Beschreibung from "./Components/contact/beschreibung";
import Vorangebot from "./Components/contact/vorangebot";
import Umsetzung from "./Components/contact/umsetzung";
import KaufFinanzierung from "./Components/contact/kaufFinanzierung";
import Bilder from "./Components/contact/bilder";
import Zeichnungen from "./Components/contact/zeichnungen";
import WIWartungArt from "./Components/wartung-instandsetzung/wartung-art";
import WIMaengel from "./Components/wartung-instandsetzung/wartung-maengel";
import WIWartungCase from "./Components/wartung-instandsetzung/wartung-case";
import WIWartungEinmaligRegelmaessig from "./Components/wartung-instandsetzung/wartung-einmalig-regelmaessig";
import WIWartungPlan from "./Components/wartung-instandsetzung/wartung-plan";
import WIWartungInspektion from "./Components/wartung-instandsetzung/wartung-inspektion";
import WIWartungEmpfehlungenInstandsetzungsarbeiten from "./Components/wartung-instandsetzung/wartung-empfehlungen-instandsetzungsarbeiten";
import WIWartungInstandsetzungOderUndReparatur from "./Components/wartung-instandsetzung/wartung-instandsetzung-reparatur";
import WIWartungDringlichkeit from "./Components/wartung-instandsetzung/wartung-dringlichkeit";
import WIWartungBeginnMassnahme from "./Components/wartung-instandsetzung/wartung-beginn-massnahme";
import WIWartungBeschreibung from "./Components/wartung-instandsetzung/wartung-beschreibung";
import WIWartungBilder from "./Components/wartung-instandsetzung/wartung-bilder";
import WIWartungZeichnungen from "./Components/wartung-instandsetzung/wartung-zeichnungen";
import WIWartungBaustelle from "./Components/wartung-instandsetzung/wartung-baustelle";
import WIWartungKontakt from "./Components/wartung-instandsetzung/wartung-kontakt";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Start />}>
          <Route path="beratung" element={<B2cComponentFunction />}>
            <Route path="balkone" element={<Balkone />}>
              <Route path="sichtschutz" element={<Sichtschutz />}></Route>
              <Route
                path="farbesichtschutz"
                element={<FarbeSichtschutz />}
              ></Route>

              <Route path="material-balkon" element={<Material />}></Route>
              <Route path="masse-balkon" element={<Masse />}></Route>
              <Route path="boden-balkon" element={<Balkonboden />}></Route>
              <Route
                path="gelaendertyp-balkon"
                element={<Balkongelaendertyp />}
              ></Route>
              <Route
                path="sichtschutz-balkon"
                element={<Sichtschutz />}
              ></Route>
              <Route
                path="farbesichtschutz-balkon"
                element={<FarbeSichtschutz />}
              ></Route>
              <Route path="dach-balkon" element={<Balkondach />}></Route>
              <Route
                path="handlauf-balkon"
                element={<HandlaufBalkon />}
              ></Route>
              <Route
                path="beschichtung-balkon"
                element={<Beschichtung />}
              ></Route>
              <Route
                path="gartentreppe-balkon"
                element={<Gartentreppe />}
              ></Route>
              <Route path="stockwerke-balkon" element={<Stockwerke />}></Route>
            </Route>
            <Route exact path="treppen" element={<Treppen />}>
              <Route
                path="gelaender-treppe"
                element={<GelaenderTreppen />}
              ></Route>
              <Route path="boden-treppe" element={<BodenTreppen />}></Route>
              <Route
                path="standort-treppe"
                element={<InnenAussenTreppen />}
              ></Route>
              <Route
                path="material-treppe"
                element={<MaterialTreppen />}
              ></Route>
              <Route
                path="stockwerke-treppe"
                element={<StockwerkeTreppen />}
              ></Route>
            </Route>
            <Route path="handlauf" element={<Handlauf />}>
              <Route
                path="wandseite-handlauf"
                element={<WandseiteHandlauf />}
              ></Route>
              <Route path="form-handlauf" element={<FormHandlauf />}></Route>
              <Route
                path="durchmesser-handlauf"
                element={<DurchmesserHandlauf />}
              ></Route>
              <Route
                path="obere-ende-handlauf"
                element={<ObereEndeHandlauf />}
              ></Route>
              <Route
                path="untere-ende-handlauf"
                element={<UntereEndeHandlauf />}
              ></Route>
              <Route
                path="obere-endkappe-handlauf"
                element={<ObereEndkappeHandlauf />}
              ></Route>
              <Route
                path="untere-endkappe-handlauf"
                element={<UntereEndkappeHandlauf />}
              ></Route>
              <Route
                path="abdeckrosseten-handlauf"
                element={<AbdeckrossetenHandlauf />}
              ></Route>
              <Route
                path="laenge-handlauf"
                element={<LaengeHandlauf />}
              ></Route>
              <Route
                path="material-handlauf"
                element={<MaterialHandlauf />}
              ></Route>
              <Route
                path="montage-handlauf"
                element={<MontageHandlauf />}
              ></Route>
              <Route
                path="beleuchtung-handlauf"
                element={<BeleuchtungHandlauf />}
              ></Route>
            </Route>
            <Route exact path="gelaender" element={<Gelaender />}>
              <Route
                path="material-gelaender"
                element={<MaterialGelaender />}
              ></Route>
              <Route
                path="standort-gelaender"
                element={<StandortGelaender />}
              ></Route>
              <Route
                path="laenge-gelaender"
                element={<LaengeGelaender />}
              ></Route>
            </Route>
            <Route path="projekt" element={<FurtherQuestions />}>
              <Route path="beschreibung" element={<Beschreibung />}></Route>
              <Route path="vorangebot" element={<Vorangebot />}></Route>
              <Route path="umsetzung" element={<Umsetzung />}></Route>
              <Route path="finanzierung" element={<KaufFinanzierung />}></Route>
              <Route path="bilder" element={<Bilder />}></Route>
              <Route path="zeichnungen" element={<Zeichnungen />}></Route>
              <Route path="baustelle" element={<Baustelle />}></Route>
              <Route path="kontakt" element={<Kontakt />}></Route>
              <Route path="termin" element={<Termin />}></Route>
            </Route>
            <Route path="andere-leistung" element={<B2bComponent />}></Route>
            <Route path="wartungen" element={<WiComponent />}>
              <Route path="maengel" element={<WIMaengel />}></Route>
              <Route path="durchfuehrung" element={<WIWartungCase />}></Route>
              <Route
                path="haeufigkeit"
                element={<WIWartungEinmaligRegelmaessig />}
              ></Route>
              <Route path="art" element={<WIWartungArt />}></Route>
              <Route path="wartungsplan" element={<WIWartungPlan />}></Route>
              <Route
                path="inspektion"
                element={<WIWartungInspektion />}
              ></Route>
              <Route
                path="empfehlungen"
                element={<WIWartungEmpfehlungenInstandsetzungsarbeiten />}
              ></Route>
              <Route
                path="instandsetzung-reparatur"
                element={<WIWartungInstandsetzungOderUndReparatur />}
              ></Route>
              <Route
                path="dringlichkeit"
                element={<WIWartungDringlichkeit />}
              ></Route>
              <Route
                path="beginn"
                element={<WIWartungBeginnMassnahme />}
              ></Route>
              <Route
                path="beschreibung"
                element={<WIWartungBeschreibung />}
              ></Route>
              <Route path="bilder" element={<WIWartungBilder />}></Route>
              <Route
                path="zeichnungen"
                element={<WIWartungZeichnungen />}
              ></Route>
              <Route path="baustelle" element={<WIWartungBaustelle />}></Route>
              <Route path="kontakt" element={<WIWartungKontakt />}></Route>
              <Route path="termin" element={<Termin />}></Route>
            </Route>
          </Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="signup" element={<Signup />}></Route>
        </Route>
        <Route path="*" element={<NotFound replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;

const Start = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  /*
  useEffect(() => {
    dispatch(getAnfragen());
  }, [currentId, dispatch]);*/

  boundToIdCreators.currentId(currentId);
  boundToIdCreators.setCurrentId(setCurrentId);

  return (
    <div className="App">
      <Form />
      <Footer />
    </div>
  );
};
