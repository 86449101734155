export const onListItemClickedB2C = () => {
  return {
    type: "B2C",
  };
};
export const onListItemClickedB2B = () => {
  return {
    type: "B2B",
  };
};
export const onListItemClickedWI = () => {
  return {
    type: "WI",
  };
};
