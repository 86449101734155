import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  onListItemClickedQuestionsVorangebotJa,
  onListItemClickedQuestionsVorangebotNein,
} from "../../actions/furtherQuestions";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useFormik } from "formik";
import TextfieldWrapper from "../materialui/textfield";
import { furtherQuestionsCreators } from "../../actions/forms/furtherQuestionsCreators";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearWithPercent55 from "../progress/number/progress55";
import Kontakt from "./kontakt";
import { useNavigate } from "react-router-dom";
import Beschreibung from "./beschreibung";
import Umsetzung from "./umsetzung";

export default function Vorangebot() {
  const [zustandComponente, setZustandComponente] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (zustandComponente == 0) {
    return (
      <div className="px-4 pb-4">
        <LinearWithPercent55 />
        <h1 className="text-center fs-3 fw-normal lh-lg pt-4">
          Soll dir ein Vorangebot gemacht werden?
        </h1>
        <br />
        <p>
          Info: Ein Vorangebot lässt man sich machen, wenn man sich erst noch
          informieren möchte und noch nicht beschlossen hat bei welchem
          Metallbauer man sein Vorhaben realisieren will.
        </p>
        <div className="row row-cols-2 pt-4 pb-4">
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/umsetzung");
                dispatch(onListItemClickedQuestionsVorangebotJa());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Ja</p>
            </div>
          </div>
          <div className="p-2 cursor-pointer breakpointColsM">
            <div
              className="col breakpointColsM divRand pt-4 pb-4"
              onClick={() => {
                setZustandComponente(1);
                navigate("/beratung/projekt/umsetzung");
                dispatch(onListItemClickedQuestionsVorangebotNein());
              }}
            >
              <div className="box_icon_wrapper">
                <img src="https://metallbau-stahlbau-stuttgart.de/wp-content/uploads/2021/09/loesung-1.svg"></img>
              </div>
              <p className="text_wrapper">Nein</p>
            </div>
          </div>
        </div>
        <button
          key={"Zurueck"}
          type="submit"
          className="btn btn-secondary me-md-2"
          onClick={() => {
            setZustandComponente(-1);
            navigate("/beratung/projekt/beschreibung");
          }}
        >
          ZURÜCK
        </button>
      </div>
    );
  } else if (zustandComponente == 1) {
    return <Umsetzung />;
  } else if (zustandComponente == -1) {
    return <Beschreibung />;
  }
}
