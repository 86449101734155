function gelaenderReducer(state = [], action) {
  switch (action.type) {
    case "Geländerart-Balkongeländer":
      return {
        ...state,
        Geländerart: "Balkongeländer",
      };
    case "Geländerart-Treppengeländer":
      return {
        ...state,
        Geländerart: "Treppengeländer",
      };
    case "Geländerart-Stabsgeländer":
      return {
        ...state,
        Geländerart: "Stabsgeländer",
      };
    case "Geländerart-Seilgeländer":
      return {
        ...state,
        Geländerart: "Seilgeländer",
      };
    case "Geländerart-Glasgeländer":
      return {
        ...state,
        Geländerart: "Glasgeländer",
      };

    case "Material-Stahl":
      return {
        ...state,
        Material: "Stahl",
      };
    case "Material-Edelstahl":
      return {
        ...state,
        Material: "Edelstahl",
      };
    case "Material-Flachstahl":
      return {
        ...state,
        Material: "Flachstahl",
      };
    case "Material-Metall":
      return {
        ...state,
        Material: "Metall",
      };
    case "Material-Glas":
      return {
        ...state,
        Material: "Glas",
      };

    case "Montage-Außen":
      return {
        ...state,
        Montage: "Außen",
      };
    case "Montage-Innen":
      return {
        ...state,
        Montage: "Innen",
      };

    case "CHANGE_GELAENDER_LAENGE":
      return {
        ...state,
        Länge: action.payload,
      };

    default:
      return state;
  }
}
export default gelaenderReducer;
